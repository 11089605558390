import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './editMyBump.css';
import { doc, onSnapshot, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../firebase';
import image_icon from '../../../assets/images/icons/image-icon.png';
import placeholder_color_thumbnail from '../../../assets/images/thumbnail/placeholder-color-thumbnail.jpg';
import placeholder_dark_thumbnail from '../../../assets/images/thumbnail/placeholder-dark-thumbnail.jpg';
import setting_icon from '../../../assets/images/icons/setting-icon.png';
import PreviewBumpPopup from './PreviewBumpPopup';
import useResponsiveDesign from '../../../components/appComponents/useResponsiveDesign'; // Adjust the path as necessary



const UserEditBumpSection = ({ videoId, user, videoTitle, isProcessedbyAI, summary, takeaways, hashtags, timestamps, isTranscriptEmpty }) => {

    const device = useResponsiveDesign(); // Use the custom hook
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('{{VIDEO_DESCRIPTION}}\n\nKey Takeaways\n{{VIDEO_TAKEAWAYS}}\n\nTimestamps:\n{{VIDEO_TIMESTAMPS}}\n\n{{VIDEO_HASHTAGS}}');
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [editingIndex, setEditingIndex] = useState(-1);
    const [editingTag, setEditingTag] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState(placeholder_color_thumbnail);
    const [localThumbnailUrl, setLocalThumbnailUrl] = useState(null);
    const fileInputRef = useRef(null);
    const [saveEditDebounce, setSaveEditDebounce] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isVideoDataNew, setIsVideoDataNew] = useState(false); // Initialize as false



    const handlePreviewClick = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    const processDescription = (description) => {
        const formattedHashtags = hashtags.map(tag => `#${tag}`).join(' ');
        const formattedTakeaways = takeaways.join('\n');
        return description
            .replace('{{VIDEO_DESCRIPTION}}', summary)
            .replace('{{VIDEO_TAKEAWAYS}}', formattedTakeaways)
            .replace('{{VIDEO_TIMESTAMPS}}', timestamps)  // Assume timestamps is a string
            .replace('{{VIDEO_HASHTAGS}}', formattedHashtags);
    };
    

    useEffect(() => {
        const debounceKey = `${videoId}_${user.uid}_debounce`;
        const lastSaveTimestamp = localStorage.getItem(debounceKey);
        const currentTime = Date.now();

        if (lastSaveTimestamp && (currentTime - lastSaveTimestamp) < 10000) { // 10 second debounce
            setSaveEditDebounce(true);
            setTimeout(() => {
                setSaveEditDebounce(false);
                localStorage.removeItem(debounceKey);
            }, 10000 - (currentTime - lastSaveTimestamp)); // Adjust timeout based on remaining debounce time
        }
    }, [videoId, user.uid]);



    useEffect(() => {
        const fetchData = async () => {
            const valueDataRef = doc(db, 'users', user.uid, 'bumpContent', `bump-${videoId}`);
            const docSnap = await getDoc(valueDataRef);  // Fetch the document snapshot once at the beginning
    
            let titleToSave = '';
            const localTitle = localStorage.getItem(`${videoId}_title`);
            if (localTitle) {
                setTitle(localTitle);
                titleToSave = localTitle;
            } else if (docSnap.exists() && docSnap.data().publish_title) {
                setTitle(docSnap.data().publish_title);
                titleToSave = docSnap.data().publish_title;
            } else {
                setTitle(videoTitle); // Set the initial title from videoTitle prop
                titleToSave = videoTitle;
            }
    
            let descriptionToSave = '';
            const localDescription = localStorage.getItem(`${videoId}_description`);
            if (localDescription) {
                setDescription(localDescription);
                descriptionToSave = localDescription;
            } else if (docSnap.exists() && docSnap.data().publish_description) {
                setDescription(docSnap.data().publish_description);
                descriptionToSave = docSnap.data().publish_description;
            } else {
                const userDocRef = doc(db, 'users', user.uid);
                const profileCorrectRef = doc(userDocRef, 'userSettings', 'profile');
    
                const profileDoc = await getDoc(profileCorrectRef);
                if (profileDoc.exists()) {
                    const profileData = profileDoc.data();
                    const templateDescription = profileData.userTemplateDescription || '{{VIDEO_DESCRIPTION}}\n\nKey Takeaways\n{{VIDEO_TAKEAWAYS}}\n\nTimestamps:\n{{VIDEO_TIMESTAMPS}}\n\n{{VIDEO_HASHTAGS}}';
                    setDescription(templateDescription);
                    descriptionToSave = templateDescription;
                    localStorage.setItem(`${videoId}_description`, templateDescription);
                }
            }
    
            let tagsToSave = [];
            const localTags = localStorage.getItem(`${videoId}_tags`);
            if (localTags) {
                setTags(JSON.parse(localTags));
                tagsToSave = JSON.parse(localTags);
            } else if (docSnap.exists() && docSnap.data().publish_hashtags) {
                setTags(docSnap.data().publish_hashtags);
                tagsToSave = docSnap.data().publish_hashtags;
            }
    
            // Only update Firestore if the document already exists
            if (docSnap.exists()) {
                if (!docSnap.data().publish_title || !docSnap.data().publish_description || !docSnap.data().publish_hashtags) {
                try {
                    await setDoc(
                    valueDataRef,
                    {
                        publish_title: titleToSave,
                        publish_description: descriptionToSave,
                        publish_hashtags: tagsToSave,
                    },
                    { merge: true }
                    );
                    // console.log('Initial data updated in Firestore');
                } catch (error) {
                    // console.error('Error updating initial data:', error);
                }
                }
            } else {
                // Optionally, handle the non-existent document case (e.g., redirect or show an error)
                // For example:
                // navigate('/bumper');
            }
    
            const unsubscribe = onSnapshot(valueDataRef, async (videoDoc) => {
                if (videoDoc.exists()) {
                    const videoData = videoDoc.data();
                    
                    if (videoData.video_thumbnail && videoData.video_thumbnail.startsWith(`bumpThumbnails/`)) {
                        // It's a storage path, so get the download URL
                        const thumbnailstorageRef = ref(storage, `bumpThumbnails/${user.uid}/${videoId}`);
                        
                        try {
                            const downloadUrl = await getDownloadURL(thumbnailstorageRef);
                            setThumbnailUrl(downloadUrl);
                            
                            // Update the Firestore document with the new URL
                            await setDoc(valueDataRef, { video_thumbnail: downloadUrl }, { merge: true });
                        } catch (error) {
                            console.error("Error fetching download URL:", error);
                        }
                    } else {
                        // It's already a URL, so directly set it
                        setThumbnailUrl(videoData.video_thumbnail || placeholder_color_thumbnail);
                    }
                } else {
                    // console.log("No such document!");
                }
            }, (error) => {
                console.error("Error fetching video data: ", error);
            });            
    
            return () => unsubscribe();
        };
    
        fetchData();
    }, [user, videoId, videoTitle]);
    


    const saveEdits = async () => {
        const debounceKey = `${videoId}_${user.uid}_debounce`;
        const lastSaveTimestamp = localStorage.getItem(debounceKey);
        const currentTime = Date.now();
    
        if (lastSaveTimestamp && (currentTime - lastSaveTimestamp) < 10000) { // 10 second debounce
            // console.log('Debounce active, please wait before saving again');
            return;
        }
    
        setSaveEditDebounce(true);
        localStorage.setItem(debounceKey, currentTime.toString());
    
        // Save to local storage
        localStorage.setItem(`${videoId}_title`, title);
        localStorage.setItem(`${videoId}_description`, description);
        localStorage.setItem(`${videoId}_tags`, JSON.stringify(tags));
    
        const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
        localStorage.removeItem(localStorageKey);
    
        try {
            const valueDataRef = doc(db, 'users', user.uid, 'bumpContent', `bump-${videoId}`);
    
            await setDoc(valueDataRef, {
                publish_title: title,
                publish_description: description,
                publish_hashtags: tags
            }, { merge: true });
    
            // console.log('Edits saved successfully');
            setIsVideoDataNew(false); // Set to false after successful save
        } catch (error) {
            // console.error('Error saving edits:', error);
        }
    
        setTimeout(() => {
            setSaveEditDebounce(false);
            localStorage.removeItem(debounceKey);
        }, 5000); // 5 second debounce
    };
    
    const [isThumbnailUploadDisabled, setIsThumbnailUploadDisabled] = useState(false);

    const handleThumbnailUpload = (event) => {
        const debounceKey = `${videoId}_${user.uid}_thumbnailUploadDebounce`;
        const lastUploadTimestamp = localStorage.getItem(debounceKey);
        const currentTime = Date.now();
    
        if (lastUploadTimestamp && (currentTime - lastUploadTimestamp) < 10000) { // 10 second debounce
            setIsThumbnailUploadDisabled(true);
            return;
        }
    
        setIsThumbnailUploadDisabled(false); // Ensure the upload is enabled after debounce period
    
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/png', 'image/jpeg'];
            const maxSize = 1 * 1024 * 1024; // 1 MB
    
            if (validTypes.includes(file.type) && file.size <= maxSize) {
                const img = new Image();
                img.src = URL.createObjectURL(file);
    
                img.onload = async () => {
                    const { width, height } = img;
    
                    // Check if the aspect ratio is 16:9
                    const aspectRatio = width / height;
                    const isValidAspectRatio = aspectRatio === 16 / 9;
    
                    // Check if the dimensions match 1280x720 or are proportional
                    const isValidDimensions =
                        (width === 1280 && height === 720) ||
                        (width >= 640 && height >= 360 && isValidAspectRatio);
    
                    if (isValidDimensions) {
                        setLocalThumbnailUrl(placeholder_dark_thumbnail); // Show loading thumbnail
    
                        const thumbnailstorageRef = ref(storage, `bumpThumbnails/${user.uid}/${videoId}`);
                        const uploadTask = uploadBytesResumable(thumbnailstorageRef, file);
    
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                // console.log('Upload is ' + progress + '% done');
                            },
                            (error) => {
                                // console.error('Error uploading thumbnail:', error);
                            },
                            async () => {
                                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                                // console.log('Thumbnail available at', downloadURL);
                                setLocalThumbnailUrl(downloadURL);
                                setThumbnailUrl(downloadURL); // Update the parent component's state
    
                                try {
                                    const valueDataRef = doc(db, 'users', user.uid, 'bumpContent', `bump-${videoId}`);
                                    await setDoc(valueDataRef, { video_thumbnail: downloadURL }, { merge: true });
                                    // console.log('Thumbnail URL updated in valueData Firestore');
    
                                    const contentCatalogRef = doc(db, 'users', user.uid, 'contentCatalog', 'bumpCatalog');
                                    const docSnap = await getDoc(contentCatalogRef);
                                    if (docSnap.exists()) {
                                        const data = docSnap.data();
                                        const updatedVideos = data.videos.map(video =>
                                            video.videoId === videoId ? { ...video, thumbnailUrl: downloadURL } : video
                                        );
                                        await setDoc(contentCatalogRef, { videos: updatedVideos }, { merge: true });
                                        // console.log('Thumbnail URL updated in contentCatalog Firestore');
    
                                        const localStorageKey = `videoCache_${user.uid}_BumpedVideos`;
                                        localStorage.removeItem(localStorageKey);
                                    }
                                } catch (error) {
                                    // console.error('Error updating Firestore with thumbnail URL:', error);
                                }
    
                                setIsVideoDataNew(true); 

                                // Set the debounce timestamp
                                localStorage.setItem(debounceKey, currentTime.toString());
                                setIsThumbnailUploadDisabled(true);
                                setTimeout(() => {
                                    setIsThumbnailUploadDisabled(false);
                                }, 10000); // Re-enable after 10 seconds
                            }
                        );
                    } else {
                        alert('Please upload a thumbnail with dimensions of 1280x720 pixels or a minimum width of 640 pixels and a 16:9 aspect ratio.');
                    }
                };
            } else {
                alert('Please upload a valid PNG or JPEG file under 1 MB.');
            }
        }
    };
    
    
    


    const handleTitleChange = (e) => {
        if (e.target.value.length <= 100) {
            setTitle(e.target.value);
            localStorage.setItem(`${videoId}_title`, e.target.value);
            setIsVideoDataNew(true); // Set to true on change
        }
    };
    
    const handleDescriptionChange = (e) => {
        if (e.target.value.length <= 5000) {
            setDescription(e.target.value);
            localStorage.setItem(`${videoId}_description`, e.target.value);
            setIsVideoDataNew(true); // Set to true on change
        }
    };
    
    const handleTagInput = (e) => {
        const input = e.target.value;
        const totalCharacters = tags.join('').length + input.length;
    
        if (totalCharacters <= 500) {
            if (input.includes(',')) {
                const newTags = input.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
                if (tags.join('').length + newTags.join('').length <= 500) {
                    setTags([...tags, ...newTags]);
                    localStorage.setItem(`${videoId}_tags`, JSON.stringify([...tags, ...newTags])); // Save tags to local storage
                    setTagInput('');
                    setIsVideoDataNew(true); // Set to true on change
                } else {
                    const allowedLength = 500 - tags.join('').length;
                    const allowedTags = newTags.join('').substring(0, allowedLength).split(',').map(tag => tag.trim()).filter(tag => tag !== '');
                    setTags([...tags, ...allowedTags]);
                    localStorage.setItem(`${videoId}_tags`, JSON.stringify([...tags, ...allowedTags])); // Save tags to local storage
                    setTagInput('');
                    setIsVideoDataNew(true); // Set to true on change
                }
            } else {
                setTagInput(input);
            }
        }
    };
    
    const handleTagKeyDown = (e) => {
        if (e.key === 'Enter' && tagInput.trim() !== '') {
            e.preventDefault();
            const totalCharacters = tags.join('').length + tagInput.length;
            if (totalCharacters <= 500) {
                const newTags = [...tags, tagInput.trim()];
                setTags(newTags);
                localStorage.setItem(`${videoId}_tags`, JSON.stringify(newTags)); // Save tags to local storage
                setTagInput('');
                setIsVideoDataNew(true); // Set to true on change
            }
        }
    };

    const removeTag = (index) => {
        const newTags = tags.filter((_, i) => i !== index);
        setTags(newTags);
        localStorage.setItem(`${videoId}_tags`, JSON.stringify(newTags)); // Save tags to local storage
        setIsVideoDataNew(true); // Set to true on change

    };

    const clearAllTags = () => {
        setTags([]);
        localStorage.removeItem(`${videoId}_tags`); // Remove tags from local storage
        setIsVideoDataNew(true); // Set to true on change

    };

    const handleTagDoubleClick = (index) => {
        setEditingIndex(index);
        setEditingTag(tags[index]);
    };

    const handleEditTagChange = (e) => {
        setEditingTag(e.target.value);
    };

    const handleEditTagKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const updatedTags = [...tags];
            updatedTags[editingIndex] = editingTag.trim();
            setTags(updatedTags);
            localStorage.setItem(`${videoId}_tags`, JSON.stringify(updatedTags)); // Save tags to local storage
            setEditingIndex(-1);
            setIsVideoDataNew(true);
        }
    };

    const handleEditTagBlur = () => {
        if (editingTag.trim() !== '') {
            const updatedTags = [...tags];
            updatedTags[editingIndex] = editingTag.trim();
            setTags(updatedTags);
            localStorage.setItem(`${videoId}_tags`, JSON.stringify(updatedTags)); // Save tags to local storage
             setIsVideoDataNew(true); // Set to true on change
        }
        setEditingIndex(-1);
    };

    return (
        <div className="editBump-section-content">
            <div className="editBump-section-details">
                <div className="editBump-section-input-group">
                    <div className="editBump-title-header">
                        <div className="editBump-section-title-label">Title (required)</div>
                        <button
                            className="editBump-title-button"
                            onClick={saveEdits}
                            disabled={saveEditDebounce || !isVideoDataNew} // Still controls whether the button is clickable
                        >
                            {saveEditDebounce ? 'Saved' : (isVideoDataNew ? 'Save' : 'Save')}
                        </button>
                    </div>
                    <div className="editBump-title-wrapper">
                        <textarea
                            className="editBump-section-title-input"
                            placeholder="Add a title that describes your video"
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </div>
                    <div className="editBump-input-character-count">{title.length}/100</div>
                </div>

                <div className="editBump-section-input-group">
                    <div className="editBump-description-header">
                    <div className="editBump-description-label-wrapper">
                            <div className="editBump-section-description-label">Description</div>
                            <div className="editBump-section-description-help-text">
                            <div>Use variables like <code>{'{{VIDEO_DESCRIPTION}}'}</code> for Bump AI content. <a href="https://intercom.help/bumpups/en/articles/9742235-what-are-the-bump-ai-variables" target="_blank" rel="noopener noreferrer">Learn more</a></div>
                            </div>
                        </div>
                        <div className="button-link-wrapper">
                            <button
                                className="previewBump-button"
                                onClick={handlePreviewClick}
                                disabled={!isProcessedbyAI}
                            >
                                { 
                                isTranscriptEmpty 
                                    ? 'No Audio'
                                    : (isProcessedbyAI 
                                        ? (device === 'tablet' || device === 'mobile' ? 'View' : 'Preview') 
                                        : (device === 'tablet' || device === 'mobile' ? 'Bumping...' : 'Bumping...'))
                                }
                            </button>
                            <Link to="/settings/creator" className="editBump-settingTemplateIcon-button">
                                <img src={setting_icon} alt="Settings" className="settingTemplateIcon" />
                            </Link>
                            </div>
                    </div>
                    <div className="editBump-description-wrapper">
                        <textarea
                            className="editBump-section-description"
                            placeholder="Tell viewers about your video"
                            value={description}
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <div className="editBump-textarea-character-count">{description.length}/5000</div>
                </div>

                <div className="editBump-section-input-group">
                    <div className="editBump-section-thumbnail-label">Thumbnail</div>
                    <div className="editBump-thumbnail-description">Set a thumbnail that stands out and draws viewers' attention. <a href="https://intercom.help/bumpups/en/articles/9742267-how-do-i-set-the-perfect-thumbnail-for-my-video" target="_blank" rel="noopener noreferrer">Learn more</a></div>
                    <div className="editBump-thumbnail-container">
                            <div
                                className="editBump-thumbnail-upload-area"
                                onClick={() => !isThumbnailUploadDisabled && fileInputRef.current.click()}
                                style={{ opacity: isThumbnailUploadDisabled ? 0.5 : 1, pointerEvents: isThumbnailUploadDisabled ? 'none' : 'auto' }}
                            >
                                <img src={image_icon} alt="Upload icon" className="editBump-upload-icon" />
                                <span>Upload file</span>
                            </div>
                            <input
                                type="file"
                                id="thumbnailInput"
                                ref={fileInputRef}
                                accept="image/png, image/jpeg"
                                onChange={handleThumbnailUpload}
                                hidden
                            />
                            <div className="editBump-thumbnail-current">
                                <img src={localThumbnailUrl || thumbnailUrl || placeholder_color_thumbnail} alt="Current Thumbnail" />
                            </div>
                        </div>
                </div>


                <div className="editBump-section-input-group">
                    <div className="editBump-section-tags-label">Tags</div>
                    <div className="editBump-tags-description">
                    Tags are hashtags that make your videos more discoverable by associating them with specific topics, trends, or communities. 
                    <a href="https://intercom.help/bumpups/en/articles/9381190-how-do-video-tags-help-you" target="_blank" rel="noopener noreferrer"> Learn more</a>
                    </div>
                    <div className="editBump-tags-input-wrapper">
                        {tags.map((tag, index) => (
                            <div className="editBump-tag" key={index}>
                                {editingIndex === index ? (
                                    <input
                                        type="text"
                                        className="editBump-tag-input"
                                        value={editingTag}
                                        onChange={handleEditTagChange}
                                        onKeyDown={handleEditTagKeyDown}
                                        onBlur={handleEditTagBlur}
                                        autoFocus
                                    />
                                ) : (
                                    <span onDoubleClick={() => handleTagDoubleClick(index)}>
                                        {tag}
                                    </span>
                                )}
                                <span className="editBump-tag-close" onClick={() => removeTag(index)}>×</span>
                            </div>
                        ))}
                        <input
                            type="text"
                            className="editBump-section-tags-input"
                            placeholder="Add tag"
                            value={tagInput}
                            onChange={handleTagInput}
                            onKeyDown={handleTagKeyDown}
                        />
                        {tags.length > 0 && (
                            <button className="editBump-clear-tags-button" onClick={clearAllTags}>×</button>
                        )}
                    </div>
                    <div className="editBump-textarea-character-count">{tags.join('').length}/500</div>
                </div>
            </div>
            <PreviewBumpPopup 
                isOpen={isModalOpen} 
                onClose={handleModalClose} 
                content={processDescription(description)} 
            />
        </div>
    );


};

export default UserEditBumpSection;
