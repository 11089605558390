// src/components/UploadWorkspace/uploadlocalWorkspace.js

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, videoStorage } from '../../../../firebase';
import { doc, setDoc } from 'firebase/firestore';
// FUNCTIONS
import { getFunctions, httpsCallable } from 'firebase/functions';

// Function to validate the video file
const validateFile = (file) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            if (video.duration > 0 && video.videoWidth > 0 && video.videoHeight > 0) {
                let videoQuality;
                if (video.videoWidth >= 3840) {
                    videoQuality = '4K';
                } else if (video.videoWidth >= 1920) {
                    videoQuality = 'HD';
                } else if (video.videoWidth >= 1280) {
                    videoQuality = '720';
                } else {
                    videoQuality = 'SD';
                }
                resolve({ videoQuality, videoDuration: video.duration });
            } else {
                reject('Invalid video file');
            }
        };

        video.onerror = () => {
            window.URL.revokeObjectURL(video.src);
            reject('Invalid video file');
        };

        video.src = URL.createObjectURL(file);
    });
};
//600000
// Initialize Firebase Functions
const functions = getFunctions();
const getCaptionsBump2GB = httpsCallable(functions, 'getCaptionsBump2GB', { timeout: 600000 });
const getCaptionsBump4GB = httpsCallable(functions, 'getCaptionsBump4GB', { timeout: 600000 });
const getCaptionsBump8GB = httpsCallable(functions, 'getCaptionsBump8GB', { timeout: 600000 });
const getCaptionsBump16GB = httpsCallable(functions, 'getCaptionsBump16GB', { timeout: 900000 });
const getCaptionsBump32GB = httpsCallable(functions, 'getCaptionsBump32GB', { timeout: 1200000 });

const uploadlocalWorkspace = async (
    file,
    user,
    content_id, // New parameter
    setUploadProgress,
    setShowPopup,
    storagePathRef,
    uploadTaskRef
) => {
    if (!file) return;

    // Check if the file size exceeds the maximum allowed size (31GB)
    if (file.size > 31 * 1024 * 1024 * 1024) {
        console.error("File size exceeds the 31GB limit:", file.size);
        return;
    }

    let videoMetadata;
    try {
        videoMetadata = await validateFile(file);
    } catch (validationError) {
        // console.error("File validation error:", validationError);
        return;
    }

    // Show the upload popup
    setShowPopup(true);

    // Define metadata for the video file
    const metadata = {
        contentType: file.type,
        customMetadata: {
            uploadedBy: user.uid,
            description: 'Video upload',
            videoFileName: file.name,
            videoQuality: videoMetadata.videoQuality,
            videoDuration: videoMetadata.videoDuration.toString(),
            contentId: `workspace-${content_id}` 
        }
    };

    // Create a storage reference using content_id
    const storageRef = ref(videoStorage, `${user.uid}/workspace-${content_id}`);
    storagePathRef.current = storageRef;

    // Start the upload task
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTaskRef.current = uploadTask;

    // Monitor the upload progress
    uploadTask.on('state_changed',
        (snapshot) => {
            const videoProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgress(videoProgress);

            // console.log(`Upload is ${videoProgress}% done`);

        },
        (error) => {
            // console.error("Upload error:", error);
        },
        async () => {
            try {
                // Get the download URL after successful upload
                await getDownloadURL(uploadTask.snapshot.ref);

                // Update upload progress to 100%
                setUploadProgress(100);

                // Prepare the payload for the captions bump function
                const payload = {
                    videoMetadata: {
                        uploadedBy: user.uid,
                        contentId: `workspace-${content_id}`, // Changed from videoId to content_id
                        description: 'Video upload',
                        videoFileName: file.name,
                        videoQuality: videoMetadata.videoQuality,
                        videoDuration: videoMetadata.videoDuration.toString()
                    }
                };
                
                const durationThreshold = 5400; // 1.5 hours in seconds
                // Determine which captions bump function to use based on file size
                let captionsFunction;
                if (file.size <= 1 * 1024 * 1024 * 1024 && videoMetadata.videoDuration <= durationThreshold) { // ≤1GB & ≤1.5 hrs
                    captionsFunction = getCaptionsBump2GB;
                  } else if (
                    (file.size <= 3 * 1024 * 1024 * 1024 && videoMetadata.videoDuration <= durationThreshold) ||
                    (file.size <= 1 * 1024 * 1024 * 1024 && videoMetadata.videoDuration > durationThreshold)
                  ) { // ≤3GB & ≤1.5 hrs OR ≤1GB & >1.5 hrs
                    captionsFunction = getCaptionsBump4GB;
                  } else if (
                    (file.size <= 7 * 1024 * 1024 * 1024 && videoMetadata.videoDuration <= durationThreshold) ||
                    (file.size <= 3 * 1024 * 1024 * 1024 && videoMetadata.videoDuration > durationThreshold)
                  ) { // ≤7GB & ≤1.5 hrs OR ≤3GB & >1.5 hrs
                    captionsFunction = getCaptionsBump8GB;
                  } else if (
                    (file.size <= 15 * 1024 * 1024 * 1024 && videoMetadata.videoDuration <= durationThreshold) ||
                    (file.size <= 7 * 1024 * 1024 * 1024 && videoMetadata.videoDuration > durationThreshold)
                  ) { // ≤15GB & ≤1.5 hrs OR ≤7GB & >1.5 hrs
                    captionsFunction = getCaptionsBump16GB;
                  } else if (
                    (file.size <= 31 * 1024 * 1024 * 1024 && videoMetadata.videoDuration <= durationThreshold) ||
                    (file.size <= 15 * 1024 * 1024 * 1024 && videoMetadata.videoDuration > durationThreshold)
                  ) { // ≤31GB & ≤1.5 hrs OR ≤15GB & >1.5 hrs
                    captionsFunction = getCaptionsBump32GB;
                  } else {
                    console.error('File size exceeds the 32GB limit:', file.size);
                    return;
                  }

                // Call the appropriate captions bump function
                await captionsFunction(payload);

            } catch (error) {
                // Check specifically for timeout-related error
                if (error.code === 'deadline-exceeded' || (error.message && error.message.includes('deadline-exceeded'))) {
                    // console.warn('Timeout occurred during caption processing. Updating Firestore.');
            
                    // Reference Firestore documents
                    const userDocRef = doc(db, 'users', user.uid);
                    const workspaceDocRef = doc(userDocRef, 'workspaceContent', `workspace-${content_id}`);
            
                    try {
                        // Attempt to set is_local_caption_processing to false with merge
                        await setDoc(workspaceDocRef, {
                            is_local_caption_processing: false
                        }, { merge: true });
            
                        // Log success confirmation
                        // console.log("Firestore update successful: 'is_local_caption_processing' set to false.");
            
                    } catch (updateError) {
                        // Log if there was an error updating Firestore
                        // console.error("Failed to update Firestore:", updateError);
                    }
            
                    // Notify the user of the timeout specifically
                    setShowPopup(false);
                    alert("The video processing request timed out. Please try again later.");
                } else {
                    // Handle any other non-timeout error cases without changing Firestore
                    // console.error("An error occurred during the video processing:", error);
                }
            }
            
            
        }
    );
};

export { uploadlocalWorkspace, validateFile };
