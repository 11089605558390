import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

// FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

// FUNCTIONS
import { AuthContext } from '../AuthContext';

// IMPORT UI
import CloseSidebar from './CloseSidebar';

// IMAGES
import comment_icon from '../assets/images/icons/comment-icon.png';
import help_icon from '../assets/images/icons/help-icon.png';
import dashboard_icon from '../assets/images/icons/dashboard-icon.png';
import setting_icon from '../assets/images/icons/setting-icon.png';
import youtube_white_logo from '../assets/images/logo/youtube-white-logo.png';

function Sidebar() {
  const location = useLocation();
  const currentView = location.pathname;
  const { currentUser } = useContext(AuthContext);

  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 1280);

  const initialState = localStorage.getItem(`currentPlan_${auth.currentUser?.uid}`) || 'Free Plan';
  const [currentPlan, setCurrentPlan] = useState(initialState);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1280);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const planDetailsRef = doc(db, 'users', user.uid, 'subscriptionData', 'planDetails');

      const fetchUserDetails = async () => {
        try {
          const planDoc = await getDoc(planDetailsRef);
          if (planDoc.exists()) {
            const currentPlan = planDoc.data().currentPlan;
            setCurrentPlan(currentPlan);
            localStorage.setItem(`currentPlan_${user.uid}`, currentPlan);
          }
        } catch (error) {
          // console.error("Error fetching user details: ", error);
        }
      };

      fetchUserDetails();

      const unsubscribePlan = onSnapshot(planDetailsRef, (docSnap) => {
        if (docSnap.exists()) {
          const newCurrentPlan = docSnap.data().currentPlan;
          const storedCurrentPlan = localStorage.getItem(`currentPlan_${user.uid}`);

          if (!storedCurrentPlan || storedCurrentPlan !== newCurrentPlan) {
            setCurrentPlan(newCurrentPlan);
            localStorage.setItem(`currentPlan_${user.uid}`, newCurrentPlan);
          }
        }
      });

      return () => {
        unsubscribePlan();
      };
    }
  }, [currentUser]);

  // Helper to check for /bumper or /links (using startsWith for any sub-route)
  const isBumperOrLinks = () =>
    currentView.startsWith('/bumper') || currentView.startsWith('/links') || currentView.startsWith('/video');

  // Modified to check if the current view starts with the given view
  const isCurrentView = (view) => currentView.startsWith(view);

  // Use the modified check for active link classes
  const getLinkClass = (view) =>
    view === '/bumper'
      ? `sidebar-link ${isBumperOrLinks() ? 'active' : ''}`
      : `sidebar-link ${isCurrentView(view) ? 'active' : ''}`;

  const getIconClass = (view) =>
    view === '/bumper'
      ? `link-icon ${isBumperOrLinks() ? 'active-icon' : ''}`
      : `link-icon ${isCurrentView(view) ? 'active-icon' : ''}`;

  if (isNarrowScreen) {
    return <CloseSidebar />;
  }

  return (
    <div className="sidebar">
      <Link
        to="/dashboard"
        className={getLinkClass('/dashboard')}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <img src={dashboard_icon} alt="Dashboard" className={getIconClass('/dashboard')} />
        <span className="sidebar-text">Dashboard</span>
      </Link>

      <div className="dividersides"></div>

      <Link
        to="/workspace"
        className={getLinkClass('/workspace')}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <img src={comment_icon} alt="Chat" className={getIconClass('/workspace')} />
        <span className="sidebar-text">Chat Workspace</span>
      </Link>

      <Link
        to="/bumper"
        className={getLinkClass('/bumper')}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <img src={youtube_white_logo} alt="YouTube Studio" className={getIconClass('/bumper')} />
        <span className="sidebar-text">Creator Studio</span>
      </Link>

      <a
        href="https://intercom.help/bumpups/en/"
        target="_blank"
        rel="noopener noreferrer"
        className={getLinkClass('/help')}
      >
        <img src={help_icon} alt="Help" className={getIconClass('/help')} />
        <span className="sidebar-text">Help</span>
      </a>

      <div className="plan-section">
        <div className="sidebar-footer-details">
          <Link to="/plans" className="plan-link">
            {currentPlan === "bump"
              ? "Bump Plan"
              : currentPlan === "base"
              ? "Base Plan"
              : currentPlan}
          </Link>
          <Link to="/settings" className="side-settings-button">
            <img src={setting_icon} alt="Settings" className="side-settings-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
