import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './commentTemplate.css';

// FIREBASE
import { db, auth } from '../../../firebase';
import { doc, setDoc, onSnapshot, updateDoc } from 'firebase/firestore';

// FUNCTIONS
import { debounce } from 'lodash';

// IMAGES
import placeholder_profile from '../../../assets/images/profile/placeholder-profile.jpg';

const CommentTemplate = () => {
  const user = auth.currentUser;
  const defaultFixedText = 'Hey everyone!\nHere are important parts of our video:';
  const localStorageData = user ? sessionStorage.getItem(`userData_${user.uid}`) : null;
  const userData = localStorageData ? JSON.parse(localStorageData) : {};

  // State initialization
  const [correctWords, setCorrectWords] = useState(userData.correctWordDoc?.correctWords || '');
  const [fixedText, setFixedText] = useState(userData.brandingData?.fixedText || defaultFixedText);
  const [profileImage, setProfileImage] = useState(userData.profileImage || '');
  const [branding, setBranding] = useState(
    userData.brandingData?.isBrandingEnabled !== undefined ? userData.brandingData.isBrandingEnabled : true
  );
  const [currentPlan, setCurrentPlan] = useState(userData.subscriptionData?.currentPlan || 'Free Plan');
  const [numberTimestamps, setNumberTimestamps] = useState(7);
  const [textError, setTextError] = useState(false);
  const [wordsError, setWordsError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  // New state for auto-comment toggle
  const [isToggledcomment, setIsToggledcomment] = useState(
    userData.isAutoCommentEnabled !== undefined ? userData.isAutoCommentEnabled : true
  );

  // Debounced save function for timestamps and fixed text
  const debouncedSaveToFirestore = debounce(async () => {
    const user = auth.currentUser;
    if (!user) return;
    const timestampTempDoc = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');
    const correctWordsTempDoc = doc(db, 'users', user.uid, 'userSettings', 'profile');

    await setDoc(
      timestampTempDoc,
      {
        fixedText: fixedText,
        isBrandingEnabled: branding
      },
      { merge: true }
    );

    await setDoc(
      correctWordsTempDoc,
      {
        correctWords: correctWords
      },
      { merge: true }
    );

    const sessionStorageData = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
    sessionStorageData.fixedTextData = { ...sessionStorageData.fixedTextData, fixedText: fixedText };
    sessionStorageData.brandingData = { ...sessionStorageData.brandingData, isBrandingEnabled: branding };
    sessionStorageData.correctWords = { ...sessionStorageData.correctWordDoc, correctWords: correctWords };

    sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify(sessionStorageData));

    setIsSaved(true);
    setTimeout(() => {
      setIsSaved(false);
    }, 1500);
  }, 700);

  // Debounced function for toggling auto-comment setting
  const debouncedCommentHandleToggle = debounce(async (newValue) => {
    setIsToggledcomment(newValue);
    const user = auth.currentUser;
    if (user) {
      const userSettingsRef = doc(db, "users", user.uid, "autoSettings", "youtubeToggles");
      try {
        await updateDoc(userSettingsRef, {
          isAutoCommentEnabled: newValue
        });
      } catch (error) {
        // Handle error as necessary
        console.error("Error updating auto comment setting:", error);
      }
    }
  }, 500);

  // Firestore listeners
  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const timestampProfileRef = doc(userDocRef, 'valueSettings', 'timestamps');
      const subscriptionRef = doc(userDocRef, 'subscriptionData', 'planDetails');
      const profileCorrectRef = doc(userDocRef, 'userSettings', 'profile');
      const autoCommentRef = doc(userDocRef, "autoSettings", "youtubeToggles");

      const localStorageData = sessionStorage.getItem(`userData_${user.uid}`);

      if (localStorageData) {
        const { brandingData, subscriptionData, profileImage, correctWordDoc, isAutoCommentEnabled } = JSON.parse(localStorageData);
        setCorrectWords(correctWordDoc?.correctWords || '');
        setFixedText(brandingData?.fixedText || 'Hey Everyone 👋\nFind the parts that interest you:');
        setCurrentPlan(subscriptionData?.currentPlan || 'Free Plan');
        setBranding(brandingData?.isBrandingEnabled !== undefined ? brandingData.isBrandingEnabled : true);
        setProfileImage(profileImage);
        setIsToggledcomment(isAutoCommentEnabled !== undefined ? isAutoCommentEnabled : true);
      }

      const unsubscribeBranding = onSnapshot(timestampProfileRef, (doc) => {
        if (doc.exists()) {
          const brandingData = doc.data();
          setFixedText(brandingData.fixedText || 'Hey Everyone 👋\nFind the parts that interest you:');
          setBranding(brandingData.isBrandingEnabled !== undefined ? brandingData.isBrandingEnabled : true);
          sessionStorage.setItem(
            `userData_${user.uid}`,
            JSON.stringify({
              ...JSON.parse(localStorageData || '{}'),
              brandingData
            })
          );
        }
      });

      const unsubscribeSubscription = onSnapshot(subscriptionRef, (doc) => {
        if (doc.exists()) {
          const subscriptionData = doc.data();
          setCurrentPlan(subscriptionData.currentPlan || 'Free Plan');
          sessionStorage.setItem(
            `userData_${user.uid}`,
            JSON.stringify({
              ...JSON.parse(localStorageData || '{}'),
              subscriptionData
            })
          );
        }
      });

      const unsubscribeUser = onSnapshot(profileCorrectRef, (doc) => {
        if (doc.exists()) {
          const profileImageData = doc.data().profileImage;
          setProfileImage(profileImageData);
          sessionStorage.setItem(
            `userData_${user.uid}`,
            JSON.stringify({
              ...JSON.parse(localStorageData || '{}'),
              profileImage: profileImageData
            })
          );
        }
      });

      const unsubscribeCorrectData = onSnapshot(profileCorrectRef, (doc) => {
        if (doc.exists()) {
          const correctWordDoc = doc.data();
          setCorrectWords(correctWordDoc.correctWords || '');
          const localStorageDataParsed = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
          sessionStorage.setItem(
            `userData_${user.uid}`,
            JSON.stringify({
              ...localStorageDataParsed,
              fixedTextData: { ...localStorageDataParsed.correctWordDoc, correctWords: correctWordDoc.correctWords }
            })
          );
        }
      });

      // Listener for auto-comment toggle state
      const unsubscribeAutoComment = onSnapshot(autoCommentRef, (doc) => {
        if (doc.exists()) {
          const { isAutoCommentEnabled } = doc.data();
          setIsToggledcomment(isAutoCommentEnabled);
          const localStorageDataParsed = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
          sessionStorage.setItem(
            `userData_${user.uid}`,
            JSON.stringify({
              ...localStorageDataParsed,
              isAutoCommentEnabled
            })
          );
        }
      });

      return () => {
        unsubscribeBranding();
        unsubscribeSubscription();
        unsubscribeUser();
        unsubscribeCorrectData();
        unsubscribeAutoComment();
      };
    }
  }, [user]);

  // Reset save state when values change
  useEffect(() => {
    setIsSaved(false);
  }, [numberTimestamps, fixedText, correctWords]);

  // Input change handlers
  const handleFixedTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length > 50) {
      setTextError(true);
    } else {
      setTextError(false);
      setFixedText(newText);
    }
  };

  const handleNumberChange = (e) => {
    const newNumber = e.target.value;
    if (newNumber >= 1 && newNumber <= 10) {
      setNumberTimestamps(newNumber);
    }
  };

  const handleWordsChange = (e) => {
    const newWords = e.target.value;
    if (newWords.length > 40) {
      setWordsError(true);
    } else {
      setWordsError(false);
      setCorrectWords(newWords);
    }
  };

  // Generates sample timestamps preview
  const generateSampleTimestamps = () => {
    let sampleTimestamps = [];
    const sampleTexts = [
      { time: '01:05', text: 'Why timestamps are crucial on YouTube' },
      { time: '04:12', text: 'Psychological aspects of engaging users with timestamps' },
      { time: '07:23', text: 'How timestamps boost video SEO rankings' },
      { time: '11:37', text: 'Comparing videos with and without timestamps - a case study' },
      { time: '16:49', text: 'Crafting effective timestamps - best practices' },
      { time: '22:26', text: 'Tools and software for automating timestamps' },
      { time: '28:08', text: 'Mistakes to avoid when creating timestamps' },
      { time: '33:33', text: 'Updating older videos with retroactive timestamps' },
      { time: '39:47', text: 'How timestamps contribute to audience retention' },
      { time: '44:55', text: 'Leveraging timestamps for YouTube channel growth' }
    ];
    for (let i = 0; i < numberTimestamps; i++) {
      const sample = sampleTexts[i] || { time: '00:00', text: 'concise description.' };
      sampleTimestamps.push(
        <div key={i}>
          <span className="timestamp-time">{sample.time}</span> -{' '}
          <span className="timestamp-text">{sample.text}</span>
        </div>
      );
    }
    return sampleTimestamps;
  };

  return (
    <>
      {/* Header outside the container */}
      <div className="setting-header">Tools</div>
  
      {/* Main container */}
      <div className="account-settings-container">
        {/* Top Row with Active toggle button and Save button */}
        <div className="settings-row">
          <div className="settings-label">
              Auto-Comment Timestamps
            <div className="settings-subtitle">
              Save how your comment appears on YouTube
            </div>
          </div>
          <div className="comment-settings-field">
            <button
              className={`comment-active-btn ${isToggledcomment ? '' : 'inactive'}`}
              onClick={() => debouncedCommentHandleToggle(!isToggledcomment)}
            >
              {isToggledcomment ? 'Active' : 'Inactive'}
            </button>
            <button
              className={`ui-setting-button ${isSaved ? 'ui-setting-button' : ''}`}
              onClick={debouncedSaveToFirestore}
              disabled={!isToggledcomment}
            >
              {isSaved ? 'Saved' : 'Save'}
            </button>
          </div>
        </div>
  
        {/* Form Container */}
        <div className={`form-container ${isToggledcomment ? '' : 'disabled'}`}>
          {/* Row: Introduction */}
          <div className="settings-row">

          <div className="comment-settings-field-fixtext">
              <textarea
                id="fixedText"
                value={fixedText}
                onChange={handleFixedTextChange}
                rows="4"
                // Optionally remove cols since we’re controlling width via CSS
                className={textError ? 'input-error' : ''}
              />
              <div className="char-count">{`${fixedText.length}/50`}</div>
            </div>

          </div>
  
          {/* Row: Preview Number */}
          <div className="settings-row">
            <div className="settings-label">
              Comment Preview
              <div className="settings-subtitle">Set number of timestamps to preview (1-10)</div>
            </div>
            <div className="csettings-comment-settings-field-preview">
              <button className="ui-setting-button" onClick={() => numberTimestamps > 1 && setNumberTimestamps(n => n - 1)} disabled={numberTimestamps <= 1}>-</button>
              <input id="numberTimestamps" type="number" value={numberTimestamps} onChange={handleNumberChange} className="csettings-timestamp-input" />
              <button className="ui-setting-button" onClick={() => numberTimestamps < 10 && setNumberTimestamps(n => n + 1)} disabled={numberTimestamps >= 10}>+</button>
            </div>
          </div>

  
          {/* Row: Auto-Correct */}
          <div className="settings-row">
            <div className="settings-label">
              Auto-Correct
              <div className="settings-subtitle">
                List words often misspelled (comma separated)
              </div>
            </div>
            <div className="comment-settings-field-auto">
              <input
                id="correctWords"
                type="text"
                value={correctWords}
                onChange={handleWordsChange}
                className={wordsError ? 'input-error' : ''}
                placeholder="word-1, word-2, word-3"
              />
            </div>
          </div>

          {/* Row: Preview Comment */}
          <div className="settings-row">
            <div className="comment-settings-field">
              <div className="preview-comment">
                <img
                  src={profileImage || placeholder_profile}
                  alt="User Profile"
                  className="prevprofile-image"
                />
                <div className="preview-text">
                  {fixedText.split('\n').map((line, i) => (
                    <div className="preview-line" key={i}>
                      {line}
                    </div>
                  ))}
                  {generateSampleTimestamps()}
                  {branding && <div>Chat with videos via Bumpups 🌲</div>}
                </div>
              </div>
            </div>
          </div>
  
          {/* Row: Remove Branding */}
          <div className="settings-row">
            <div className="settings-label">
              Remove Branding
              <div className="settings-subtitle">
                Upgrade plan to remove "Chat with videos via Bumpups 🌲"
              </div>
            </div>
            <div className="comment-settings-field">
              <input
                id="tubestampBranding"
                type="checkbox"
                className="styled-checkbox"
                onChange={() => setBranding(!branding)}
                checked={!branding}
                disabled={currentPlan === 'Free Plan'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentTemplate;
