// src/pages/SubscriptionPage.js

import React, { useState, useEffect, useCallback } from 'react';
import './SubscriptionPage.css';

// FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot, collection, query, orderBy } from 'firebase/firestore';

// FUNCTIONS
import AppTransition from '../components/appComponents/AppTransition';

// IMPORT UI
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';
import SubPlanSection from './SubPlanSection'; 
import OneTimeSection from './OneTimeSection'; 
import PurchaseLog from './PurchaseLog'; 

const SubscriptionPage = ({ isSidebarOpen, toggleSidebar }) => {

  // Helper function to safely convert Firebase timestamp to JavaScript Date
  const toDate = (timestamp) => {
    if (timestamp?.toDate) {
      return timestamp.toDate(); 
    } else if (typeof timestamp === 'number') {
      return new Date(timestamp * 1000); 
    } else {
      return null; 
    }
  };

  const formatDate = useCallback((timestamp) => {
    const dateObject = toDate(timestamp);
    if (!dateObject) return null; 
    
    return dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }, []);

  const user = auth.currentUser;

  // Function to get initial values from sessionStorage or set default values
  const getInitialValues = () => {
    if (user) {
      const sessionData = sessionStorage.getItem(`userData_${user.uid}`);
      if (sessionData) {
        const data = JSON.parse(sessionData);

        let formattedRenewalDate = null;
        if (data.RenewalDate) {
          const renewalDateObject = new Date(data.RenewalDate.seconds * 1000);
          formattedRenewalDate = renewalDateObject.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
        }
        return {
          isActive: data.isActive,
          currentPlan: data.currentPlan,
          totalPlanTime: data.totalPlanTime,
          price: data.price,
          formattedRenewalDate: formattedRenewalDate,
          billingEmail: data.billingEmail // Initialize billingEmail
        };
      }
    }
    return { isActive: true, currentPlan: "Loading...", totalPlanTime: null, price: 0, formattedRenewalDate: null, billingEmail: null };
  };

  const initial = getInitialValues();

  // Initialize state with values from sessionStorage or default values
  const [isActive, setIsActive] = useState(initial.isActive);
  const [currentPlan, setCurrentPlan] = useState(initial.currentPlan);
  const [timeBank, setTimeBank] = useState(0); 
  const [timeBankBump, setTimeBankBump] = useState(0); 
  const [totalPlanTime, setTotalPlanTime] = useState(initial.totalPlanTime);
  const [price, setPrice] = useState(initial.price);
  const [renewalDate, setRenewalDate] = useState(initial.formattedRenewalDate);
  const [billingEmail, setBillingEmail] = useState(initial.billingEmail); // Add billingEmail state
  const [showPlanPopUp, setShowPlanPopUp] = useState(false);
  
  // New state for purchase history
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const planDetailsRef = doc(userDocRef, "subscriptionData", "planDetails");
      const timeWardenRef = doc(userDocRef, "subscriptionData", "timeWarden");
      const purchaseHistoryRef = collection(userDocRef, "purchaseHistory"); // Reference to purchaseHistory subcollection

      const sessionUserData = sessionStorage.getItem(`userData_${user.uid}`);
      if (sessionUserData) {
        const { isActive, currentPlan, totalPlanTime, price, RenewalDate, billingEmail } = JSON.parse(sessionUserData); // Add billingEmail
        setIsActive(isActive);
        setCurrentPlan(currentPlan);
        setTotalPlanTime(totalPlanTime);
        setPrice(price);
        setRenewalDate(formatDate(RenewalDate));
        setBillingEmail(billingEmail); // Set billingEmail from session data
      }

      // Listen to planDetails
      const unsubscribePlanDetails = onSnapshot(planDetailsRef, (doc) => {
        if (doc.exists()) {
          const newPlanData = doc.data();
          setIsActive(newPlanData.IsActive);
          setCurrentPlan(newPlanData.currentPlan);
          setTotalPlanTime(newPlanData.TotalPlanTime);
          setPrice(newPlanData.Price);
          setRenewalDate(formatDate(newPlanData.RenewalDate));
          setBillingEmail(newPlanData.StripeCustomerEmail || null); // Set billingEmail from Firestore

          const userData = {
            isActive: newPlanData.IsActive,
            currentPlan: newPlanData.currentPlan,
            totalPlanTime: newPlanData.TotalPlanTime,
            price: newPlanData.Price,
            RenewalDate: newPlanData.RenewalDate,
            billingEmail: newPlanData.StripeCustomerEmail || null // Store billingEmail in session
          };
          sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
        }
      });

      // Listen to timeWarden
      const unsubscribeTimeBank = onSnapshot(timeWardenRef, (doc) => {
        if (doc.exists() && doc.data().activeTimes) {
          setTimeBank(doc.data().timeBank);
          setTimeBankBump(doc.data().timeBankBump);
        }
      });

      // Listen to purchaseHistory
      const purchaseHistoryQuery = query(purchaseHistoryRef, orderBy("purchase_datetime", "desc")); // Order by purchase_datetime descending
      const unsubscribePurchaseHistory = onSnapshot(purchaseHistoryQuery, (snapshot) => {
        const purchases = snapshot.docs.map(doc => ({
          id: doc.id,
          plan_type: doc.data().plan_type,
          purchase_datetime: formatDate(doc.data().purchase_datetime),
          purchase_type: doc.data().purchase_type,
          total_minutes: doc.data().total_minutes,
        }));
        setPurchaseHistory(purchases);

        // Optionally, store in sessionStorage
        sessionStorage.setItem(`purchaseHistory_${user.uid}`, JSON.stringify(purchases));
      });

      // Retrieve purchase history from sessionStorage if available
      const sessionPurchaseHistory = sessionStorage.getItem(`purchaseHistory_${user.uid}`);
      if (sessionPurchaseHistory) {
        setPurchaseHistory(JSON.parse(sessionPurchaseHistory));
      }

      return () => {
        unsubscribePlanDetails();
        unsubscribeTimeBank();
        unsubscribePurchaseHistory(); // Clean up the purchaseHistory listener
      };
    }
  }, [user, formatDate]);

  const openContactPage = () => {
    window.open("https://bumpups.zapier.app/get-in-touch", "_blank");
  };

  const openStripeBilling = () => {
    window.open("https://billing.stripe.com/p/login/9AQcNCf6w0SS6C4000", "_blank");
  };

  return (
    <div className="metasub-container">
      {isSidebarOpen && <Sidebar />}
      {!isSidebarOpen && <CloseSidebar />}
      <AppTransition>
        <div className="subscription-page-container">

          {/* Container for SubPlanSection and OneTimeSection */}
          <div className="subscription-top-sections">
            <SubPlanSection
              isActive={isActive}
              currentPlan={currentPlan}
              timeBank={timeBank}
              timeBankBump={timeBankBump}
              showPlanPopUp={showPlanPopUp}
              setShowPlanPopUp={setShowPlanPopUp}
              billingEmail={billingEmail}
              price={price}
              totalPlanTime={totalPlanTime}
              renewalDate={renewalDate}
              openStripeBilling={openStripeBilling}
              openContactPage={openContactPage}
            />
            <OneTimeSection />
          </div>

          {/* PurchaseLog below the top sections */}
          <div className="purchase-log-wrapper">
            <PurchaseLog purchaseHistory={purchaseHistory} />
          </div>

        </div>
      </AppTransition>
    </div>
  );
};

export default SubscriptionPage;
