import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { doc, updateDoc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { auth, db, storage } from '../../../firebase';

import placeholder_profile from '../../../assets/images/profile/placeholder-profile.jpg';
import upload_icon from '../../../assets/images/icons/upload-icon.png';

import './generalSettings.css';
import '../../UserSettings.css';

const languages = [
  { value: 'en', label: 'English (English)', name: 'English' },
  { value: 'hi', label: 'Hindi (हिन्दी)', name: 'Hindi' },
  { value: 'es', label: 'Spanish (Español)', name: 'Spanish' },
  { value: 'pt', label: 'Portuguese (Português)', name: 'Portuguese' },
  { value: 'ru', label: 'Russian (Русский)', name: 'Russian' },
  { value: 'de', label: 'German (Deutsch)', name: 'German' },
  { value: 'fr', label: 'French (Français)', name: 'French' },
  { value: 'ja', label: 'Japanese (日本語)', name: 'Japanese' },
  { value: 'ko', label: 'Korean (한국어)', name: 'Korean' },
  { value: 'ar', label: 'Arabic (العربية)', name: 'Arabic' }
];

const GeneralSettings = () => {
  const [profileImage, setProfileImage] = useState(placeholder_profile);
  const [email, setEmail] = useState('');

  // Language states
  const [language, setLanguage] = useState('en');
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [languageSaved, setLanguageSaved] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(
    localStorage.getItem(`currentPlan_${auth.currentUser?.uid}`) || 'Free Plan'
  );
  // File input ref for image upload
  const fileInputRef = useRef(null);

  // Load initial user data and settings
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      // Load user data (profile image and email)
      const localStorageUserData = localStorage.getItem(`userData_${user.uid}`);
      if (localStorageUserData) {
        const userData = JSON.parse(localStorageUserData);
        setProfileImage(userData.profileImage || placeholder_profile);
        setEmail(userData.email || '');
      } else {
        const fetchUserData = async () => {
          const userDocRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setEmail(userData.email || '');
            if (userData.profileImage) {
              setProfileImage(userData.profileImage);
            }
            localStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
          }
        };
        fetchUserData();
      }

      // Load language setting from local storage or Firestore
      const localStorageLanguage = localStorage.getItem(`userDefaultLanguage_${user.uid}`);
      if (localStorageLanguage) {
        setLanguage(localStorageLanguage);
      } else {
        const fetchLanguage = async () => {
          const profileRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
          const profileDoc = await getDoc(profileRef);
          if (profileDoc.exists() && profileDoc.data().userDefaultLanguage) {
            setLanguage(profileDoc.data().userDefaultLanguage);
            localStorage.setItem(`userDefaultLanguage_${user.uid}`, profileDoc.data().userDefaultLanguage);
          }
        };
        fetchLanguage();
      }

      // NEW: Load current plan from local storage or Firestore
      const localStoragePlan = localStorage.getItem(`userCurrentPlan_${user.uid}`);
      if (localStoragePlan) {
        setCurrentPlan(localStoragePlan);
      } else {
        const fetchPlan = async () => {
          const planDetailsRef = doc(db, 'users', user.uid, 'subscriptionData', 'planDetails');
          const planDoc = await getDoc(planDetailsRef);
          if (planDoc.exists() && planDoc.data().currentPlan) {
            setCurrentPlan(planDoc.data().currentPlan);
            localStorage.setItem(`userCurrentPlan_${user.uid}`, planDoc.data().currentPlan);
          }
        };
        fetchPlan();
      }
    }
  }, []);

  // Real-time snapshot listeners for up-to-date data
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      // Listen to email updates
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribeUser = onSnapshot(userDocRef, (userDocSnapshot) => {
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setEmail(userData.email || '');
          localStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
        }
      });

      // Listen to profile image updates
      const userProfileImageRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
      const unsubscribeProfileImage = onSnapshot(userProfileImageRef, (profileSnapshot) => {
        if (profileSnapshot.exists()) {
          const profileData = profileSnapshot.data();
          setProfileImage(profileData.profileImage || placeholder_profile);
          localStorage.setItem(`userProfileImage_${user.uid}`, profileData.profileImage || placeholder_profile);
        }
      });

      // NEW: Listen to subscription plan updates
      const planDetailsRef = doc(db, 'users', user.uid, 'subscriptionData', 'planDetails');
      const unsubscribePlan = onSnapshot(planDetailsRef, (planSnapshot) => {
        if (planSnapshot.exists()) {
          const planData = planSnapshot.data();
          if (planData.currentPlan) {
            setCurrentPlan(planData.currentPlan);
            localStorage.setItem(`userCurrentPlan_${user.uid}`, planData.currentPlan);
          }
        }
      });

      return () => {
        unsubscribeUser();
        unsubscribeProfileImage();
        unsubscribePlan();
      };
    }
  }, []);

  // Handle image upload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const user = auth.currentUser;
    if (file && file.size < 1024 * 1024 && user) {
      try {
        const storageRef = ref(storage, `profileImages/${user.uid}`);
        await uploadBytes(storageRef, file);
        const photoURL = await getDownloadURL(storageRef);

        const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
        await updateDoc(userDocRef, { profileImage: photoURL });
        setProfileImage(photoURL);
        localStorage.setItem(`profileImage_${user.uid}`, photoURL);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      alert('File is too large. Please select a file less than 1MB.');
    }
  };

  // Language change handler
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    const user = auth.currentUser;
    if (user) {
      setIsLanguageChanged(newLanguage !== localStorage.getItem(`userDefaultLanguage_${user.uid}`));
    }
  };

  // Save language setting with debounce
  const saveLanguageSetting = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const debounceKey = `${user.uid}_language_debounce`;
    const lastSaveTimestamp = localStorage.getItem(debounceKey);
    const currentTime = Date.now();

    if (lastSaveTimestamp && currentTime - lastSaveTimestamp < 5000) {
      return;
    }

    setIsSaving(true);
    localStorage.setItem(debounceKey, currentTime.toString());

    try {
      const userDocRef = doc(db, 'users', user.uid);
      const profileRef = doc(userDocRef, 'userSettings', 'profile');
      await setDoc(profileRef, { userDefaultLanguage: language }, { merge: true });
      localStorage.setItem(`userDefaultLanguage_${user.uid}`, language);
      setLanguageSaved(true);
      setIsLanguageChanged(false);
      setTimeout(() => setLanguageSaved(false), 3000);
    } catch (error) {
      console.error('Error saving language:', error);
    }

    setTimeout(() => {
      setIsSaving(false);
      localStorage.removeItem(debounceKey);
    }, 5000);
  };

  return (
    <>
      <div className="setting-header">General</div>
      <div className="account-settings-container">
        {/* Avatar Row */}
        <div className="settings-row">
          <div className="settings-label">Avatar</div>
          <div className="settings-field">
            <div className="avatar-container">
              <img src={profileImage} alt="User Avatar" className="avatar-image" />
              <button className="avatar-upload-button" onClick={() => fileInputRef.current.click()}>
                <img src={upload_icon} alt="Upload" />
              </button>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              accept=".png, .jpg, .jpeg"
            />
          </div>
        </div>

        {/* Email Row */}
        <div className="settings-row">
          <div className="settings-label">Email</div>
          <div className="settings-field">
            <span>{email}</span>
          </div>
        </div>

        {/* Plan Row */}
        <div className="settings-row">
          <div className="settings-label">Subscription</div>
          <div className="settings-field">
            <span>
              {currentPlan === "bump"
                ? "Bump Plan"
                : currentPlan === "base"
                ? "Base Plan"
                : currentPlan}
            </span>
            <Link to="/plans" style={{ textDecoration: 'none' }}>
              <button className="ui-setting-button">View Details</button>
            </Link>
          </div>
        </div>


        {/* Language Row */}
        <div className="settings-row">
          <div className="settings-label">
            AI Output Language
            <div className="settings-subtitle">The language used for AI responses</div>
          </div>
          <div className="language-settings-field">
            <div className="select-wrapper">
              <select className="language-dropdown" value={language} onChange={handleLanguageChange}>
                {languages.map((lang) => (
                  <option key={lang.value} value={lang.value}>
                    {lang.label}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="ui-setting-button"
              onClick={saveLanguageSetting}
              disabled={isSaving || !isLanguageChanged}
            >
              {languageSaved ? 'Saved' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettings;
