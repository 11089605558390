import React from 'react';
import { Helmet } from 'react-helmet';
import './TermsOfService.css';

//IMPORT UI
import Footer from '../unAuthPages/Footer';


const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <Helmet>
      <title>Bumpups: Privacy Policy</title>
      <meta name="description" content="Learn how Bumpups protects your privacy and handles your data. Your trust is our priority." />
    </Helmet>
      <h1 className="terms-title">Privacy Policy</h1>
      <div className="terms-text-container">
        <p>Effective date: April 14th, 2024</p>

        <p>At Bumpups, your privacy is critically important to us. This Privacy Policy outlines how we manage your personal data. By interacting with our Services in any capacity, you are acknowledging and agreeing to the practices and policies outlined in this Privacy Policy, and you give us consent to collect, use, and share your information as described herein.</p>
        <p>Please note that your use of Bumpups's Services is always subject to our Terms of Services, which incorporates this Privacy Policy. Any terms that are not defined in this Policy are defined in the Terms of Services.</p>
        <p>As Bumpups continually strives to enhance its Services, we may find it necessary to update this Privacy Policy periodically. We will notify you of any such changes by posting an announcement on the Bumpups website, sending you an email, or through other appropriate means. It's important to note that even if you have chosen not to receive legal notices from us via email, or have not provided an email address, these notices will still be applicable to your use of our Services. You are responsible for staying informed by regularly reviewing these notices. Continued use of the Services following any modifications to this Privacy Policy signifies your acceptance of such changes.</p>
        <h3>What this Privacy Policy Covers</h3>
        <p>This Privacy Policy outlines how we manage the Personal Data we collect when you access or utilize our Services. "Personal Data" refers to any information that can be used to identify an individual and encompasses terms like "personally identifiable information" or "personal information" as defined by relevant data privacy laws, rules, or regulations. It's important to note that this Privacy Policy does not extend to the practices of entities that are not owned or controlled by Bumpups, nor to individuals who are not employed or managed by us.</p>
        <h3>Google API Services User Data Policy</h3>
<p>Bumpups's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer" class="policyLinks">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>

        <h3>YouTube API Services Data Usage and Consent</h3>
        <p>
        Bumpups utilizes YouTube API Services to automate the generation of timestamps for YouTube videos, enhancing your content management experience. Our application specifically accesses video metadata such as titles, durations, and captions, which are necessary to provide our services. We are committed to ensuring the confidentiality and integrity of your data.
        </p>
        <p>
          Here's how we handle your data:
          <ul>
            <li><strong>Data Storage and Security:</strong> The video metadata we collect is solely for the purpose of timestamp generation. This information is stored temporarily and is overwritten during the processing of new videos. We employ robust encryption methods to safeguard your data while in our custody.</li>
            <li><strong>Monthly Data Clearance:</strong> To further protect your privacy, we have implemented a monthly clearance procedure that runs automatically. This procedure deletes all stored YouTube metadata from our systems, excluding your access and refresh tokens unless you choose to revoke our access. Video IDs of processed videos are retained to enable you to keep track of videos you have worked on.</li>
            <li><strong>Consent and Control:</strong> By using Bumpups, you grant us permission to access and collect the necessary video metadata. Your consent is obtained explicitly during the authentication process. You may withdraw your consent and request the deletion of your data at any time.</li>
            <li>To delete any data stored by Bumpups, please contact our support team.</li>
          </ul>
        </p>
        <p>
          <strong>Revoke and Delete:</strong> You have the ability to revoke Bumpups's access to your YouTube data directly from the Bumpups dashboard at any time. This action will prompt us to immediately revoke our authorization, delete your access and refresh tokens, and clear all related YouTube metadata from our records. Additionally, you can revoke our access through the Google security settings page at <a href="https://security.google.com/settings/" target="_blank" rel="noopener noreferrer" class="policyLinks">https://security.google.com/settings/</a>. We will retain only the video IDs for which timestamps were previously generated, allowing you to identify previously processed videos if you choose to use our services again in the future.
        </p>
        <p>
          By using our service, you explicitly consent to these actions during the application's authentication process. You also have the right to withdraw your consent and request data deletion at any time. For more information, please refer to <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer" class="policyLinks">YouTube's Terms of Service</a> and <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" class="policyLinks">Google's Privacy Policy</a>.
        </p>
        <p>
          We advise you to review YouTube's Terms of Service and Google's Privacy Policy alongside our practices to fully understand your rights and our obligations.
        </p>
        <p>
          For any queries or concerns regarding our use of YouTube API Services data, please reach out to our support team.
        </p>
        <h3>Data Sharing and User Consent</h3>

        <b>Data We Share:</b>
        <p>At Bumpups, we value your privacy and only share the captions extracted from your videos temporarily with our third-party AI provider for processing. We do not store or save the caption data. The sharing of this data is essential for enhancing our video processing capabilities, specifically to identify key points within your content.
        </p>

        <b>Purpose of Data Sharing:</b>
        <p>The captions are shared solely to facilitate the creation of timestamps in your videos, making your content more accessible and easier to navigate. By analyzing these captions, we can pinpoint significant moments in the video, thereby enhancing the viewing experience for your audience. Only the timestamps derived from this analysis are stored, not the captions themselves.
        </p>

        <b>Third-Party AI Provider:</b>
        <p>We partner with OpenAI to process video captions. OpenAI uses advanced AI models to analyze the text and generate meaningful timestamps based on the content's key points.</p>
        <b>Consent for Data Sharing:</b>
        <p>By signing up and continuing to use Bumpups, you agree to our Terms of Service and consent to the sharing of your video captions with OpenAI as detailed in our Privacy Policy. This consent is voluntary. You can opt out at any time by contacting us at <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a>, which will stop further data sharing.</p>


        <h3>Personal Data We Collect</h3>
        <p>At Bumpups, we collect various categories of Personal Data to enhance your experience and deliver our Services effectively. This includes Profile or Contact Data such as your name, email, and phone number, which we may share with Service Providers, Advertising Partners, and Analytics Partners. We also collect Payment Data like your financial account information and the last four digits of your payment card, primarily shared with our payment processing partner. Commercial Data like your purchase history, Device/IP Data such as your IP address and device type, and Web Analytics like your browsing history are also collected. Additionally, we gather Social Network Data, which may include public and private data from your social media profiles, and Geolocation Data based on your IP address. </p>

        <p>Furthermore, we draw Inferences from the collected Personal Data to understand user attributes, behavior, preferences, or abilities. These inferences are shared with our Service Providers, Advertising Partners, and Analytics Partners. It's important to note that this Privacy Policy is focused solely on the practices of Bumpups and does not cover third-party companies or individuals not managed by us. Your continued use of our Services signifies your consent to this data collection and sharing as outlined.</p>
        <h2>Categories of Sources of Personal Data</h2>
        <ul>
          <li><strong>You:</strong>
            <ul>
              <li>Directly provided information during account creation or service interaction.</li>
              <li>Voluntary information through free-form text boxes, surveys, or questionnaires.</li>
              <li>Emails or other forms of contact.</li>
              <li>Automatically collected information when using the Services.</li>
              <li>Data through Cookies.</li>
              <li>Location and device data via mobile application or location-enabled browser.</li>
              <li>Information from applications and software we provide for service enhancements.</li>
            </ul>
          </li>
          <li><strong>Public Records:</strong>
            <ul>
              <li>Government or other external sources.</li>
            </ul>
          </li>
          <li><strong>Third Parties:</strong>
            <ul>
              <li>Vendors for analytics, customer support, lead generation, and user profiling.</li>
              <li>Advertising Partners for marketing and promotional services.</li>
              <li>Social Networks for integrated account information.</li>
            </ul>
          </li>
        </ul>
        <p>This comprehensive data collection is crucial for delivering the Services you expect and is governed by this Privacy Policy.</p>
        <h2>Our Commercial or Business Purposes for Collecting or Disclosing Personal Data</h2>
        <ul>
          <li><strong>Providing, Customizing and Improving the Services:</strong>
            <ul>
              <li>Account creation and management.</li>
              <li>Transaction processing and billing.</li>
              <li>Delivering requested products, services, or information.</li>
              <li>Fulfilling the purpose for which you provided the information.</li>
              <li>Support and assistance for the Services.</li>
              <li>Service improvement through testing, research, and analytics.</li>
              <li>Personalization based on your preferences.</li>
              <li>Fraud protection and security measures.</li>
              <li>Other business purposes as defined by applicable laws like the CPRA.</li>
            </ul>
          </li>
          <li><strong>Marketing the Services:</strong>
            <ul>
              <li>Marketing and sales activities.</li>
              <li>Displaying targeted advertisements.</li>
            </ul>
          </li>
          <li><strong>Corresponding with You:</strong>
            <ul>
              <li>Responding to your correspondence.</li>
              <li>Communications based on your preferences or interests.</li>
            </ul>
          </li>
          <li><strong>Meeting Legal Requirements and Enforcing Legal Terms:</strong>
            <ul>
              <li>Fulfilling legal obligations under applicable laws.</li>
              <li>Protecting rights, property, or safety.</li>
              <li>Enforcing agreements with you.</li>
              <li>Resolving disputes and handling claims.</li>
            </ul>
          </li>
        </ul>
        <p>We will not collect additional categories of Personal Data or use it for unrelated or incompatible purposes without notifying you. For California residents, we adhere to the CPRA regulations concerning the use and disclosure of sensitive personal information.</p>
        <h2>How We Disclose Your Personal Data</h2>
        <p>We disclose your Personal Data to various service providers and parties as outlined below. Depending on applicable state laws, some of these disclosures may be considered a "sale" of your Personal Data. For state-specific information, please refer to the relevant sections below.</p>
        <ul>
          <li><strong>Service Providers:</strong> Parties assisting us in providing Services or conducting business functions.
            <ul>
              <li>Hosting, technology, and communication providers.</li>
              <li>Analytics providers.</li>
              <li>Support and customer service vendors.</li>
              <li>Payment processors, including our partner Stripe, Inc. for payment card information.</li>
            </ul>
          </li>
          <li><strong>Advertising Partners:</strong> Parties aiding us in marketing and promotional activities.
            <ul>
              <li>Ad networks.</li>
              <li>Marketing providers.</li>
            </ul>
          </li>
          <li><strong>Analytics Partners:</strong> Parties offering web traffic or usage analytics.
            <ul>
              <li>Companies tracking user referrals to the Services.</li>
              <li>Companies analyzing user interactions with the Services.</li>
            </ul>
          </li>
          <li><strong>Business Partners:</strong> Parties collaborating with us in various services.
            <ul>
              <li>Businesses you have a relationship with.</li>
              <li>Companies offering marketing and creator tools integrated with our products.</li>
              <li>Joint promotional partners.</li>
            </ul>
          </li>
          <li><strong>Parties You Authorize, Access or Authenticate:</strong>
            <ul>
              <li>Third parties accessed through the Services.</li>
              <li>Social media services.</li>
            </ul>
          </li>
        </ul>
        <p>Please note that our payment processing partner, Stripe, Inc., has its own terms of service and privacy policy concerning the use and storage of your Personal Data.</p>
        <h2>Additional Disclosures</h2>
        <p><strong>Legal Obligations:</strong> We reserve the right to share any Personal Data we collect with third parties when required by law or to fulfill legal obligations. This is in line with the activities set forth under "Meeting Legal Requirements and Enforcing Legal Terms" in the "Our Commercial or Business Purposes for Collecting Personal Data" section.</p>

        <p><strong>Business Transfers:</strong> In the event of a merger, acquisition, bankruptcy, or other transaction where a third party assumes control of our business, either in whole or in part, all Personal Data we collect may be transferred to that third party. We will make reasonable efforts to notify you in advance, so you are aware that your information will be subject to a different set of privacy and security policies.</p>

        <p><strong>Data that is Not Personal Data:</strong> We may create aggregated, de-identified, or anonymized data from the Personal Data we collect. This is done by removing information that makes the data personally identifiable. We may use and share this aggregated, de-identified, or anonymized data with third parties for lawful business purposes, such as to analyze, build, and improve our Services and promote our business. Rest assured, this data will not be shared in a manner that could identify you personally.</p>

        <h2>Tracking Tools, Advertising and Opt-Out</h2>
        <p>The Services employ various tracking technologies including cookies, pixel tags, web beacons, and similar tools ("Cookies") to enhance user experience, analyze trends, and improve our Services. Cookies are small text files placed on your device when you access our Services. We may also integrate data from third parties that use their own Cookies on your device.</p>

        <ul>
          <li><strong>Essential Cookies:</strong> These Cookies are crucial for accessing features or services you've requested. For instance, they allow you to log into secure areas. Disabling these may render some services unavailable.</li>
          <li><strong>Functional Cookies:</strong> These Cookies remember your settings and preferences, enhancing your experience with our Services. They may remember your language or region preferences.</li>
          <li><strong>Performance/Analytical Cookies:</strong> These Cookies help us understand user behavior by collecting data on how visitors use our Services. They help us improve both our Services and advertising campaigns. For example, Google Analytics uses Cookies to gather data, and you can opt-out via Google's settings.</li>
          <li><strong>Retargeting/Advertising Cookies:</strong> These Cookies collect data on your online activity to provide targeted advertising that is relevant to you.</li>
        </ul>

        <p>You have the option to manage Cookies through your browser settings, where you can disable Cookies or manage them individually. Note that doing so may affect your experience and some functionalities may not work. To manage your Cookie preferences, you can access your settings by clicking.</p>

        <p>For more general information on Cookies, including how to manage and delete them, you can visit <a href="http://www.allaboutcookies.org/" class="policyLinks">All About Cookies</a> or, if you're in the European Union, <a href="https://ico.org.uk/for-the-public/online/cookies/" class="policyLinks">ICO's Cookie Information</a>.</p>

        <h2>Information about Interest-Based Advertisements</h2>
          <p>We may display advertisements and collaborate with third-party ad networks, including ad servers, agencies, technology vendors, and research firms. These advertisements may be tailored to users based on certain profile categories or preferences, known as "Interest-Based Ads."</p>

          <ul>
            <li><strong>Tracking Methods:</strong> Information for Interest-Based Ads may be collected through Cookies and web beacons. These tools track user activities over time, even when you leave our Services.</li>
            <li><strong>Third-Party Involvement:</strong> We may use third-party services to deliver Cookies or web beacons to provide anonymized, aggregated auditing, research, and reporting for us and advertisers.</li>
            <li><strong>Compliance:</strong> We adhere to the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral Advertising.</li>
            <li><strong>Consumer Control:</strong> Through the DAA and Network Advertising Initiative (“NAI”), you have the option to understand and control how ads are customized based on your online behavior. To opt-out, visit the DAA’s or NAI’s consumer opt-out pages.</li>
          </ul>

          <p>If you wish to opt-out of receiving behaviorally targeted ads, you can visit <a href="http://www.networkadvertising.org/choices/" class="policyLinks">NAI’s opt-out page</a> or <a href="http://www.aboutads.info/choices/" class="policyLinks">DAA’s opt-out page</a>. For users in the European Union, you can visit <a href="http://www.youronlinechoices.eu/" class="policyLinks">European Interactive Digital Advertising Alliance’s user information website</a> to make choices about Interest-Based Ads.</p>

          <h2>Data Security</h2>
          <p>We take the security of your Personal Data seriously and employ a variety of physical, technical, organizational, and administrative measures to protect it. However, it's important to note that no method of online data transmission or storage is entirely secure. We encourage you to contribute to these security measures by selecting a strong password, limiting access to your computer or device, and signing off after accessing your account. While we strive to protect your data, we cannot guarantee absolute security, and it's crucial to be aware of these limitations.</p>

          <h2>Data Retention</h2>
          <p>We hold onto your Personal Data for durations that are essential for offering you our Services or for carrying out our business objectives. The retention period for specific types of data depends on various factors including its source, necessity, purpose, and sensitivity. In certain situations, we may keep your data for extended periods to meet legal requirements, resolve disputes, or collect outstanding fees. Anonymized or aggregated data may be retained indefinitely as it does not personally identify you.</p>
          <ul>
            <li>Profile Information: Kept as long as your account is active.</li>
            <li>Payment Data: Retained for the duration needed to complete your transaction.</li>
            <li>Device/IP Data: Stored as long as required for system functionality.</li>
          </ul>

          <h2>Personal Data of Children</h2>
          <p>We strictly adhere to the policy of not knowingly collecting or soliciting Personal Data from children under the age of 16. If we discover that we have inadvertently collected such data, we will promptly delete it. If you suspect that a child under 16 has provided us with Personal Data, please reach out to us at contact@bumpups.com.</p>


          <h2>California Resident Rights</h2>
          <p>If you reside in California, you have specific rights regarding your Personal Data as outlined in this section. For details on how to exercise these rights, please refer to the "Exercising Your Rights" section. Note that we may handle the Personal Data of our customers' end users or employees as part of our services. If you fall into this category, please contact the entity that originally collected your data for further action. In case of any discrepancies between this section and other parts of this Privacy Policy, the terms that offer greater protection to Personal Data will prevail. For any questions concerning this section, you can reach us at contact@bumpups.com.</p>

          <h2>Access</h2>
          <p>You possess the right to inquire about the details of your Personal Data that we have collected over the last year. Upon your request, we will furnish you with the following information:</p>
          <ul>
            <li>The categories of Personal Data that we have collected about you.</li>
            <li>The categories of sources from which that Personal Data was collected.</li>
            <li>The business or commercial purpose for collecting or selling your Personal Data.</li>
            <li>The categories of third parties with whom we have shared your Personal Data.</li>
            <li>The specific pieces of Personal Data that we have collected about you.</li>
          </ul>
          <p>If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we will identify the categories of Personal Data shared with each category of third party recipient. If we have sold your Personal Data over the past 12 months, we will identify the categories of Personal Data sold to each category of third party recipient.</p>

          <h2>Deletion</h2>
          <p>You also have the right to request the deletion of your Personal Data that we hold. However, this right is not absolute under the California Consumer Privacy Act (CPRA). There are specific exceptions where we may need to retain your Personal Data, such as for ongoing service provision, transaction completion, or if the deletion process is unduly burdensome. In such cases, we may deny your request for deletion.</p>

          <h2>Correction</h2>
          <p>You have the right to request that we amend any inaccurate Personal Data we have collected about you. However, this right is not absolute under the California Consumer Privacy Act (CPRA). There are specific exceptions where we may decide that the data is accurate based on the overall circumstances surrounding your Personal Data. In such cases, we may deny your request for correction.</p>

          <h2>Personal Data Sales Opt-Out and Opt-In</h2>
          <p>We do not sell your Personal Data and have not done so in the past 12 months. Additionally, we do not sell the Personal Data of minors under the age of 16.</p>

          <h2>We Will Not Discriminate Against You for Exercising Your Rights Under the CPRA</h2>
          <p>We will not discriminate against you for exercising your rights under the CPRA. This means we will not deny you our goods or services, charge you different prices or rates, or provide you with a lower quality of goods or services if you exercise your rights. However, we may offer different tiers of our Services as permitted by applicable data privacy laws, including the CPRA. These tiers may have varying prices, rates, or levels of quality based on the value of the Personal Data we receive from you.</p>

          <h2>Virginia Resident Rights</h2>
          <p>If you are a resident of Virginia, you have specific rights under the Virginia Consumer Data Protection Act (VCDPA). For instructions on how to exercise these rights, please refer to the “Exercising Your Rights” section below.</p>

          <p>Please be aware that we may process Personal Data of our customers' end users or employees as part of our service offerings. If you find that we are processing your Personal Data in this capacity, you should first contact the entity that originally collected your data to address your rights.</p>

          <p>It's important to note that these rights are subject to conditions and exceptions under applicable law. These conditions may either permit or require us to deny your request. If there is a conflict between this section and any other part of this Privacy Policy, and you are a Virginia resident, the terms that are more protective of Personal Data will take precedence.</p>

          <p>For any questions regarding this section or to find out if these rights apply to you, please contact us at <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a>.</p>

          <h2>Virginia Resident Rights</h2>

          <h3>Access</h3>
          <ul>
            <li>You have the right to request confirmation of whether or not we are processing your Personal Data.</li>
            <li>You have the right to access your Personal Data.</li>
          </ul>

          <h3>Correction</h3>
          <p>You have the right to correct inaccuracies in your Personal Data, to the extent that such correction is appropriate considering the nature of the data and our purposes for processing your Personal Data.</p>

          <h3>Portability</h3>
          <p>You have the right to request a copy of your Personal Data in a machine-readable format, to the extent technically feasible.</p>

          <h3>Deletion</h3>
          <p>You have the right to delete your Personal Data.</p>

          <h3>Opt-Out of Certain Processing Activities</h3>
          <ul>
            <li>You have the right to opt-out of the processing of your Personal Data for targeted advertising purposes. Note: We do not process your Personal Data for targeted advertising purposes.</li>
            <li>You have the right to opt-out of the sale of your Personal Data. Note: We do not currently sell your Personal Data as defined under the VCDPA.</li>
            <li>You have the right to opt-out from the processing of your Personal Data for the purposes of profiling in furtherance of decisions that produce legal or similarly significant effects to you, if applicable.</li>
          </ul>
          <h2>Appealing a Denial</h2>

          <p>If we refuse to take action on a request within a reasonable period of time after receiving your request in accordance with this section, you have the right to appeal. In such appeal, you must:</p>

          <ol>
            <li>Provide sufficient information to allow us to verify that you are the person about whom the original request pertains and to identify the original request.</li>
            <li>Provide a description of the basis of your appeal.</li>
          </ol>

          <p>Please note that your appeal will be subject to your rights and obligations afforded to you under the VCDPA. We will respond to your appeal within 60 days of receiving your request. If we deny your appeal, you have the right to contact the Virginia Attorney General using the methods described at <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint" class="policyLinks">Virginia Attorney General's website</a>.</p>

          <h3>Methods for Appealing</h3>
          <ul>
          <li>Email us at: <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a> (title must include “VCDPA Appeal”)</li>
          </ul>
          <h2>Exercising Your Rights under CPRA and VCDPA</h2>
            <p>
              To exercise any of the rights described in this Privacy Policy, you or, 
              if you are a California resident, your Authorized Agent, must send us a request that meets the following criteria:
            </p>
            <ul>
              <li>
                Provides sufficient information to allow us to verify that you are the person about whom we have collected Personal Data.
              </li>
              <li>
                Describes your request in sufficient detail to allow us to understand, evaluate, and respond to it.
              </li>
            </ul>
            <p>
              Each request that meets both of these criteria will be considered a "Valid Request." 
              We may not respond to requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify your identity and complete your request. 
              You do not need an account to submit a Valid Request.
            </p>
            <p>
              We will work to respond to your Valid Request within the time period required by applicable law. 
              We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive, or manifestly unfounded. 
              If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
            </p>
            <p>
              You may submit a Valid Request using the following methods:
            </p>
            <ul>
            <li>Email us at: <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a></li>
            </ul>
            <h3>Designating an Authorized Representative</h3>
            <p>
              For residents of California, you have the option to appoint an "Authorized Representative" to exercise your rights under this Privacy Policy on your behalf. To do so, you must provide your Authorized Representative with a written authorization to act on your behalf. We may request to see this written authorization when your Authorized Representative submits a request on your behalf.
            </p>
            <h3>State-Specific Privacy Rights</h3>

            <h4>California Resident Rights</h4>
            <p>
              In accordance with California Civil Code Sections 1798.83-1798.84, residents of California have the right to request that we do not disclose their Personal Data to third parties for those third parties' direct marketing purposes. To make such a request, please contact us at contact@bumpups.com.
            </p>
            <p>
            While some web browsers offer a "Do Not Track" option, our Services currently do not support this feature. For more information about "Do Not Track," please visit <a href="http://www.allaboutdnt.com" class="policyLinks">www.allaboutdnt.com</a>.
            </p>

            <h4>Nevada Resident Rights</h4>
            <p>
              If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. To exercise this right, please contact us at contact@bumpups.com with the subject line "Nevada Do Not Sell Request," and provide your name and the email address associated with your account.
            </p>
            <h3>Supplemental Terms for International Users</h3>

            <h4>EEA, Switzerland, and UK</h4>
            <p>
              If you are accessing the Services from the European Region (EEA, Switzerland, or the UK), the following additional terms apply:
            </p>

            <h5>Legal Bases for Processing</h5>
            <p>
              We process your personal data in accordance with applicable laws. The legal bases for our processing of your personal data as outlined in this Privacy Policy are as follows:
            </p>

            <h3>Legal Bases for Processing Your Personal Data</h3>

            <p>At Bumpups, we process your personal data for various purposes and rely on different legal bases to do so. Below are the details:</p>

            <ul>
              <li>
                <strong>Processing Purpose:</strong> To administer, operate, and provide Bumpups to you, including account creation, transaction processing, and troubleshooting.
                <br />
                <strong>Legal Basis:</strong> Processing is necessary to perform the contract governing our provision of Bumpups or to take steps that you request prior to signing up for an account.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To understand how you use Bumpups, monitor its performance, and develop new features.
                <br />
                <strong>Legal Basis:</strong> The processing is necessary for our legitimate interests, namely, to identify and resolve issues and improve Bumpups.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To provide personalized advertising.
                <br />
                <strong>Legal Basis:</strong> We ask for your consent to serve you with personalized ads based on your activity on and off Bumpups.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To comply with legal obligations, including assisting in law enforcement and investigations.
                <br />
                <strong>Legal Basis:</strong> This processing is necessary to comply with our legal obligations.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To communicate with you, including customer support and service-related messages.
                <br />
                <strong>Legal Basis:</strong> The processing is necessary for the performance of a contract with you.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To send you marketing communications.
                <br />
                <strong>Legal Basis:</strong> The processing is necessary for our legitimate interests, namely to promote new developments and features of Bumpups. We will obtain your consent where required by law.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To enforce our terms, conditions, and policies.
                <br />
                <strong>Legal Basis:</strong> The processing is necessary for the performance of a contract with you.
              </li>

              <li>
                <strong>Processing Purpose:</strong> To detect abuse, fraud, and illegal activity.
                <br />
                <strong>Legal Basis:</strong> The processing is necessary for our legitimate interests, namely, to ensure that Bumpups is safe and secure.
              </li>
            </ul>
            <h3>International Data Transfers</h3>
            <p>
            If you are based in the European Region, your personal data may be transferred to and stored at destinations outside the European Region, such as to our service providers and partners. These transfers are conducted with appropriate safeguards, like standard data protection clauses adopted by the European Commission. For further inquiries about these safeguards, please contact us at <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a>.
            </p>

            <h3>Your Rights</h3>
            <p>You have the following rights:</p>
            <ul>
              <li>The right to request, free of charge, confirmation of whether we process your personal data and access to a copy of the retained data.</li>
              <li>The right to request proper rectification or removal of your personal data or restriction of its processing.</li>
              <li>The right to data portability, if processing is based on your consent or necessary for contract performance and is automated.</li>
              <li>The right to withdraw consent at any time, without affecting the lawfulness of prior processing activities.</li>
              <li>The right not to be subject to automatic decisions, including profiling, that have legal or significant effects on you, unless authorized or necessary for contract performance.</li>
              <li>The right to object to processing based on our legitimate interest, unless we demonstrate compelling grounds that override your rights.</li>
              <li>The right to object to processing your data for direct marketing purposes.</li>
              <li>The right to lodge complaints with the competent data protection regulator.</li>
            </ul>
            <p>
            To exercise any of these rights, you may need to verify your identity or account details. Please send an email to <a href="mailto:contact@bumpups.com" class="policyLinks">contact@bumpups.com</a> to exercise your rights.
            </p>

            <h3>Russia</h3>

            <h4>Legal Grounds for Data Processing</h4>
            <p>
              We process your personal data based on various legal grounds such as your consent, performance of a contract, our legitimate interest, and legal obligations. By signing up and using Bumpups, you consent to the processing of personal data in accordance with this Privacy Policy.
            </p>

            <h4>Where We Store Your Personal Data</h4>
            <p>
              Your personal data may be transferred from Russia to the U.S.A. and stored in that location, outside of the country where you reside.
            </p>

            <h3>Brazil</h3>

            <h4>Exercise of Data Protection Rights</h4>
            <p>
              Brazilian law provides certain rights to individuals regarding their personal data. We ensure transparency and access controls to allow users to exercise these rights under the Brazilian General Data Protection Law - LGPD.
            </p>
            <ul>
              <li>Confirmation of whether your data are being processed</li>
              <li>Access to your data</li>
              <li>Correction of incomplete, inaccurate, or outdated data</li>
              <li>Anonymization, blocking, or erasure of data</li>
              <li>Portability of personal data to a third party</li>
              <li>Object to the processing of personal data</li>
              <li>Information of public and private entities with which we shared data</li>
              <li>Information about the possibility to refuse providing personal data and the respective consequences, when applicable</li>
              <li>Withdrawal of your consent</li>
            </ul>

            <h4>Verifying Your Identity</h4>
            <p>
              For your safety, we may request specific information and/or documents to verify your identity before responding to a request concerning your data.
            </p>

            <h4>Limitations to Your Rights</h4>
            <p>
              In certain situations, we may have legitimate reasons not to comply with some of your requests. We will inform you if we cannot fulfill your request and explain the reasons.
            </p>

            <h4>International Transfer of Data</h4>
            <p>
              We may share your personal data globally and ensure compliance with applicable data protection laws for international transfers.
            </p>

            <h4>Parental and Guardian Consent</h4>
            <p>
              If required by Brazilian data protection laws, specific age-related consent procedures apply.
            </p>

            <h4>Contact the Data Protection Officer</h4>
            <p>
              To contact the Data Protection Officer, please email us at contact@bumpups.com.
            </p>
            <h3>South Korea</h3>

            <h4>Data Retention</h4>
            <p>
              In accordance with South Korean law, we retain your personal data only for as long as necessary to fulfill the purposes for which it was collected. However, certain types of data are retained for statutorily-prescribed periods:
            </p>
            <ul>
              <li>Act on Consumer Protection in Electronic Commerce: Records related to order cancellations, payments, and supply of goods/services are retained for 5 years.</li>
              <li>Records on the handling of consumer complaints or disputes are retained for 3 years.</li>
              <li>Records on advertisements and labels are retained for 6 months.</li>
              <li>Protection of Communications Secrets Act: Records on your visits to our website are retained for 3 months.</li>
            </ul>

            <h4>Destruction of Personal Data</h4>
            <p>
              Once the retention period expires or the purpose of data collection is achieved, we securely destroy your personal data in a manner that makes it unrestorable, ensuring your privacy is maintained.
            </p>

            <h4>Your Rights</h4>
            <p>
              You have the right to access, rectify, or delete your personal data. You also have the right to request the suspension of your data processing. To exercise these rights, please contact us at contact@bumpups.com.
            </p>

            <h4>Data Security</h4>
            <p>
              We employ a variety of technical, managerial, and physical safeguards to protect your personal data from unauthorized access, alteration, disclosure, or destruction. These measures are continually updated to ensure the highest level of security.
            </p>

            <h4>Information Relating to Children</h4>
            <p>
            Bumpups is not intended for children under the age of 14. We do not knowingly collect or solicit personal data from children under this age.
            </p>

            <h4>Entrustment and/or Overseas Transfer of Personal Data</h4>
            <p>
              Your personal data may be entrusted to our affiliates, cloud storage providers, IT service providers, and data centers, some of which may be located abroad. These entities are committed to complying with both domestic and international data protection regulations. If you wish to opt-out of such transfers, you may do so by contacting us at contact@bumpups.com.
            </p>
            <h3>Indonesia</h3>

            <h4>Age, Parental and Guardian Consent</h4>
            <p>
              By using Bumpups, you affirm that you are at least 21 years old, married, or not under guardianship. If you don't meet these criteria:
            </p>
            <ul>
              <li>You must secure approval from your parent(s) or legal guardian(s).</li>
              <li>Your parent(s) or legal guardian(s) are responsible for your actions and compliance with our policy.</li>
            </ul>
            <p>
              If you cannot obtain this consent, you must cease using Bumpups immediately.
            </p>

            <h4>Your Choices</h4>
            <p>
              You have the right to withdraw consent for the disclosure of your personal data to third parties. Doing so will not affect your ability to enjoy the core features of Bumpups.
            </p>
            <ul>
              <li>You can request a history of your collected personal data.</li>
              <li>You can request the erasure of your personal data, which will not impact your user experience negatively.</li>
            </ul>
            <p>
              To exercise these rights, please contact us at contact@bumpups.com.
            </p>

            <h4>Notification</h4>
            <p>
              In the unlikely event of a data breach, we prioritize your security and will promptly notify you, providing all necessary information and steps for remediation.
            </p>

            <h4>Data Retention</h4>
            <p>
              We retain your data only as long as necessary to provide you with our exceptional service. Beyond that, we keep your data for a maximum of five years for legal compliance and to defend against any legal claims.
            </p>
            <p>
              After the retention period, your data is stored in an anonymized format for analytics, ensuring your privacy is maintained.
            </p>
            <h3>United Arab Emirates (UAE)</h3>

            <h4>How We Share Your Personal Data</h4>
            <p>
              If you are using Bumpups in the UAE, you consent to the terms of this Privacy Policy, including our practices for sharing your personal data. We adhere to the highest standards of data privacy and ensure that your data is handled with utmost care, in line with the main text of this Privacy Policy.
            </p>

            <h4>Governing Law and Dispute Resolution</h4>
            <p>
              By using Bumpups in the UAE, you agree that this Privacy Policy is governed by and interpreted in accordance with UAE laws. In the unlikely event of a dispute, the following procedures shall apply:
            </p>
            <ul>
              <li>
                For claims within the limits specified by the DIFC Small Claims Tribunal, the dispute will be referred to this Tribunal.
              </li>
              <li>
                For all other disputes, mediation will be sought in accordance with the Mediation Rules of the DIFC-LCIA Arbitration Centre. If mediation is unsuccessful within 30 days or an agreed-upon extended period, the dispute will be resolved by arbitration under the Arbitration Rules of the DIFC-LCIA Arbitration Centre.
              </li>
            </ul>
            <p>
              All mediation and arbitration proceedings will be conducted in English and take place in Dubai.
            </p>
            <h3>Vietnam</h3>

            <h4>Age, Parental and Guardian Consent</h4>
            <p>
              If you are using Bumpups in Vietnam, you must be at least 16 years old or not under guardianship to access and use our services. If you are below 16 or under guardianship:
            </p>
            <ul>
              <li>
                You must obtain consent from your parent(s) or legal guardian(s).
              </li>
              <li>
                Your parent(s) or legal guardian(s) will be responsible for your actions, compliance with our policy, and ensuring that your use of Bumpups does not violate any child protection laws.
              </li>
            </ul>
            <p>
              If you cannot obtain such consent, you must stop using Bumpups immediately.
            </p>

            <h3>South Africa</h3>

            <h4>Age Requirements</h4>
            <p>
              If you are using Bumpups in South Africa, you affirm that you are over the age of 18. If you are under 18, you should review this Privacy Policy with a parent or legal guardian.
            </p>
            <p>
              Parents or legal guardians of users under 18 but above 13 are required to read and acknowledge this Privacy Policy and Terms of Service, thereby consenting to their child's use of Bumpups.
            </p>
            <h3>Philippines</h3>

            <h4>Your Rights</h4>
            <p>
              If you are using Bumpups in the Philippines, you have specific rights under the laws, rules, and regulations set forth by the Philippines National Privacy Commission (NPC). These rights include:
            </p>
            <p>In the Philippines, Bumpups users have a range of rights as outlined by the Philippines National Privacy Commission (NPC). These include the right to information, the right to object, the right to access, the right to rectification, the right to erasure or blocking, the right to lodge complaints before the NPC, the right to damages, and the right to data portability. Before we can fulfill any of these rights, you may be required to verify your identity or account details. </p>
            <p>
              Before we can fulfill any of these rights, you may be required to verify your identity or account details.
            </p>

            <h3>Egypt</h3>

            <h4>Age Requirements</h4>
            <p>
              If you are using Bumpups in Egypt, you affirm that you are over the age of 18. If you are under 18, you should review this Privacy Policy with a parent or legal guardian.
            </p>
            <p>
              Parents or legal guardians of users under 18 but above 13 are required to read and acknowledge this Privacy Policy and Terms of Service, thereby consenting to their child's use of Bumpups.
            </p>
            <h3>Mexico</h3>

            <h4>How We Use Your Personal Data</h4>
            <p>
              If you are using Bumpups in Mexico, please note that most of the data processing activities mentioned in our Privacy Policy are necessary for the provision of our services. However, we also engage in secondary purposes like:
            </p>
            <ul>
              <li>Providing you with personalized advertising</li>
            </ul>
            <p>
              These secondary purposes enhance your experience on Bumpups. You have the option to limit these activities by adjusting your settings.
            </p>
            <p>
              We employ both human and automated means to process your data for a more tailored and efficient service.
            </p>

            <h4>How We Share Your Personal Data</h4>
            <p>
              We may share your personal data with third parties for purposes other than processing on our behalf. We will obtain your explicit consent for such disclosures, as required by applicable laws.
            </p>

            <h4>Your Rights</h4>
            <p>
              You have various rights concerning your personal data, including:
            </p>
            <ul>
              <li>Access</li>
              <li>Rectification</li>
              <li>Cancellation</li>
              <li>Opposition</li>
              <li>Revocation of consent</li>
              <li>Limitation of use and disclosure</li>
            </ul>
            <p>
              To exercise any of these rights, you can send your request to our contact email. If you are under 18, these rights must be exercised through a parent or guardian. We will respond to your requests as promptly as possible.
            </p>
            <h3>Contact Information</h3>
            <p>
              If you have any questions or comments about this Privacy Policy, or if you would like to exercise your rights regarding the collection and use of your Personal Data, please feel free to reach out to us at:
            </p>
            <ul>
            <li><a className="policyLinks" href="https://www.bumpups.com" target="_blank" rel="noopener noreferrer">www.bumpups.com</a></li>
            <li>Email: <a className="policyLinks" href="mailto:contact@bumpups.com">contact@bumpups.com</a></li>
              <li>5473 Blair Rd Ste 100 #112725, Dallas, TX 75231-4227</li>
            </ul>



        </div>

     
     
     
     
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;