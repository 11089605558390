// NonCreatorDashboard.js

import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './nonCreatorDashboard.css';

// FIREBASE
import { doc, onSnapshot, collection, getDoc, updateDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../../firebase';

// FUNCTIONS
import LinksNotification from '../../components/appComponents/linksNotification'; // Adjust the path as needed
import AppTransition from '../../components/appComponents/AppTransition';
import axios from 'axios';
import { debounce } from 'lodash';
import { getYoutubeURLUnAuthCaptions } from '../myBumpUp/myCreator/getVideoCaptions';

// IMPORT UI
import NonAuthThumbnailLayout from './nonAuthThumbnailLayout';
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';
import UserStudio from '../UserStudio';

// IMAGES
import processBumpupslogo from '../../assets/gifmedia/bumpups-2.gif';
import info_icon from '../../assets/images/icons/info-icon.png';
import preview_thumbnail from '../../assets/images/thumbnail/preview-thumbnail.png';
import link_icon from '../../assets/images/icons/link-icon.png';
import baseplan_white_up_arrow_icon from '../../assets/images/icons/baseplan-white-up-arrow-icon.png';

function NonCreatorDashboard({ isSidebarOpen, toggleSidebar }) {
  const [youtubeVideoDetails, setYoutubeVideoDetails] = useState({});
  const [youtubeInputUrl, setYoutubeInputUrl] = useState(''); // Initialize as an empty string

  // Step 1: Function to extract the YouTube video ID from the URL
  const extractYoutubeVideoID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|youtube\.com\/live\/)([^#&?]*).*/;
    const match = url.match(regExp);

    // The video ID should be 11 characters long and consist of valid characters.
    return match && /^[a-zA-Z0-9_-]{11}$/.test(match[2]) ? match[2] : null;
  };

  const convertDurationToSeconds = (duration) => {
    if (!duration) return 0; // Return 0 if duration is null or undefined

    let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    if (!match) return 0; // Return 0 if the duration doesn't match the pattern

    let hours = match[1] ? parseInt(match[1].slice(0, -1), 10) : 0;
    let minutes = match[2] ? parseInt(match[2].slice(0, -1), 10) : 0;
    let seconds = match[3] ? parseInt(match[3].slice(0, -1), 10) : 0;
    return hours * 3600 + minutes * 60 + seconds;
  };

  const [isSearching, setIsSearching] = useState(false);
  const [istoLong, setistoLong] = useState(false);
  const [noValidVideo, setnoValidVideo] = useState(false);
  const [captionsAvailable, setcaptionsAvailable] = useState(false); // Managing state for captions availability

  // Function to fetch YouTube video details and conditionally check captions
  const fetchYoutubeVideoDetails = async (url) => {
    const videoId = extractYoutubeVideoID(url);
    if (!videoId) {
      return;
    }
    setIsSearching(true);
    const apiYoutubeKey = process.env.REACT_APP_YOUTUBE_API_KEY;

    try {
      const videoResponse = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
        params: {
          part: 'snippet,contentDetails,status',
          id: videoId,
          key: apiYoutubeKey,
        },
      });

      if (videoResponse.data.items.length > 0) {
        const { snippet, contentDetails, status } = videoResponse.data.items[0];
        const durationSeconds = convertDurationToSeconds(contentDetails.duration);
        setistoLong(durationSeconds > 12600); // 3.5 hours in seconds

        const thumbnailUrl =
          snippet.thumbnails.maxres?.url ||
          snippet.thumbnails.standard?.url ||
          snippet.thumbnails.high?.url;
        if (!thumbnailUrl) {
          setIsSearching(false);
          return;
        }

        const language = snippet.defaultLanguage || 'Language unspecified';
        const visibility = status.privacyStatus; // Get visibility status ('public', 'private', 'unlisted')

        if (visibility === 'public') {
          // Video is public, check for captions
          const captionsResponse = await axios.get(`https://www.googleapis.com/youtube/v3/captions`, {
            params: {
              videoId: videoId,
              key: apiYoutubeKey,
            },
          });

          const captionsFound = captionsResponse.data.items.length > 0;
          setcaptionsAvailable(captionsFound); // Update state based on actual captions availability
        } else {
          // For private or unlisted videos, default captionsAvailable to true
          setcaptionsAvailable(true);
        }

        // Set video details, including captions information
        const youtubeDetails = {
          thumbnailUrl: thumbnailUrl,
          duration: contentDetails.duration,
          language: language,
          captionAvailable: captionsAvailable, // Updated state used here
          visibility: visibility,
          videoId: videoId,
          title: snippet.title,
        };
        setYoutubeVideoDetails(youtubeDetails);

        setIsSearching(false);
      } else {
        setnoValidVideo(true);
        setIsSearching(false);
      }
    } catch (error) {
      setIsSearching(false);
    }
  };

  const [isOverTimebank, setOverTimebank] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const timebankRef = doc(userDocRef, 'subscriptionData', 'timeWarden');

      const fetchTimebank = async () => {
        try {
          const docSnap = await getDoc(timebankRef);
          if (docSnap.exists()) {
            const { timeBank, timeBankBoost, timeBankBump } = docSnap.data();

            // Convert all timebank values to seconds
            const timebankInSeconds = (timeBank || 0) * 60;
            const timeBankBoostInSeconds = (timeBankBoost || 0) * 60;
            const timeBankBumpInSeconds = (timeBankBump || 0) * 60;

            // Total timebank in seconds
            const totalTimebankInSeconds =
              timebankInSeconds + timeBankBoostInSeconds + timeBankBumpInSeconds;

            if (youtubeVideoDetails) {
              const videoDurationInSeconds = convertDurationToSeconds(youtubeVideoDetails.duration);

              if (totalTimebankInSeconds >= videoDurationInSeconds) {
                setOverTimebank(false);
              } else {
                setOverTimebank(true);
              }
            }
          }
        } catch (error) {
          // Handle error
        }
      };

      fetchTimebank();
    }
  }, [youtubeVideoDetails]);

  function formatDuration(duration) {
    // Match against the ISO 8601 duration format
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

    if (!match) return ''; // Return empty string if no match

    let hours = match[1] ? parseInt(match[1].slice(0, -1), 10) : 0;
    let minutes = match[2] ? parseInt(match[2].slice(0, -1), 10) : 0;

    // Build formatted duration string
    let formatted = '';
    if (hours > 0) formatted += `${hours}h `;
    if (minutes > 0 || (minutes === 0 && hours === 0)) formatted += `${minutes}m`;

    return formatted.trim(); // Trim any extra space at the end
  }

  function getLanguageName(languageCode) {
    // Check if languageCode is valid and not the placeholder text
    if (!languageCode || languageCode === 'Language unspecified') {
      return null;
    }

    try {
      const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
      return languageNames.of(languageCode);
    } catch (error) {
      return null;
    }
  }

  const [isShortTimestamp, setIsShortTimestamp] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const timestampSettingsRef = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');
      getDoc(timestampSettingsRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            // Update the checkbox state to match the Firestore setting
            setIsShortTimestamp(docSnap.data().isShortTimestamps || false);
          }
        })
        .catch((error) => {
          // Handle error
        });
    }
  }, []);

  const debouncedUpdateTimestampLength = debounce(async (isShort) => {
    const user = auth.currentUser;
    if (user) {
      const timestampSettingsRef = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');

      try {
        await updateDoc(timestampSettingsRef, {
          isShortTimestamps: isShort,
        });
      } catch (error) {
        // Handle error
      }
    }
    setIsShortTimestamp(isShort);
  }, 500);

  const handleTimestampLengthChange = (event) => {
    debouncedUpdateTimestampLength(event.target.checked);
  };

  const [showVideoNotFound, setShowVideoNotFound] = useState(false);

  // New input change handler
  const handleUrlChange = (url) => {
    const videoId = extractYoutubeVideoID(url);
    setYoutubeInputUrl(url);
    setnoValidVideo(false);
    setistoLong(false);
    setOverTimebank(false);
    if (videoId) {
      // Reset any previous "Video Not Found" message
      setShowVideoNotFound(false);
      // Fetch video details
      fetchYoutubeVideoDetails(url);
    } else {
      // Clear previous video details and show "Video Not Found" message
      setYoutubeVideoDetails({});
      setShowVideoNotFound(true);
    }
  };

  const [doneVideos, setDoneVideos] = useState([]);
  const [processingStatus, setProcessingStatus] = useState({}); // Tracks processing status for each videoId
  const [videoProcessingError, setVideoProcessingError] = useState({}); // Tracks error messages for each videoId

  const progressIntervalRef = useRef(null);




// LINE 278 is NEW CODE TO MITIGATE NULL CONTENT ID

  const handleYoutubeURLProcessing = async () => {
    // if (processingStatus[youtubeVideoDetails.videoId]) return; // Prevent multiple clicks

    setIsSearching(true);
  
    const videoId = youtubeVideoDetails.videoId;
    const durationInSeconds = convertDurationToSeconds(youtubeVideoDetails.duration);
    const fullUrl = youtubeInputUrl;
  
    const visibility = youtubeVideoDetails.visibility;
    const isPublic = visibility === 'public';
  
    const contentId = `youtubeUrl-${videoId}`; // New contentId
  
    setProcessingStatus((prevStatus) => ({ ...prevStatus, [videoId]: true }));
  
    try {
      const { message } = await getYoutubeURLUnAuthCaptions({
        videoId,
        durationInSeconds,
        thumbnailUrl: youtubeVideoDetails.thumbnailUrl,
        title: youtubeVideoDetails.title,
        isReprocess: false,
        fullUrl,
        isPublic,
        contentId, // Pass contentId
      });
  
      if (message === 'success') {

      } else {
        setProcessingStatus((prevStatus) => ({ ...prevStatus, [videoId]: false }));
        setIsSearching(false);
        setVideoProcessingError((prevStatus) => ({
          ...prevStatus,
          [videoId]: message,
        }));
  
        setTimeout(() => {
          setVideoProcessingError((prevStatus) => ({
            ...prevStatus,
            [videoId]: null,
          }));
        }, 20000);
      }
    } catch (error) {
      setProcessingStatus((prevStatus) => ({ ...prevStatus, [videoId]: false }));
      setIsSearching(false);
      setVideoProcessingError((prevStatus) => ({
        ...prevStatus,
        [videoId]: 'An error occurred during processing.',
      }));
  
      setTimeout(() => {
        setVideoProcessingError((prevStatus) => ({
          ...prevStatus,
          [videoId]: null,
        }));
      }, 20000);
    }
  
    setTimeout(() => {
      setVideoProcessingError((prevStatus) => ({
        ...prevStatus,
        [videoId]: 'Processing is taking longer than usual... ⏳',
      }));
  
      setTimeout(() => {
        setVideoProcessingError((prevStatus) => ({
          ...prevStatus,
          [videoId]: null,
        }));
      }, 20000);
  
      setProcessingStatus((prevStatus) => ({ ...prevStatus, [videoId]: false }));
      setIsSearching(false);
    }, 600000);
  };
  






  useEffect(() => {
    const user = auth.currentUser;
    if (user && youtubeVideoDetails.videoId) {
      const userDocRef = doc(db, 'users', user.uid);
      const unAuthCollectionRef = collection(userDocRef, 'youtubeUrlContent');
  
      const unsubscribe = onSnapshot(unAuthCollectionRef, async (querySnapshot) => {
        const processedVideoIds = querySnapshot.docs.map((doc) =>
          doc.id.startsWith('youtubeUrl-') ? doc.id.substring('youtubeUrl-'.length) : doc.id
        );
  
        if (processedVideoIds.includes(youtubeVideoDetails.videoId)) {
          const truncatedTitle =
            youtubeVideoDetails.title.length > 52
              ? youtubeVideoDetails.title.substring(0, 52) + '...'
              : youtubeVideoDetails.title;
          const newVideoData = {
            durationInSeconds: convertDurationToSeconds(youtubeVideoDetails.duration),
            thumbnailUrl: youtubeVideoDetails.thumbnailUrl,
            title: truncatedTitle,
            videoId: youtubeVideoDetails.videoId,
          };
  
          try {
            const docSnap = await getDoc(unAuthCollectionRef);
            if (!docSnap.exists()) {
              await setDoc(unAuthCollectionRef, { videos: [newVideoData] });
            } else {
              await updateDoc(unAuthCollectionRef, {
                videos: arrayUnion(newVideoData),
              });
            }
  
            const localStorageKey = `youtubeUrlCache_${auth.currentUser.uid}`;
            localStorage.removeItem(localStorageKey);
  
            setProcessingStatus((prevStatus) => ({
              ...prevStatus,
              [youtubeVideoDetails.videoId]: false,
            }));
            setIsSearching(false);
            clearInterval(progressIntervalRef.current); // Stop the progress increment
          } catch (error) {
          }
        }
      });
  
      return () => unsubscribe();
    }
  }, [
    youtubeVideoDetails.duration,
    youtubeVideoDetails.thumbnailUrl,
    youtubeVideoDetails.title,
    youtubeVideoDetails.videoId,
  ]);
  

  useEffect(() => {
    const fetchProcessedVideos = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const unauthDataCollectionRef = collection(userDocRef, 'youtubeUrlContent');

        const unsubscribe = onSnapshot(unauthDataCollectionRef, (querySnapshot) => {
          const processedVideoIds = querySnapshot.docs.map((doc) =>
            doc.id.startsWith('youtubeUrl-') ? doc.id.substring('youtubeUrl-'.length) : doc.id
          );

          setDoneVideos(processedVideoIds);

          processedVideoIds.forEach((videoId) => {
            setProcessingStatus((prevStatus) => {
              if (prevStatus[videoId]) {
                return { ...prevStatus, [videoId]: false };
              }
              return prevStatus;
            });
          });
        });

        return unsubscribe;
      }
    };

    fetchProcessedVideos();
  }, []);
  

// New state variables for countdown and finalizing
const [countdown, setCountdown] = useState(10);
const [finalizingUpload, setFinalizingUpload] = useState(false);

// Updated useEffect for countdown (removed finalizingUpload from dependencies)
useEffect(() => {
  const currentVideoId = youtubeVideoDetails.videoId;

  if (currentVideoId && processingStatus[currentVideoId]) {
    // Initialize countdown and reset finalizing state
    setCountdown(10);
    setFinalizingUpload(false);

    // Start the countdown interval
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount === 1) {
          clearInterval(countdownInterval);
          setFinalizingUpload(true);  // Set to finalizing state
          return prevCount; // Keep it at 1 to avoid showing "0 seconds left"
        }
        return prevCount - 1;
      });
    }, 1000);

    // Cleanup intervals on unmount or when videoId changes
    return () => {
      clearInterval(countdownInterval);
    };
  }
}, [processingStatus, youtubeVideoDetails.videoId]);

// Optional: Reset countdown and finalizing when video ID changes
useEffect(() => {
  setCountdown(10);
  setFinalizingUpload(false);
}, [youtubeVideoDetails.videoId]);


  const handleUploadAnotherVideo = () => {
    setYoutubeInputUrl(''); // Clear the URL input field
    setYoutubeVideoDetails({}); // Reset video details
    setDoneVideos([]); // Clear the list of done videos
    setProcessingStatus({}); // Reset processing statuses
    setIsSearching(false); // Reset searching state
    setnoValidVideo(false); // Reset video validity state
    setistoLong(false); // Reset duration check
    setOverTimebank(false); // Reset timebank check
    setcaptionsAvailable(false); // Reset captions availability
  };



  return (
    <div className="uploaddashboard">
      {isSidebarOpen && <Sidebar />}
      {!isSidebarOpen && <CloseSidebar />}
      <AppTransition>
      <UserStudio/>

        <div className="dashboard-container">
          <LinksNotification />

          <section className="urlinput-section">
            <div className="urlinput-container">
              <div className="input-icon-wrapper">
                <img src={link_icon} alt="Link Icon" className="url-input-icon" />
                <input
                  type="text"
                  id="urlInput"
                  className="url-input-box"
                  placeholder="Drop a Youtube link..."
                  value={youtubeInputUrl}
                  onChange={(e) => handleUrlChange(e.target.value)}
                />
              </div>

              <div>
                {/* "Process Video" Button - Conditionally Rendered Based on Captions */}
                {!processingStatus[youtubeVideoDetails.videoId] &&
                  !doneVideos.includes(youtubeVideoDetails.videoId) &&
                  !isOverTimebank &&
                  !istoLong &&
                  captionsAvailable && // Ensure captions are available
                  youtubeInputUrl && // Ensure input URL is present
                  extractYoutubeVideoID(youtubeInputUrl) && ( // Ensure valid YouTube video ID is present
                    <button
                      className={`url-input-button 
                                            ${isSearching ? 'searching' : ''} 
                                            ${
                                              !youtubeInputUrl ||
                                              !extractYoutubeVideoID(youtubeInputUrl) ||
                                              isSearching ||
                                              noValidVideo
                                                ? 'disabled'
                                                : ''
                                            }`}
                      onClick={handleYoutubeURLProcessing}
                      disabled={
                        !youtubeInputUrl ||
                        !extractYoutubeVideoID(youtubeInputUrl) ||
                        isSearching ||
                        noValidVideo
                      }
                    >
                      <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="icon" />
                      {noValidVideo ? 'Not Valid URL' : isSearching ? 'Searching...' : 'Upload Video'}
                    </button>
                  )}

                {/* "No Captions" Button - Shown When Captions Are Unavailable */}
                {!processingStatus[youtubeVideoDetails.videoId] &&
                  !doneVideos.includes(youtubeVideoDetails.videoId) &&
                  !isOverTimebank &&
                  !istoLong &&
                  !captionsAvailable && // Show No Captions button only when captions are unavailable
                  youtubeVideoDetails.videoId && (
                    <button className="url-input-button processing" disabled>
                      <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="icon" />
                      No Captions
                    </button>
                  )}

                {/* "Stay on page..." Button - Shown When Processing */}
                {processingStatus[youtubeVideoDetails.videoId] && (
                  <button className="url-input-button processing" disabled>
                    <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="icon" />
                    {!finalizingUpload ? `${countdown} seconds left...` : 'Finalizing Upload...'}
                  </button>
                )}

                {/* "Get More Time" Link - Shown When Over Timebank */}
                {isOverTimebank &&
                  !doneVideos.includes(youtubeVideoDetails.videoId) &&
                  !processingStatus[youtubeVideoDetails.videoId] && (
                    <Link to="/plans" className="url-input-button timebank-over">
                      <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="icon" />
                      Get More Time
                    </Link>
                  )}

                {/* "Max Duration" Warning Button - Shown When Video Is Too Long */}
                {istoLong &&
                  !doneVideos.includes(youtubeVideoDetails.videoId) &&
                  !isOverTimebank &&
                  !processingStatus[youtubeVideoDetails.videoId] && (
                    <button className="url-input-button max-duration-warning" disabled>
                      <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="icon" />
                      Max Duration 3h 30m
                    </button>
                  )}

                {/* "View Bump" Link - Shown When Processing Is Done */}
                {doneVideos.includes(youtubeVideoDetails.videoId) && (
                  <button
                    className="url-input-button videoDataReady"
                    onClick={handleUploadAnotherVideo}
                  >
                    Upload Another Video
                  </button>
                )}
              </div>
            </div>
          </section>

          <section className="youtube-video-details">
            {youtubeInputUrl && youtubeVideoDetails.thumbnailUrl && (
              <div className="video-card">
                <div className="video-metadata">
                  {youtubeVideoDetails.language && youtubeVideoDetails.language !== 'Language unspecified' ? (
                    <div className="video-caption">
                      Caption: <b>{getLanguageName(youtubeVideoDetails.language)}</b>
                    </div>
                  ) : (
                    <div className="video-caption" aria-hidden="true">
                      &#160;
                    </div> // Use aria-hidden for accessibility
                  )}
                  <div className="video-credit-usage">
                    <span className="badge-base">Base</span> Credit Cost: <b>{formatDuration(youtubeVideoDetails.duration)}</b>
                  </div>
                </div>
                <div className="video-overlay">
                  {/* Placeholder for potential overlay content */}
                </div>

                <div className="video-thumbnail-container" style={{ position: 'relative' }}>
                  <img
                    src={youtubeVideoDetails.thumbnailUrl || preview_thumbnail}
                    alt="Video Thumbnail"
                    className="video-thumbnail"
                  />

                  {processingStatus[youtubeVideoDetails.videoId] && (
                    <>
                      <div className="video-thumbnail-overlay"></div>
                      <img
                        src={processBumpupslogo}
                        alt="Processing"
                        className="processing-gif"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 2,
                        }}
                      />
                    </>
                  )}

                  {videoProcessingError[youtubeVideoDetails.videoId] &&
                    !doneVideos.includes(youtubeVideoDetails.videoId) && (
                      <div className="error-video-thumbnail-overlay">
                        <div className="unauth-error-message-box">
                          {videoProcessingError[youtubeVideoDetails.videoId] === 'captions_not_available' ? (
                            <div className="unauth-error-message">
                              Captions not available for this video.
                            </div>
                          ) : videoProcessingError[youtubeVideoDetails.videoId] ===
                            'captions_not_available_in_language' ? (
                            <div className="unauth-error-message">
                              Captions not available in your preferred language.
                            </div>
                          ) : (
                            <div className="unauth-error-message">
                              Captions not available for this video.
                            </div>
                          )}

                          <div className="unauth-retry-message">
                            Learn more{' '}
                            <a
                              href="https://intercom.help/bumpups/en/articles/9126173-why-won-t-my-videos-process"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here
                            </a>
                            .
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          </section>

          {
            // Only display the toggle-switch-container if youtubeInputUrl is a valid YouTube URL
            youtubeInputUrl &&
              extractYoutubeVideoID(youtubeInputUrl) && (
                <div className="toggle-switch-container">
                  <span className="toggle-switch-text">
                    <b>Make timestamp length short?</b>
                  </span>
                  <a
                    href="https://intercom.help/bumpups/en/articles/9108586-what-timestamp-length-is-right-for-your-content"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img src={info_icon} alt="Info" className="info-icon-toggle" />
                  </a>
                  <label className="toggle-switch-label">
                    <input
                      type="checkbox"
                      checked={isShortTimestamp}
                      onChange={handleTimestampLengthChange}
                    />
                    <span className="toggle-slider"></span>
                  </label>
                </div>
              )
          }
          {showVideoNotFound && youtubeInputUrl && ( // Add `youtubeInputUrl` to make sure there's some input
            <div className="unauth-video-not-found">
              <div>
                <div className="unauth-main-text">Video Not Found 🔎</div>
                <div className="unauth-small-text">
                  check your video id:
                  <span className="unauth-example-url">
                    https://www.youtube.com/watch?v=
                    <span className="highlight-video-id">Vevu1P5XGNk</span>
                  </span>
                  <br />
                  supports videos from 5 to 240 minutes ⏲️
                </div>
              </div>
            </div>
          )}

          {!youtubeInputUrl && (
            <section className="video-type-section">
                <div className="video-type-info">
                  <p>
                    This feature is optimized for videos with <b>SPOKEN</b> content and currently supports YouTube links.
                  </p>
                </div>
                <div className="video-types">
                  <div className="supported-types">
                    <p>Timestamps</p>
                    <p>Titles</p>
                    <p>Description</p>
                    <p>Tags</p>
                    <p>Key Takeaways</p>
                  </div>
                  <div className="unsupported-types">
                    <p><b>No Chat Feature</b></p>
                  </div>
                </div>
                <div className="example-text-seotool">
                  Learn more and see an example <a href="https://intercom.help/bumpups/en/articles/10787198-how-to-optimize-your-video-with-bump-ai-s-seo-tool" target="_blank" rel="noopener noreferrer" className="settings-link">here</a>.
                </div>
              </section>
          )}


          <NonAuthThumbnailLayout />
        </div>
      </AppTransition>
    </div>
  );
}

export default NonCreatorDashboard;