import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import '../../unAuthPages/SignUpPage.css';

//FIREBASE
import { auth, db } from '../../firebase';
import { doc, onSnapshot } from "firebase/firestore"; 

//FUNCTIONS
import { registerListener } from './ListenerManager';

//IMPORT UI
import ProcessingPage from '../../unAuthPages/ProcessingPage';
import MaintenancePage from '../miscComponents/MaintenancePage';



const PrivateRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);
  let navigate = useNavigate();
  let location = useLocation(); // Use location to determine the current route

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        navigate("/signup");
      }
      setIsLoading(false);
    });

    registerListener(unsubscribeAuth);

    const maintenanceStatusRef = doc(db, 'maintenanceStatus', 'status');
    const unsubscribeMaintenance = onSnapshot(maintenanceStatusRef, (statusSnapshot) => {
      if (statusSnapshot.exists()) {
        setIsMaintenanceActive(statusSnapshot.data().isActive);
      } else {
        setIsMaintenanceActive(false);
      }
    });

    registerListener(unsubscribeMaintenance);

    return () => {
      unsubscribeAuth();
      unsubscribeMaintenance();
    };
  }, [navigate]);

  // Determine if the current route requires showing the ProcessingPage
  const showProcessingPageForRoute = (pathname) => {
    // Define routes that do not need the ProcessingPage
    const routesWithoutProcessing = ['/dashboard', '/creator', '/settings', '/plans'];
    return !routesWithoutProcessing.includes(pathname);
  };

  if (isLoading && showProcessingPageForRoute(location.pathname)) {
    return <ProcessingPage />;
  }

  if (isMaintenanceActive) {
    return <MaintenancePage />;
  }

  return isAuthenticated ? children : null;
};

export default PrivateRoute;
