import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './userStudio.css';

const UserStudio = () => {
  const location = useLocation();
  
  return (
    <div className="studio-meta-container">
      {/* Top Navigation Bar */}
      <div className="studio-top-nav-bar">
        <div className="studio-top-nav-title">Creator</div>
        <Link
          to="/bumper"
          className={`studio-nav-item ${location.pathname.includes('/bumper') ? 'active' : ''}`}
        >
          YouTube Upload
        </Link>
        <Link
          to="/links"
          className={`studio-nav-item ${location.pathname.includes('/links') ? 'active' : ''}`}
        >
          SEO Tool
        </Link>
      </div>
    </div>
  );
};

export default UserStudio;
