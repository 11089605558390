import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../Footer';
import HeroComponent from '../productComponent/HeroComponent';
import WorkflowComponent from '../productComponent/WorkflowComponent';

import youtube_header from '../../assets/images/unauth/youtube-feature-header.jpg';
import youtube_placeholder from '../../assets/images/unauth/youtube-feature-placeholder.jpg';

import youtube_timestamps from '../../assets/images/unauth/youtube-feature-v1.jpg';
import workspace_prompt from '../../assets/images/unauth/workspace-benefite-v1.jpg';

import SocialProfileCarousel from '../unauthComponent/socialprofileCarousel';

import './productPages.css';

const YouTubeFeature = () => {
  const heroData = {
    title: 'Any YouTube Video',
    subtitle:
      'Bumpups streamlines your workflow by processing public videos, livestreams, and unlisted videos.',
    buttonText: 'Start for free today',
    arrowIcon: '→',
    hoverImage: youtube_header,
  };

  const workflowHeaderData = {
    title: 'Your YouTube Workflow',
    description:
      'Handle every type of YouTube video with ease. Whether it’s a public upload, a live stream, unlisted, or private content from your channel.',
  };

  const workflowRow1Data = {
    video:
      'https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2Fyoutube-feature-video.mp4?alt=media&token=a4b2c165-b1c7-486e-80e1-f3152958348e',
    placeholder: youtube_placeholder,
    accordionItems: [
        {
          title: 'Upload & Process Videos',
          content:
            'Process all types of YouTube content with Bumpups – whether it’s public videos, livestreams, unlisted, or private videos.',
        },
        {
          title: 'Integrated YouTube Insights',
          content:
            'Extract essential details like timestamps and summaries. Our system even lets you prompt the video in workspaces, ensuring you get all the relevant insights you need.',
        },
        {
            title: 'Video Specifications',
            content: 'Supported video durations range from 4 minutes to 3.5 hours, with resolutions from 480p to 4K.'
        },
      ],
      
  };

  const workflowRow2Data = {
    text: {
      title: 'Easy YouTube Information',
      description:
        'Easily extract key details from your YouTube videos – including timestamps, titles, descriptions, tags, and key takeaways – all within one streamlined process.',
      ctaText: 'Try it out',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: youtube_timestamps,
  };
  

  const workflowRow3Data = {
    text: {
      title: 'Ask Bump AI Anything',
      description:
        'Bump AI can generate tables, bullet points, checklists, email drafts, summaries, scripts, lecture notes, quiz questions, SWOT analyses, and more about your YouTube video.',
      ctaText: 'Try it out',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: workspace_prompt,
  };
  

  return (
    <div className="product-main-container">
        <Helmet>
        <title>AI Analyze Your YouTube Video | Bumpups.com</title>
        <meta
          name="description"
          content="Streamline your YouTube workflow with Bumpups. Process public videos, livestreams, and unlisted content while extracting timestamps, summaries, and actionable insights with ease."
        />
      </Helmet>
      <HeroComponent {...heroData} />
      <SocialProfileCarousel />
      <WorkflowComponent
        headerData={workflowHeaderData}
        row1Data={workflowRow1Data}
        row2Data={workflowRow2Data}
        row3Data={workflowRow3Data}
      />
      <Footer />
    </div>
  );
};

export default YouTubeFeature;
