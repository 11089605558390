import React, { useState, useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { registerListener } from './ListenerManager';
import bumpups_green_darkBackground_logo from '../../assets/images/logo/bumpups-green-darkBackground-logo.jpg';

const LinksNotification = () => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationLink, setNotificationLink] = useState(null);

  useEffect(() => {
    const docRef = doc(db, "notifications", "linksNotification");

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setNotificationMessage(docSnap.data().message);
        setNotificationLink(docSnap.data().link);
      }
    });

    registerListener(unsubscribe);

    return () => {
      unsubscribe();
    };
  }, []);

  if (!notificationMessage) return null;



return (
  <div className="notification-box">
    <img src={bumpups_green_darkBackground_logo} alt="Logo" className="notification-logo" />
    <span>
      {notificationMessage}
      {notificationLink && (
        <a href={notificationLink} target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
      )}
    </span>
  </div>
);




};

export default LinksNotification;
