// src/components/TemplateWorkspace/NewWorkspace.js

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import './templateWorkspace.css'; // Shared CSS
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';
import AppTransition from '../../components/appComponents/AppTransition';

import WorkspaceHeader from './workspaceUploads/WorkspaceHeader';
import UploadWorkspace from './workspaceUploads/uploadWorkspace';
import PromptsWorkspace from './promptsWorkspace';
import ShortcutsWorkspace from './shortcutsWorkspace';

import { createWorkspace } from './createWorkspace';

import workspace_draft_icon from '../../assets/images/icons/workspace-draft-icon.png';

const NewWorkspace = ({ isSidebarOpen, toggleSidebar }) => {
  const user = auth.currentUser;
  const navigate = useNavigate(); // Initialize navigate for redirection

  // Initialize state variables for creating a new workspace
  const [workspaceTitle, setWorkspaceTitle] = useState(''); // Default title
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false); // State for button enable/disable

  // Enable "Save" button if workspaceTitle is not empty
  useEffect(() => {
    const hasChanges = workspaceTitle.trim() !== '';
    setIsSaveButtonEnabled(hasChanges);
  }, [workspaceTitle]);

  // Helper function to generate localStorage key
  const getLocalDraftCountKey = (userId) => `draftCount_${userId}`;

  // Redirect if draft count exceeds the limit
  useEffect(() => {
    if (user) {
      const localDraftCountKey = getLocalDraftCountKey(user.uid);
      const draftCountStr = localStorage.getItem(localDraftCountKey);
      const draftCount = draftCountStr ? parseInt(draftCountStr, 10) : 0;

      if (draftCount >= 10) {
        // Optionally, you can display a toast or notification here
        navigate('/workspace');
      }
    } else {
      // Optionally, redirect to login if user is not authenticated
      navigate('/login'); // Adjust the path as necessary
    }
  }, [user, navigate]);

  // Handler to create a new workspace
  const handleCreateWorkspace = useCallback(async () => {
    if (!user) {
      navigate('/login'); // Adjust the path as necessary
      return;
    }

    const localDraftCountKey = getLocalDraftCountKey(user.uid);
    const draftCountStr = localStorage.getItem(localDraftCountKey);
    const draftCount = draftCountStr ? parseInt(draftCountStr, 10) : 0;

    if (draftCount >= 10) {
      navigate('/workspace');
      return;
    }

    if (workspaceTitle.trim() === '') {
      return;
    }

    try {
      const content_id_with_prefix = await createWorkspace({
        user: user,
        workspaceTitle,
        // Removed other properties as they're no longer needed
      });
      // console.log('Workspace created successfully.');

      // Extract content_id and remove 'workspace-' prefix
      const content_id = content_id_with_prefix.startsWith('workspace-')
        ? content_id_with_prefix.replace(/^workspace-/, '')
        : content_id_with_prefix;

      // Redirect to the newly created workspace page
      navigate(`/workspace/${content_id}`);
    } catch (error) {
      // console.error('Error creating workspace:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    } finally {
      // Any cleanup if necessary
    }
  }, [user, workspaceTitle, navigate]);

  return (
    <div className="template-workspace-container">
      {isSidebarOpen ? <Sidebar /> : <CloseSidebar toggleSidebar={toggleSidebar} />}

      <AppTransition>
        <div className="template-workspace-main-new-one">
          <WorkspaceHeader
            workspaceTitle={workspaceTitle}
            setWorkspaceTitle={setWorkspaceTitle}
            workspaceCreatedFunction={handleCreateWorkspace}
            isSaveButtonEnabled={isSaveButtonEnabled} // Pass state to enable/disable the save button
          />

          {/* New Wrapper for Row and Prompts Containers */}
          <div className="workspace-content-wrapper">
            <div className="template-row-container">
              <div className="template-upload-container">
                <div className="newworkspace-upload-workspace-wrapper">
                  <UploadWorkspace />
                </div>
                <div className="modify-shortcuts-container">
                  <ShortcutsWorkspace/>
                  {/* Removed props related to ShortcutsWorkspace */}
                </div>
              </div>
            </div>

            <div className="template-prompts-container">
              <PromptsWorkspace />
              {/* Removed props related to PromptsWorkspace */}
            </div>

            {/* Overlay covering both template-row-container and template-prompts-container */}
            <div className="newworkspace-overlay global-overlay">
              <div className="newworkspace-overlay-message">
                <div className="newworkspace-header-container">
                  <img src={workspace_draft_icon} alt="Draft Icon" className="newworkspace-draft-icon" />
                  <div className="newworkspace-overlay-header">Workspace Not Saved</div>
                </div>
                <p className="newworkspace-overlay-subtitle">
                  Save your workspace to start chatting with videos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </AppTransition>
    </div>
  );
};

export default NewWorkspace;
