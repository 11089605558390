import React from 'react';
import { Routes, Route, NavLink, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './UserSettings.css';

// Custom transition component
import AppTransition from '../components/appComponents/AppTransition';

// Sidebar UI
import Sidebar from '../myDashboard/Sidebar';
import CloseSidebar from '../myDashboard/CloseSidebar';

// ACCOUNT SETTINGS
import GeneralSettings from './settingComponent/accountSetting/GeneralSettings';
import IntegrationSetting from './settingComponent/accountSetting/IntegrationSetting';
import PreferenceSetting from './settingComponent/accountSetting/PeferenceSetting';
import WarningSetting from './settingComponent/accountSetting/WarningSetting';

// CREATOR SETTINGS
import DescriptionTemplate from './settingComponent/creatorSetting/DescriptionTemplate';
import CommentTemplate from './settingComponent/creatorSetting/CommentTemplate';

const UserSettings = ({ isSidebarOpen }) => {
  const location = useLocation();

  return (
    <div className="set-meta-settings">
      {isSidebarOpen ? <Sidebar /> : <CloseSidebar />}

      {/* Outer AppTransition for overall page transition */}
      <AppTransition>
        <Helmet>
          <title>Bumpups</title>
          <meta name="description" content="Customize your Bumpups settings." />
        </Helmet>

        {/* Top Navigation Bar */}
        <div className="set-top-nav-bar">
          <div className="set-top-nav-title">Settings</div>
          <NavLink
            to="/settings"
            end
            className={({ isActive }) =>
              isActive ? 'set-nav-item active' : 'set-nav-item'
            }
          >
            Account
          </NavLink>

          <NavLink
            to="/settings/creator"
            className={({ isActive }) =>
              isActive ? 'set-nav-item active' : 'set-nav-item'
            }
          >
            Creator
          </NavLink>

          {/* API (coming soon) - disabled */}
          <span className="set-nav-item disabled">API (coming soon)</span>
        </div>

        {/* Main content area with nested AppTransition for sub-route transitions */}
        <div className="set-settings-content">
          <AppTransition key={location.key}>
            <Routes location={location}>
              <Route path="/" element={<AccountSettings />} />
              <Route path="creator" element={<CreatorSettings />} />
              <Route path="*" element={<Navigate to="/settings" replace />} />
            </Routes>
          </AppTransition>
        </div>
      </AppTransition>
    </div>
  );
};

// Separate component for Account Settings
const AccountSettings = () => {
  return (
    <div className="set-account-settings-wrapper">
      <GeneralSettings />
      <IntegrationSetting />
      <PreferenceSetting />
      <WarningSetting />
    </div>
  );
};

const CreatorSettings = () => {
  return (
    <div className="set-account-settings-wrapper">
      <DescriptionTemplate />
      <CommentTemplate />
    </div>
  );
};

export default UserSettings;
