import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../Footer';
import HeroComponent from '../productComponent/HeroComponent';
import WorkflowComponent from '../productComponent/WorkflowComponent';

import local_header from '../../assets/images/unauth/local-feature-header.jpg';
import local_placeholder from '../../assets/images/unauth/local-feature-placeholder.jpg';

import youtube_timestamps from '../../assets/images/unauth/youtube-feature-v1.jpg';
import workspace_prompt from '../../assets/images/unauth/workspace-benefite-v1.jpg';

import './productPages.css';

const LocalFeature = () => {
  const heroData = {
    title: 'Any Local Video',
    subtitle:
      'Bumpups streamlines your workflow by processing local video files, supporting a wide range of formats and quality levels.',
    buttonText: 'Start for free today',
    arrowIcon: '→',
    hoverImage: local_header,
  };

  const workflowHeaderData = {
    title: 'Your Local Video Workflow',
    description:
      'Handle every type of local video file with ease. Upload files effortlessly and enjoy seamless processing along with detailed insights.',
  };

  const workflowRow1Data = {
    video:
      'https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2Flocal-feature-video.mp4?alt=media&token=a5930dfd-3b71-4812-b3b0-6865577e430b',
    placeholder: local_placeholder,
    accordionItems: [
      {
        title: 'Upload & Process Videos',
        content:
          'Upload any local video file effortlessly with Bumpups. Our system supports a wide range of formats and quality levels.',
      },
      {
        title: 'Integrated Video Insights',
        content:
          'Extract key details such as timestamps, metadata, and summaries from your local videos, ensuring you get all the insights you need.',
      },
      {
        title: 'Video Specifications',
        content:
        'Video durations range from 30 seconds to 3 hours 30 minutes, available in SD, HD, or 4K with 16:9 or 9:16 ratios. Files up to 32GB in mp4, mov, webm, or quicktime are accepted.'
      },
    ],
  };

  const workflowRow2Data = {
    text: {
      title: 'Easy Video Information',
      description:
        'Easily extract key details from your local videos – including timestamps, titles, descriptions, tags, and key takeaways – all within one streamlined process.',
      ctaText: 'Try it out',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: youtube_timestamps,
  };

  const workflowRow3Data = {
    text: {
      title: 'Ask Bump AI Anything',
      description:
        'Bump AI can generate tables, bullet points, checklists, email drafts, summaries, scripts, lecture notes, quiz questions, SWOT analyses, and more about your video content.',
      ctaText: 'Try it out',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: workspace_prompt,
  };

  return (
    <div className="product-main-container">
      <Helmet>
        <title>AI Analyze Your Local Videos | Bumpups.com</title>
        <meta
          name="description"
          content="Process and analyze local video files with Bumpups' AI. Support for multiple formats, resolutions, and file sizes – extract timestamps, metadata, summaries, and actionable insights effortlessly."
        />
      </Helmet>
      <HeroComponent {...heroData} />
      <WorkflowComponent
        headerData={workflowHeaderData}
        row1Data={workflowRow1Data}
        row2Data={workflowRow2Data}
        row3Data={workflowRow3Data}
      />
      <Footer />
    </div>
  );
};

export default LocalFeature;
