import React from 'react';
import './benefitsComponent.css';

function BenefitsComponent({ title, benefits }) {
  return (
    <section className="benefits-section">
      <div className="benefits-title-wrapper">
        <h1>{title}</h1>
      </div>
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <div className="benefit-card" key={index}>
            <div className="card-icon">
              <img src={benefit.icon} alt={`Icon ${index + 1}`} />
            </div>
            <h4>{benefit.title}</h4>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BenefitsComponent;
