import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, auth } from '../../../firebase';
import { CLIENT_ID, SCOPES } from '../../../googleOAuth';
import { debounce } from 'lodash';
import { doc, getDoc } from 'firebase/firestore';

// Import images (adjust the paths as needed)
import youtube_dark_logo from '../../../assets/images/logo/youtube-dark-logo.png';
import youtube_red_logo from '../../../assets/images/logo/youtube-red-logo.png';

// Import the CSS file for styling
import './integrationSetting.css';

const IntegrationSetting = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [revokeButtonText, setRevokeButtonText] = useState("Disconnect");

  // Connect YouTube using OAuth
  const handleConnectYouTube = () => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const connectYoutubeUrl = `${process.env.REACT_APP_FUNCTIONS_ENDPOINT}/connectYoutube`;
    if (userId) {
      const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(connectYoutubeUrl)}&scope=${encodeURIComponent(SCOPES)}&response_type=code&access_type=offline&state=${userId}`;
      window.location.href = authUrl;
    }
  };

  // Revoke YouTube connection using Firebase callable function
  const handleRevokeYouTubeAction = (channelId) => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    if (userId) {
      const functions = getFunctions();
      const revokeYoutubeCallable = httpsCallable(functions, 'revokeYoutube');

      revokeYoutubeCallable()
        .then(() => {
          sessionStorage.removeItem('accessToken');
          const YThandleKey = `${userId}_${channelId}_YThandle`;
          localStorage.removeItem(YThandleKey);
          window.location.reload();
        })
        .catch(() => {
          setRevokeButtonText("Retry");
        });
    }
  };

  // Debounce the revoke function to prevent rapid calls
  const debouncedHandleRevokeYouTube = debounce(handleRevokeYouTubeAction, 10000);

  const handleRevokeClick = () => {
    setRevokeButtonText("Loading...");
    debouncedHandleRevokeYouTube();
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      if (userId) {
        const userDoc = doc(db, "users", userId, 'contentAccess', 'youtubeAccess');
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          const { channelId, accessToken } = userData;
          if (channelId && accessToken) {
            setIsConnected(true);
          }
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Header outside the container */}
      <div className="setting-header">Integrations</div>
      
      {/* Main container for integration settings */}
      <div className="account-settings-container">
        <div className="settings-row">
          <div className="settings-label">
            <span>YouTube</span>
            <div className="settings-subtitle">
              Connect your channel to publish and auto-comment timestamps
            </div>
          </div>
          <div className="settings-field">
            <button
              className={`settings-utube-connect-button ${isConnected ? 'connected' : ''}`}
              onClick={() => (isConnected ? null : handleConnectYouTube())}
              style={{ cursor: isConnected ? 'default' : 'pointer' }}
            >
              <img
                src={isConnected ? youtube_red_logo : youtube_dark_logo}
                alt="YouTube Button"
                className="settings-create-connectyoutube"
              />
            </button>
            {isConnected && (
              <button className="ui-setting-button" onClick={handleRevokeClick}>
                {revokeButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationSetting;
