import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './productDropdown.css';

import workspace_icon from '../../../assets/images/icons/comment-icon.png';
import creator_icon from '../../../assets/images/icons/dashboard-icon.png';
import yt_icon from '../../../assets/images/logo/youtube-white-logo.png';
import local_icon from '../../../assets/images/icons/upload-small-icon.png';

const ProductDropdown = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <li className="unauthn-product-dropdown" ref={dropdownRef}>
      <button onClick={handleClick} className="unauthn-product-dropdown-toggle">
        Product{' '}
        <span className={`unauthn-dropdown-arrow ${open ? 'open' : ''}`}>
          &#x25BC;
        </span>
      </button>

      <div className={`unauthn-product-dropdown-menu ${open ? 'open' : ''}`}>
        {/* ========== LEFT COLUMN: FEATURES (Two-Column Grid) ========== */}
        <div className="unauthn-dropdown-column unauthn-dropdown-column-left">
          <h4 className="unauthn-dropdown-heading">Features</h4>
          <div className="unauthn-features-grid">
            {/* Feature 1 */}
            <Link
              to="/workspace-feature"
              className="unauthn-feature-tile"
              onClick={() => setOpen(false)}
            >
              <img
                src={workspace_icon}
                alt="Icon"
                className="unauthn-feature-icon"
              />
              <div>
                <h5 className="unauthn-feature-title">Workspaces</h5>
                <p className="unauthn-feature-description">
                  Chat with your videos.
                </p>
              </div>
            </Link>
            {/* Feature 2 */}
            <Link
              to="/creator-feature"
              className="unauthn-feature-tile"
              onClick={() => setOpen(false)}
            >
              <img
                src={creator_icon}
                alt="Icon"
                className="unauthn-feature-icon"
              />
              <div>
                <h5 className="unauthn-feature-title">Creator Studio</h5>
                <p className="unauthn-feature-description">
                  Upload videos and publish on YouTube.
                </p>
              </div>
            </Link>
            {/* Feature 3 */}
            <Link
              to="/youtube-feature"
              className="unauthn-feature-tile"
              onClick={() => setOpen(false)}
            >
              <img
                src={yt_icon}
                alt="Icon"
                className="unauthn-feature-icon"
              />
              <div>
                <h5 className="unauthn-feature-title">YouTube Videos</h5>
                <p className="unauthn-feature-description">
                  Process YouTube videos on Bumpups.
                </p>
              </div>
            </Link>
            {/* Feature 4 */}
            <Link
              to="/local-feature"
              className="unauthn-feature-tile"
              onClick={() => setOpen(false)}
            >
              <img
                src={local_icon}
                alt="Icon"
                className="unauthn-feature-icon"
              />
              <div>
                <h5 className="unauthn-feature-title">Local Videos</h5>
                <p className="unauthn-feature-description">
                  Process local videos on Bumpups.
                </p>
              </div>
            </Link>
          </div>
        </div>

        {/* ========== RIGHT COLUMN: QUICK LINKS ========== */}
        <div className="unauthn-dropdown-column unauthn-quick-links-column">
          <h4 className="unauthn-dropdown-heading">Quick Links</h4>
          <a
            href="https://intercom.help/bumpups/en/"
            target="_blank"
            rel="noopener noreferrer"
            className="unauthn-dropdown-item"
            onClick={() => setOpen(false)}
          >
            <div>Help center</div>
          </a>
          <a
            href="https://bumpups.canny.io/feature-requests"
            target="_blank"
            rel="noopener noreferrer"
            className="unauthn-dropdown-item"
            onClick={() => setOpen(false)}
          >
            <div>Feature request</div>
          </a>
          <a
            href="https://bumpups.canny.io/changelog"
            target="_blank"
            rel="noopener noreferrer"
            className="unauthn-dropdown-item"
            onClick={() => setOpen(false)}
          >
            <div>Changelog</div>
          </a>
        </div>
      </div>
    </li>
  );
};

export default ProductDropdown;
