import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../Footer';
import HeroComponent from '../productComponent/HeroComponent';
import BenefitsComponent from '../productComponent/BenefitsComponent';
import CaseStudyComponent from '../productComponent/CaseStudyComponent';
import WorkflowComponent from '../productComponent/WorkflowComponent';


import BenefitLogo1 from '../../assets/images/icons/upload-small-icon.png';
import BenefitLogo2 from '../../assets/images/icons/dashboard-icon.png';
import BenefitLogo3 from '../../assets/images/icons/comment-icon.png';

import berkeley_campus from '../../assets/images/unauth/berkeley-school-campus.jpg';
import workspace_header from '../../assets/images/unauth/workspace-feature-header.png';
import workspace_prompt from '../../assets/images/unauth/workspace-benefite-v1.jpg';
import workspace_shortcuts from '../../assets/images/unauth/workspace-benefite-v2.jpg';
import workspace_placeholder from '../../assets/images/unauth/workspace-feature-placeholder.jpg';




import './productPages.css';

const WorkspaceFeature = () => {


  const heroData = {
    title: 'Video Management with Workspaces',
    subtitle:
      'Upload, organize, and interact with your video content, whether from YouTube or local files.',
    buttonText: 'Start for free today',
    arrowIcon: '→',
    hoverImage: workspace_header,
  };
  
  const benefitsData = {
    title: "Make Video Anaylsis Simple.",
    benefits: [
      {
        icon: BenefitLogo1,
        title: 'Effortless Uploads',
        description:
          'Import videos with ease using YouTube links or local files—start analyzing within minutes.',
      },
      {
        icon: BenefitLogo2,
        title: 'Streamlined Organization',
        description:
          'Categorize and manage your video library efficiently, making search and retrieval a breeze.',
      },
      {
        icon: BenefitLogo3,
        title: 'Instant AI Insights',
        description:
          'Leverage our AI to generate detailed analytics, summaries, and actionable insights from your videos.',
      },
    ],
  };
  
  const workflowHeaderData = {
    title: 'Automate Your Video Management Workflows',
    description:
      'Import, analyze, and organize your video content—powered by our advanced AI engine.',
  };
  

  const workflowRow1Data = {
    video: 'https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2Fworkspace-feature.mp4?alt=media&token=5e5dbe5a-e3b0-4a90-8173-d6a9421333b6',
    placeholder: workspace_placeholder,
    accordionItems: [
      {
        title: 'Easy Upload',
        content: 'Import videos via YouTube links or local files for immediate processing.',
      },
      {
        title: 'Prompt Workspace',
        content:
          'Upload any video and interact directly with its content—ask questions, request summaries, analyses, and more with our intuitive Prompts Workspace.',
      },
      {
        title: 'Bump Shortcuts',
        content:
          'Bump Shortcuts are prebuilt tools that allow you to quickly generate specific outputs, such as timestamps, descriptions, titles, and tags, without needing to ask Bump AI for each output.',
      },
    ],
  };
  
  
  
  const workflowRow2Data = {
    text: {
      title: 'Experience the Power of Prompts Workspace',
      description:
        'Upload any video and interact directly with its content—ask questions, request summaries, analyses, and more with our intuitive Prompts Workspace.',
      ctaText: 'Explore Prompts Workspace',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: workspace_prompt,
  };
  
  
  const workflowRow3Data = {
    text: {
      title: 'Boost with Bump Shortcuts',
      description:
        'Access prebuilt tools that quickly generate outputs—timestamps, descriptions, titles, and tags—with minimal input.',
      ctaText: 'Explore Bump Shortcuts',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: workspace_shortcuts,
  };

  
  const caseStudyData = {
    leftImage: berkeley_campus,
    title: 'Workspaces For Education',
    subtitle:
      'Students and educators harness AI-powered video management to streamline lectures, ask questions, access info, and create practice tests and quizzes.',
    buttonText: 'Start for free today',
    stats: [
      { metrics: 'Up to 3.5 hours', description: 'Optimized for extended-length videos' },
      { metrics: 'AI-powered', description: 'Enhanced insights generation' },
      { metrics: '10K+', description: 'Videos processed' },
    ],
  };
  
  


  return (
    <div className="product-main-container">
      <Helmet>
        <title>AI Video Management with Workspaces | Bumpups.com</title>
        <meta
          name="description"
          content="Effortlessly manage and analyze your video content with Bumpups Workspaces. Upload, organize, and interact with your videos using our AI-powered platform for accessible insights and streamlined workflows."
        />
      </Helmet>
      <HeroComponent {...heroData} />
      <BenefitsComponent {...benefitsData} />
      <WorkflowComponent
        headerData={workflowHeaderData}
        row1Data={workflowRow1Data}
        row2Data={workflowRow2Data}
        row3Data={workflowRow3Data}
      />
      <CaseStudyComponent caseStudyData={caseStudyData} />
      <Footer />
    </div>
  );
};

export default WorkspaceFeature;
