import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './PricingPage.css';
import { AuthProvider } from '../AuthContext';
import Footer from './Footer';
import FAQComponent from './unauthComponent/FAQComponent';
import checkmark_icon from '../assets/images/icons/checkmark-icon.png';
import baseplan_white_up_arrow_icon from '../assets/images/icons/baseplan-white-up-arrow-icon.png';
import bumpups_white_small_logo from '../assets/images/logo/bumpups-white-small-logo.png';
import PricingTable from './PricingTable';

const PricingPage = () => {
  const [selectedCreditsBump, setSelectedCreditsBump] = useState(100);
  const [selectedCreditsBase, setSelectedCreditsBase] = useState(100);
  const [billingPeriod, setBillingPeriod] = useState('Monthly'); // 'Monthly' or 'One Time'

  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleCreditsChangeBump = (e) => {
    setSelectedCreditsBump(parseInt(e.target.value, 10));
  };

  const handleCreditsChangeBase = (e) => {
    setSelectedCreditsBase(parseInt(e.target.value, 10));
  };

  const handleBillingToggle = (period) => {
    setBillingPeriod(period);
  };

  // Hard-coded pricing based on billing period
  const pricing = {
    Base: {
      Monthly: {
        100: 3.99,
        200: 7.98,
        400: 15.96,
        600: 23.94,
        800: 31.92,
        1000: 39.90,
      },
      'One Time': {
        100: 5.00,
        200: 10.00,
        400: 20.00,
        600: 30.00,
        800: 40.00,
        1000: 50.00,
      },
    },
    Bump: {
      Monthly: {
        100: 7.99,
        200: 15.99,
        400: 31.99,
        600: 47.99,
        800: 63.99,
        1000: 79.99,
      },
      'One Time': {
        100: 10.00,
        200: 20.00,
        400: 40.00,
        600: 60.00,
        800: 80.00,
        1000: 100.00,
      },
    },
  };
  

  // Function to get price based on plan, billing period, and credits
  const getPrice = (plan, credits) => {
    return pricing[plan][billingPeriod][credits].toFixed(2);
  };

  return (
    <AuthProvider>
      <Helmet>
        <title>Compare plans, features & costs | Bumpups.com</title>
        <meta name="description" content="Discover our flexible pricing options, from a free starter plan to advanced Base and Bump plans." />
      </Helmet>
      <div className="bp-pricing-page">
        <header className="bp-pricing-header">
          <h1>Plans & Pricing</h1>
          <div className="bp-billing-toggle">
            <span
              className={`bp-toggle-option ${billingPeriod === 'Monthly' ? 'bp-active' : ''}`}
              onClick={() => handleBillingToggle('Monthly')}
            >
              Monthly
            </span>
            <span
              className={`bp-toggle-option ${billingPeriod === 'One Time' ? 'bp-active' : ''}`}
              onClick={() => handleBillingToggle('One Time')}
            >
              One Time
            </span>
          </div>
        </header>

        <section className="bp-pricing-tiers">

                    {/* Free Plan Tier */}
                    <div className="bp-tier bp-free">
            <div className="bp-plan-title">
              <div className="bp-plan-title-card">Free</div>
            </div>
            <p className="bp-billing-info">Start processing videos with no commitment</p>
            <p className="bp-price">$0 USD</p>
            <button className="bp-cta-button" onClick={handleSignUp}>
              Create an account
            </button>
            <ul className="bp-features-list">
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" />{' '}
                <strong>60</strong>&nbsp;processing minutes
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" />{' '}
                <strong>Bump AI</strong>&nbsp;video optimization
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> Prompts Workspace
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> YouTube video processing
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> Video timestamps
              </li>
            </ul>
          </div>

          {/* Base Plan Tier */}
          <div className="bp-tier bp-base">
            <div className="bp-plan-title">
              <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="bp-plan-logo" />
              <div className="bp-plan-title-card">Base</div>
            </div>
            <p className="bp-billing-info">For YouTube Links</p>
            <p className="bp-price">
              ${getPrice('Base', selectedCreditsBase)} USD
              {billingPeriod === 'Monthly' && <span className="bp-discount-label"> (20% off)</span>}
            </p>
            <p className="bp-upload-text base-plan-text">
              <strong>{selectedCreditsBase} upload minutes</strong>
            </p>

            <div className="bp-select-wrapper">
              <select
                className="bp-minutes-select"
                onChange={handleCreditsChangeBase}
                value={selectedCreditsBase}
              >
                <option value={100}>100 credits</option>
                <option value={200}>200 credits</option>
                <option value={400}>400 credits</option>
                <option value={600}>600 credits</option>
                <option value={800}>800 credits</option>
                <option value={1000}>1000 credits</option>
              </select>
            </div>

            <button className="bp-cta-button" onClick={handleSignUp}>
              Start your free plan
            </button>
            <ul className="bp-features-list">
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" />{' '}
                <strong>{selectedCreditsBase}</strong>&nbsp;processing minutes per{' '}
                {billingPeriod === 'Monthly' ? 'month' : 'purchase'}
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> Custom brand template
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> Branded comments
              </li>
            </ul>
          </div>


          {/* Bump Plan Tier */}
          <div className="bp-tier bp-bump">
            <div className="bp-plan-title">
              <img src={bumpups_white_small_logo} alt="Bump Plan Logo" className="bp-plan-logo" />
              <div className="bp-plan-title-card">Bump</div>
            </div>
            <p className="bp-billing-info">For Local Videos</p>
            <p className="bp-price">
              ${getPrice('Bump', selectedCreditsBump)} USD
              {billingPeriod === 'Monthly' && <span className="bp-discount-label-bump"> (20% off)</span>}
            </p>
            <p className="bp-upload-text bump-plan-text">
              <strong>{selectedCreditsBump} upload minutes</strong>
            </p>

            <div className="bp-select-wrapper">
              <select
                className="bp-minutes-select"
                onChange={handleCreditsChangeBump}
                value={selectedCreditsBump}
              >
                <option value={100}>100 credits</option>
                <option value={200}>200 credits</option>
                <option value={400}>400 credits</option>
                <option value={600}>600 credits</option>
                <option value={800}>800 credits</option>
                <option value={1000}>1000 credits</option>
              </select>
            </div>

            <button className="bp-cta-button" onClick={handleSignUp}>
              Start your free plan
            </button>
            <ul className="bp-features-list">
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" />{' '}
                <strong>{selectedCreditsBump}</strong>&nbsp;processing minutes per{' '}
                {billingPeriod === 'Monthly' ? 'month' : 'purchase'}
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" />{' '}
                <strong>One-click</strong>&nbsp;distribution
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" />{' '}
                <strong>Local video</strong>&nbsp;processing
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> Social media posting
              </li>
              <li>
                <img src={checkmark_icon} alt="Checkmark" className="bp-checkmark-icon" /> Multiple aspect ratios
              </li>
            </ul>
          </div>


        </section>

        <div className="bp-enterprise-tier">
          <div className="bp-enterprise-content">
            <span className="bp-enterprise-badge">Enterprise</span>
            <span className="bp-enterprise-text">
              For organizations that need over 1,000 min/mo usage.
              <a href="https://bumpups.zapier.app/get-in-touch" className="bp-enterprise-contact" target="_blank" rel="noopener noreferrer">
                Contact us
              </a>
            </span>
          </div>
        </div>

        <PricingTable />

        <FAQComponent />
      </div>
      <Footer />
    </AuthProvider>
  );
};

export default PricingPage;
