import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../Footer';
import HeroComponent from '../productComponent/HeroComponent';
import WorkflowComponent from '../productComponent/WorkflowComponent';

import creator_header from '../../assets/images/unauth/creator-feature-header.jpg';
import creator_placeholder from '../../assets/images/unauth/creator-feature-placeholder.jpg';

import creator_description from '../../assets/images/unauth/creator-feature-v1.jpg';
import creator_preview from '../../assets/images/unauth/creator-feature-v2.jpg';

import SocialProfileCarousel from '../unauthComponent/socialprofileCarousel';


import './productPages.css';

const StudioFeature = () => {
  const heroData = {
    title: 'Automate Your Content Workflow',
    subtitle:
      'Manage, optimize, and elevate your video content as a Bumpups Creator.',
    buttonText: 'Start for free today',
    arrowIcon: '→',
    hoverImage: creator_header,
  };

  const workflowHeaderData = {
    title: 'Streamline Your Creative Process',
    description:
      'Transform how you manage and optimize your video content with advanced tools in Creator Studio, crafted for every Bumpups Creator.',
  };

  const workflowRow1Data = {
    video:
      'https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2Fcreator-feature-video.mp4?alt=media&token=aca30490-0b7f-4bd4-b434-2352277ae606',
    placeholder: creator_placeholder,
    accordionItems: [
      {
        title: 'Post To YouTube',
        content:
          'Upload your local video files into Creator Studio. Once uploaded, choose to save as a draft, mark as unlisted or public, or even schedule your video for later release on YouTube.',
      },
      {
        title: 'YouTube Description',
        content:
        'Bump AI automatically crafts your video description by combining a complete summary, timestamps, key takeaways, and relevant hashtags to boost your video’s reach.',
    },
      {
        title: 'Preview Generated Description',
        content:
          'Once Bump AI processes your video, the placeholders are replaced with actual content in the preview. This gives you the opportunity to review and edit your video description before finalizing it.',
      },
    ],
  };

  const workflowRow2Data = {
    text: {
      title: 'AI YouTube Description',
      description:
        'Bump AI automatically crafts your video description by combining a complete summary, timestamps, key takeaways, and relevant hashtags to boost your video’s reach.',
      ctaText: 'Try it out',
      ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: creator_description,
  };
  

  const workflowRow3Data = {
    text: {
      title: 'Preview Generated Description',
      description:
        'Once Bump AI processes your video, the placeholders are replaced with actual content in the preview. This gives you the opportunity to review and edit your video description before finalizing it.',
        ctaText: 'Try it out',
        ctaLink: '/signup',
      ctaArrow: '→',
    },
    image: creator_preview,
  };
  
  return (
    <div className="product-main-container">
        <Helmet>
        <title>AI Automate Your Content Workflow | Bumpups.com</title>
        <meta
          name="description"
          content="Elevate your video content creation with Bumpups Creator Studio. Automate uploads, generate AI-powered YouTube descriptions, and streamline your workflow to focus on creativity."
        />
      </Helmet>
      <HeroComponent {...heroData} />
      <SocialProfileCarousel />
      <WorkflowComponent
        headerData={workflowHeaderData}
        row1Data={workflowRow1Data}
        row2Data={workflowRow2Data}
        row3Data={workflowRow3Data}
      />
      <Footer />
    </div>
  );
};

export default StudioFeature;
