import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import './templateWorkspace.css'; // Shared CSS
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';
import AppTransition from '../../components/appComponents/AppTransition';

import WorkspaceHeader from './workspaceUploads/WorkspaceHeader';
import UploadWorkspace from './workspaceUploads/uploadWorkspace';
import PromptsWorkspace from './promptsWorkspace';
import ShortcutsWorkspace from './shortcutsWorkspace';
import ProcessingPage from '../../unAuthPages/ProcessingPage';

import { updateWorkspace } from './updateWorkspace';

const ExistingWorkspace = ({ isSidebarOpen, toggleSidebar }) => {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const { content_id: rawContentId } = useParams();

  const content_id = rawContentId?.startsWith('workspace-')
    ? rawContentId.replace(/^workspace-/, '')
    : rawContentId;

  // State variables for current workspace data
  const [workspaceTitle, setWorkspaceTitle] = useState('');
  const [isScTimestampsEnabled, setIsScTimestampsEnabled] = useState(false);
  const [isScDescriptionEnabled, setIsScDescriptionEnabled] = useState(false);
  const [isScTakeawaysEnabled, setIsScTakeawaysEnabled] = useState(false);
  const [isScTitlesEnabled, setIsScTitlesEnabled] = useState(false);
  const [isScTagsEnabled, setIsScTagsEnabled] = useState(false);
  const [isTranscriptAvailable, setIsTranscriptAvailable] = useState(false);
  const [isLocalTranscriptAvailable, setIsLocalTranscriptAvailable] = useState(false);
  const [isYTProcessing, setIsYTProcessing] = useState(false);
  const [isLOCALProcessing, setIsLOCALProcessing] = useState(false);
  const [isTranscriptError, setIsTranscriptError] = useState(false);
  const [isTranscriptEmpty, setIsTranscriptEmpty] = useState(false); // New state for is_transcript_empty

  // New state variables for additional data
  const [timestamps, setTimestamps] = useState('');
  const [summary, setSummary] = useState('');
  const [takeaways, setTakeaways] = useState([]);
  const [titles, setTitles] = useState([]);
  const [hashtags, setHashtags] = useState('');
  const [isScRun, setisScRun] = useState(false);

  // State variables for original workspace data (for comparison)
  const [originalWorkspaceTitle, setOriginalWorkspaceTitle] = useState('');
  const [originalIsScTimestampsEnabled, setOriginalIsScTimestampsEnabled] = useState(false);
  const [originalIsScDescriptionEnabled, setOriginalIsScDescriptionEnabled] = useState(false);
  const [originalIsScTakeawaysEnabled, setOriginalIsScTakeawaysEnabled] = useState(false);
  const [originalIsScTitlesEnabled, setOriginalIsScTitlesEnabled] = useState(false);
  const [originalIsScTagsEnabled, setOriginalIsScTagsEnabled] = useState(false);
  const [originalIsTranscriptAvailable, setOriginalIsTranscriptAvailable] = useState(false);
  const [originalIsLocalTranscriptAvailable, setOriginalIsLocalTranscriptAvailable] = useState(false);
  const [originalIsYTProcessing, setOriginalIsYTProcessing] = useState(false);
  const [originalIsLOCALProcessing, setOriginalIsLOCALProcessing] = useState(false);
  const [originalIsTranscriptError, setOriginalIsTranscriptError] = useState(false);
  const [originalIsTranscriptEmpty, setOriginalIsTranscriptEmpty] = useState(false); // New original state

  const [loading, setLoading] = useState(true);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (content_id && user) {
      const userDocRef = doc(db, 'users', user.uid);
      const workspaceDocRef = doc(userDocRef, 'workspaceContent', `workspace-${content_id}`);

      const localDataKey = `workspace-${content_id}-data`;
      const localDataJSON = localStorage.getItem(localDataKey);

      if (localDataJSON) {
        const workspaceData = JSON.parse(localDataJSON);

        // Set initial state from local storage if available
        setWorkspaceTitle(workspaceData.workspace_title || '');
        setIsScTimestampsEnabled(workspaceData.is_sc_timestamps_enabled || false);
        setIsScDescriptionEnabled(workspaceData.is_sc_description_enabled || false);
        setIsScTakeawaysEnabled(workspaceData.is_sc_takeaways_enabled || false);
        setIsScTitlesEnabled(workspaceData.is_sc_titles_enabled || false);
        setIsScTagsEnabled(workspaceData.is_sc_tags_enabled || false);
        setIsTranscriptAvailable(workspaceData.is_transcript_available || false);
        setIsLocalTranscriptAvailable(workspaceData.is_local_transcript_available || false);
        setIsYTProcessing(workspaceData.is_caption_processing || false);
        setIsLOCALProcessing(workspaceData.is_local_caption_processing || false);
        setIsTranscriptError(workspaceData.is_transcript_error || false);
        setIsTranscriptEmpty(workspaceData.is_transcript_empty || false); // Initialize new state

        setTimestamps(workspaceData.timestamps || '');
        setSummary(workspaceData.summary || '');
        setTakeaways(workspaceData.takeaways || []);
        setTitles(workspaceData.titles || []);
        setHashtags(workspaceData.hashtags || '');
        setisScRun(workspaceData.is_sc_run || false);

        setLoading(false);
      } else {
        setLoading(true);
      }

      // Set up onSnapshot listener
      const unsubscribeMeta = onSnapshot(
        workspaceDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const workspaceData = docSnapshot.data();

            // Update state with the latest Firestore data
            setWorkspaceTitle(workspaceData.workspace_title || '');
            setIsScTimestampsEnabled(workspaceData.is_sc_timestamps_enabled || false);
            setIsScDescriptionEnabled(workspaceData.is_sc_description_enabled || false);
            setIsScTakeawaysEnabled(workspaceData.is_sc_takeaways_enabled || false);
            setIsScTitlesEnabled(workspaceData.is_sc_titles_enabled || false);
            setIsScTagsEnabled(workspaceData.is_sc_tags_enabled || false);
            setIsTranscriptAvailable(workspaceData.is_transcript_available || false);
            setIsLocalTranscriptAvailable(workspaceData.is_local_transcript_available || false);
            setIsYTProcessing(workspaceData.is_caption_processing || false);
            setIsLOCALProcessing(workspaceData.is_local_caption_processing || false);
            setIsTranscriptError(workspaceData.is_transcript_error || false);
            setIsTranscriptEmpty(workspaceData.is_transcript_empty || false); // Update new state

            setTimestamps(workspaceData.timestamps || '');
            setSummary(workspaceData.summary || '');
            setTakeaways(workspaceData.takeaways || []);
            setTitles(workspaceData.titles || []);
            setHashtags(workspaceData.hashtags || '');
            setisScRun(workspaceData.is_sc_run || false);

            // Save updated data to local storage
            localStorage.setItem(localDataKey, JSON.stringify(workspaceData));
          } else {
            // Document does not exist: clear local storage and redirect
            localStorage.removeItem(localDataKey);
            navigate('/workspace');
            return;
          }

          setLoading(false);
        },
        (error) => {
          // console.error('Error fetching workspace data:', error);
          setLoading(false);
        }
      );

      return () => unsubscribeMeta();
    } else {
      setLoading(false);
    }
  }, [content_id, user, navigate]);

  // Detect changes and enable/disable the Save button
  useEffect(() => {
    const localDataKey = `workspace-${content_id}-data`;
    const workspaceData = {
      workspace_title: workspaceTitle,
      is_sc_timestamps_enabled: isScTimestampsEnabled,
      is_sc_description_enabled: isScDescriptionEnabled,
      is_sc_takeaways_enabled: isScTakeawaysEnabled,
      is_sc_titles_enabled: isScTitlesEnabled,
      is_sc_tags_enabled: isScTagsEnabled,
      is_transcript_available: isTranscriptAvailable,
      is_local_transcript_available: isLocalTranscriptAvailable,
      is_caption_processing: isYTProcessing,
      is_local_caption_processing: isLOCALProcessing,
      is_transcript_error: isTranscriptError,
      is_transcript_empty: isTranscriptEmpty, // Include new field
      timestamps,
      summary,
      takeaways,
      titles,
      hashtags,
      is_sc_run: isScRun,
    };

    const hasChanges =
      workspaceTitle !== originalWorkspaceTitle ||
      isScTimestampsEnabled !== originalIsScTimestampsEnabled ||
      isScDescriptionEnabled !== originalIsScDescriptionEnabled ||
      isScTakeawaysEnabled !== originalIsScTakeawaysEnabled ||
      isScTitlesEnabled !== originalIsScTitlesEnabled ||
      isScTagsEnabled !== originalIsScTagsEnabled ||
      isTranscriptAvailable !== originalIsTranscriptAvailable ||
      isLocalTranscriptAvailable !== originalIsLocalTranscriptAvailable ||
      isYTProcessing !== originalIsYTProcessing ||
      isLOCALProcessing !== originalIsLOCALProcessing ||
      isTranscriptError !== originalIsTranscriptError ||
      isTranscriptEmpty !== originalIsTranscriptEmpty; // Compare new field

    setIsSaveButtonEnabled(hasChanges);

    // Optionally, you can update local storage whenever there's a change
    localStorage.setItem(localDataKey, JSON.stringify(workspaceData));
  }, [
    workspaceTitle,
    isScTimestampsEnabled,
    isScDescriptionEnabled,
    isScTakeawaysEnabled,
    isScTitlesEnabled,
    isScTagsEnabled,
    isTranscriptAvailable,
    isLocalTranscriptAvailable,
    isYTProcessing,
    isLOCALProcessing,
    isTranscriptError,
    isTranscriptEmpty, // Include new dependency
    timestamps,
    summary,
    takeaways,
    titles,
    hashtags,
    isScRun,
    originalWorkspaceTitle,
    originalIsScTimestampsEnabled,
    originalIsScDescriptionEnabled,
    originalIsScTakeawaysEnabled,
    originalIsScTitlesEnabled,
    originalIsScTagsEnabled,
    originalIsTranscriptAvailable,
    originalIsLocalTranscriptAvailable,
    originalIsYTProcessing,
    originalIsLOCALProcessing,
    originalIsTranscriptError,
    originalIsTranscriptEmpty, // Include new original state
    content_id,
  ]);

  const handleUpdateWorkspace = useCallback(async () => {
    try {
      await updateWorkspace({
        user,
        content_id,
        workspaceTitle,
        isSCTimestampsEnabled: isScTimestampsEnabled,
        isSCDescriptionEnabled: isScDescriptionEnabled,
        isSCTakeawaysEnabled: isScTakeawaysEnabled,
        isSCTitlesEnabled: isScTitlesEnabled,
        isSCTagsEnabled: isScTagsEnabled,
        isTranscriptAvailable,
        isLocalTranscriptAvailable,
        isYTProcessing,
        isLOCALProcessing,
        isTranscriptError,
        isTranscriptEmpty, // New field
        timestamps,
        summary,
        takeaways,
        titles,
        hashtags,
        isScRun,
      });

      // Update original values to match current values after successful save
      setOriginalWorkspaceTitle(workspaceTitle);
      setOriginalIsScTimestampsEnabled(isScTimestampsEnabled);
      setOriginalIsScDescriptionEnabled(isScDescriptionEnabled);
      setOriginalIsScTakeawaysEnabled(isScTakeawaysEnabled);
      setOriginalIsScTitlesEnabled(isScTitlesEnabled);
      setOriginalIsScTagsEnabled(isScTagsEnabled);
      setOriginalIsTranscriptAvailable(isTranscriptAvailable);
      setOriginalIsLocalTranscriptAvailable(isLocalTranscriptAvailable);
      setOriginalIsYTProcessing(isYTProcessing);
      setOriginalIsLOCALProcessing(isLOCALProcessing);
      setOriginalIsTranscriptError(isTranscriptError);
      setOriginalIsTranscriptEmpty(isTranscriptEmpty); // Update original state
    } catch (error) {
      console.error('Error updating workspace:', error);
    }
  }, [
    user,
    content_id,
    workspaceTitle,
    isScTimestampsEnabled,
    isScDescriptionEnabled,
    isScTakeawaysEnabled,
    isScTitlesEnabled,
    isScTagsEnabled,
    isTranscriptAvailable,
    isLocalTranscriptAvailable,
    isYTProcessing,
    isLOCALProcessing,
    isTranscriptError,
    isTranscriptEmpty, // New dependency
    timestamps,
    summary,
    takeaways,
    titles,
    hashtags,
    isScRun,
  ]);

  return (
    <div className="template-workspace-container">
      {isSidebarOpen ? <Sidebar /> : <CloseSidebar toggleSidebar={toggleSidebar} />}
      <AppTransition>
        <div className="template-workspace-main">
          {loading ? (
            <div>
              <ProcessingPage />
            </div>
          ) : (
            <>
              <div className="workspace-header-container">
                <WorkspaceHeader
                  workspaceTitle={workspaceTitle}
                  isTranscriptAvailable={isTranscriptAvailable}
                  isLocalTranscriptAvailable={isLocalTranscriptAvailable}
                  isTranscriptError={isTranscriptError}
                  isTranscriptEmpty={isTranscriptEmpty} // Pass new prop
                  isYTProcessing={isYTProcessing}
                  isLOCALProcessing={isLOCALProcessing}
                  workspaceCreatedFunction={handleUpdateWorkspace}
                  setWorkspaceTitle={setWorkspaceTitle}
                  isSaveButtonEnabled={isSaveButtonEnabled}
                />
              </div>

              <div className="template-row-container">
                <div className="template-upload-container">
                  <UploadWorkspace
                    isTranscriptAvailable={isTranscriptAvailable}
                    isLocalTranscriptAvailable={isLocalTranscriptAvailable}
                    isTranscriptEmpty={isTranscriptEmpty} // Pass new prop
                    isYTProcessing={isYTProcessing}
                    isLOCALProcessing={isLOCALProcessing}
                    content_id={content_id}
                  />
                  <div className="modify-shortcuts-container">
                    <ShortcutsWorkspace
                      isScTimestampsEnabled={isScTimestampsEnabled}
                      setIsScTimestampsEnabled={setIsScTimestampsEnabled}
                      isScDescriptionEnabled={isScDescriptionEnabled}
                      setIsScDescriptionEnabled={setIsScDescriptionEnabled}
                      isScTakeawaysEnabled={isScTakeawaysEnabled}
                      setIsScTakeawaysEnabled={setIsScTakeawaysEnabled}
                      isScTitlesEnabled={isScTitlesEnabled}
                      setIsScTitlesEnabled={setIsScTitlesEnabled}
                      isScTagsEnabled={isScTagsEnabled}
                      setIsScTagsEnabled={setIsScTagsEnabled}
                      isTranscriptAvailable={isTranscriptAvailable}
                      isLocalTranscriptAvailable={isLocalTranscriptAvailable}
                      content_id={content_id}
                      timestamps={timestamps}
                      summary={summary}
                      takeaways={takeaways}
                      titles={titles}
                      hashtags={hashtags}
                    />
                  </div>
                </div>
              </div>

              <div className="template-prompts-container">
                <PromptsWorkspace
                  isTranscriptAvailable={isTranscriptAvailable}
                  isLocalTranscriptAvailable={isLocalTranscriptAvailable}
                  isYTProcessing={isYTProcessing}
                  isLOCALProcessing={isLOCALProcessing}
                  content_id={content_id}
                  timestamps={timestamps}
                  summary={summary}
                  takeaways={takeaways}
                  titles={titles}
                  hashtags={hashtags}
                  isScRun={isScRun}
                  isScTimestampsEnabled={isScTimestampsEnabled}
                  isScDescriptionEnabled={isScDescriptionEnabled}
                  isScTakeawaysEnabled={isScTakeawaysEnabled}
                  isScTitlesEnabled={isScTitlesEnabled}
                  isScTagsEnabled={isScTagsEnabled}
                />
              </div>
            </>
          )}
        </div>
      </AppTransition>
    </div>
  );
};

export default ExistingWorkspace;
