import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Ensure this is the correct import

// Function to send payload to the postYoutube2GB function
const postYoutube2GB = async (videoId, visibility, userId, scheduleTimestamp = null) => {
    await postYoutubeGeneric('postYoutube2GB', videoId, visibility, userId, scheduleTimestamp, 600000);
};

// Function to send payload to the postYoutube4GB function
const postYoutube4GB = async (videoId, visibility, userId, scheduleTimestamp = null) => {
    await postYoutubeGeneric('postYoutube4GB', videoId, visibility, userId, scheduleTimestamp, 600000);
};

// Function to send payload to the postYoutube16GB function
const postYoutube16GB = async (videoId, visibility, userId, scheduleTimestamp = null) => {
    await postYoutubeGeneric('postYoutube16GB', videoId, visibility, userId, scheduleTimestamp, 1200000);
};

// Function to send payload to the postYoutube32GB function
const postYoutube32GB = async (videoId, visibility, userId, scheduleTimestamp = null) => {
    await postYoutubeGeneric('postYoutube32GB', videoId, visibility, userId, scheduleTimestamp, 1500000);
};

// Generic function to prepare payload and call the respective Cloud Function
const postYoutubeGeneric = async (functionName, videoId, visibility, userId, scheduleTimestamp = null, timeoutms) => {
    const valueDataRef = doc(db, 'users', userId, 'bumpContent', `bump-${videoId}`);

    try {
        const docSnap = await getDoc(valueDataRef);
        if (!docSnap.exists()) {
            throw new Error("No such document!");
        }

        const videoData = docSnap.data();
        const videoArray = [{
            'videoId': videoId,
            'postType': 'youtube',
            'videoVisibility': visibility,
            'videoThumbnail': videoData.video_thumbnail || '',
            'videoTitle': videoData.publish_title || '',
            'videoDescription': videoData.publish_description || '',
            'videoTags': (videoData.publish_hashtags || []).join(',')
        }];

        if (scheduleTimestamp) {
            videoArray[0].scheduleTimestamp = scheduleTimestamp;
        }

        // Set timeout options directly in httpsCallable
        const functions = getFunctions();
        const postYoutube = httpsCallable(functions, functionName, { timeout: timeoutms }); // 1200000 ms = 25 minutes

        await postYoutube({ videoArray });
    } catch (error) {
        // Handle error if necessary
    }
};




export { postYoutube2GB, postYoutube4GB, postYoutube16GB, postYoutube32GB };
