import React, { useState } from 'react';
import './warningSetting.css';
import { unregisterAllListeners } from '../../../components/appComponents/ListenerManager';
import { auth } from '../../../firebase';
import { useNavigate } from 'react-router-dom';

const WarningSetting = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Logout action for signing out the user
  const handleLogout = async () => {
    try {
      unregisterAllListeners(); 
      await auth.signOut();
      navigate('/');
    } catch (error) {
      // Optional: Handle error if logging out fails
      // console.error("Error logging out:", error);
    }
  };

  // Show the modal for account deletion
  const handleDeleteAccount = () => {
    setShowModal(true);
  };

  // Hide the modal without deleting
  const handleCancel = () => {
    setShowModal(false);
  };

  // Confirm delete action
  const handleConfirmDelete = () => {
    alert('Account deletion confirmed. Account deleted.');
    setShowModal(false);
  };

  return (
    <>
      {/* Header outside the container */}
      <div className="setting-header">System</div>

      {/* Main container, similar to other settings components */}
      <div className="account-settings-container">
        {/* New row for Active account */}
        <div className="settings-row">
          <div className="settings-label">
            <span>Active account</span>
            <div className="settings-subtitle">
              You are signed in
            </div>
          </div>
          <div className="settings-field">
            <button className="ui-setting-button" onClick={handleLogout}>
              Sign out
            </button>
          </div>
        </div>

        {/* Existing row for Delete Account */}
        {/* <div className="settings-row">
          <div className="settings-label">
            <span>Delete account</span>
            <div className="settings-subtitle">
              Permanently delete your account and data
            </div>
          </div>
          <div className="settings-field">
            <button className="ui-setting-button" onClick={handleDeleteAccount}>
              Learn More
            </button>
          </div>
        </div> */}

        {showModal && (
          <div className="warning-modal-overlay">
            <div className="warning-modal">
              
              {/* Modal Header with Title & Close Button */}
              <div className="warning-modal-header">
                <h2>Confirm Account Deletion</h2>
                <button className="warning-modal-close" onClick={handleCancel}>
                  &times;
                </button>
              </div>
              
              {/* Modal Content */}
              <div className="warning-modal-content">
                <p>
                  Before you delete your account, please take a moment to understand what
                  will happen to your data:
                </p>
                <ul className="warning-list">
                  <li>Your profile details, preferences, and settings will be removed.</li>
                  <li>
                    You won't be able to access past videos processed,
                    workspaces, or past transactions. Any new account with the same email
                    won't receive free credits.
                  </li>
                </ul>
                <p>
                  All data will be permanently deleted 30 days after account deletion.
                  <strong> Keep in mind that deleting your account can’t be undone.</strong>
                </p>
              </div>
              
              {/* Modal Footer with Buttons */}
              <div className="warning-modal-footer">
                <button className="warning-cancel-button" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="warning-confirm-delete-button" onClick={handleConfirmDelete}>
                  Confirm Deletion
                </button>
              </div>

            </div>
          </div>
        )}


        
      </div>
    </>
  );
};

export default WarningSetting;
