import React, { useState } from 'react';
import Linkify from 'react-linkify';
import './editMyBump.css';
import copy_icon from '../../../assets/images/icons/copy-icon.png';
import checkmark_icon from '../../../assets/images/icons/checkmark-icon.png';

const PreviewBumpPopup = ({ isOpen, onClose, content }) => {
    const [copied, setCopied] = useState(false);

    if (!isOpen) return null;

    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer" className="aibump-linkified">
            {text}
        </a>
    );

    const wrapHashtagsAndTimestamps = (text) => {
        const hashtagRegex = /(#\w+)/g;
        const timestampRegex = /\b(\d{1,2}:\d{2}(?::\d{2})?)\b/g;

        const parts = text.split(/(#\w+|\b\d{1,2}:\d{2}(?::\d{2})?\b)/g);

        return parts.map((part, index) => {
            if (hashtagRegex.test(part)) {
                return <span key={index} className="aibump-hashtag">{part}</span>;
            }
            if (timestampRegex.test(part)) {
                return <span key={index} className="aibump-timestamp">{part}</span>;
            }
            return part;
        });
    };

    const handleBumpAICopy = () => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    };

    return (
        <div className="editBump-popup-overlay" onClick={onClose}>
            <div className="editBump-popup-content" onClick={(e) => e.stopPropagation()}>
                <div className="editBump-popup-header">
                    <div className="editBump-popup-title">Bump AI Description</div>
                    <button className="copy-summary-btn-videoid" onClick={handleBumpAICopy}>
                        <div className="tooltip-videoid">
                            <img src={copied ? checkmark_icon : copy_icon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
                            <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
                        </div>
                    </button>
                </div>
                <div className="editBump-popup-inner-content-preview">
                    <Linkify componentDecorator={componentDecorator}>
                        {wrapHashtagsAndTimestamps(content)}
                    </Linkify>
                </div>
                <div className="editBump-popup-footer">
                    <button className="editBump-close-btn" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default PreviewBumpPopup;
