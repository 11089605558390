import React, { useState, useEffect } from 'react';
import './cookieBar.css';

const CookieBar = () => {
  const [showBar, setShowBar] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const consent = localStorage.getItem('cookieConsent');
      const laterTime = localStorage.getItem('laterTime');
      let shouldShow = false;
      if (consent === 'accepted') {
        shouldShow = false;
      } else if (consent === 'later' && laterTime) {
        const currentTime = new Date().getTime();
        const timePassed = (currentTime - laterTime) / (1000 * 60); // minutes
        shouldShow = timePassed >= 5;
      } else {
        shouldShow = true;
      }
      if (shouldShow) {
        setShowBar(true);
        setAnimate(true);
      }
    }, 2000); // 2-second delay

    return () => clearTimeout(timer);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBar(false);
    // Optionally, update Firebase
  };

  const handleLater = () => {
    localStorage.setItem('cookieConsent', 'later');
    const currentTime = new Date().getTime();
    localStorage.setItem('laterTime', currentTime);
    setShowBar(false);

    // Set a timeout to show the bar again after 5 minutes
    setTimeout(() => {
      setShowBar(true);
      setAnimate(true);
    }, 300000); // 5 minutes in milliseconds

    // Optionally, update Firebase
  };

  return (
    showBar && (
      <div className={`cookie-bar ${animate ? 'slide-up' : ''}`}>
        <div className="cookie-content-wrapper">
          <p className="cookie-text">
            Our website uses cookies. By continuing, we assume your permission to deploy cookies as detailed in our <a href="/privacy-policy">Privacy Policy</a>.
          </p>
          <div className="button-wrapper">
            <button className="accept-cookies-btn" onClick={handleAccept}>Accept Cookies</button>
            <button className="later-btn" onClick={handleLater}>Later</button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieBar;
