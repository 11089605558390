// src/components/UploadWorkspace/YTVideoDisplay.js

import React from 'react';
import baseplan_white_up_arrow_icon from '../../../../assets/images/icons/baseplan-white-up-arrow-icon.png';
import link_icon from '../../../../assets/images/icons/link-icon.png';
import preview_thumbnail from '../../../../assets/images/thumbnail/preview-thumbnail.png'; // Ensure this path is correct
import './linkWorkspace.css'; // Shared CSS

const YTVideoDisplay = ({ workspaceVideoDetails, doneVideos }) => {
    
    // Helper function to format duration
    const formatDuration = (duration) => {
        if (!duration) return '0m';
        if (typeof duration === 'number') {
            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration % 3600) / 60);
            let formatted = '';
            if (hours > 0) formatted += `${hours}h `;
            formatted += `${minutes}m`;
            return formatted.trim();
        } else {
            // Assume ISO 8601 format
            const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
            let hours = match[1] ? parseInt(match[1].slice(0, -1), 10) : 0;
            let minutes = match[2] ? parseInt(match[2].slice(0, -1), 10) : 0;
            let formatted = '';
            if (hours > 0) formatted += `${hours}h `;
            if (minutes > 0 || (minutes === 0 && hours === 0)) formatted += `${minutes}m`;
            return formatted.trim();
        }
    };

    // Helper function to truncate title if it's too long
    const truncateTitle = (title, maxLength = 66) => {
        if (!title) return '';
        return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
    };

    return (
        <section className="worklink-urlinput-section">
            <div className="worklink-urlinput-container">
                <div className="worklink-input-icon-wrapper">
                    <img src={link_icon} alt="Link Icon" className="worklink-url-input-icon" />
                    <input
                        type="text"
                        id="urlInput"
                        className="worklink-input-icon-wrapper-DONE"
                        value={`https://youtu.be/${workspaceVideoDetails.videoId}`}
                        disabled
                    />
                </div>

                <div>
                    {doneVideos.includes(workspaceVideoDetails.videoId) && (
                        <a 
                            href={`https://youtu.be/${workspaceVideoDetails.videoId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="worklink-url-input-button videoDataReady"
                        >
                            <img src={baseplan_white_up_arrow_icon} alt="Base Plan Logo" className="icon" />
                            View Video
                        </a>
                    )}
                </div>
            </div>

            {/* Video Details Section */}
            {workspaceVideoDetails.thumbnailUrl && (
                <div className="worklink-workspace-video-details">
                    <div className="worklink-video-card">
                        <div className="worklink-video-metadata">
                            {workspaceVideoDetails.language && workspaceVideoDetails.language !== 'Language unspecified' ? (
                                <div className="worklink-video-caption">
                                    Caption: <b>{workspaceVideoDetails.language}</b>
                                </div>
                            ) : (
                                <div className="worklink-video-caption" aria-hidden="true">&#160;</div>
                            )}
                            <div className="worklink-video-credit-usage">
                                <span className="badge-base">Base</span> Credit Cost: <b>{
                                    workspaceVideoDetails.durationSeconds
                                        ? formatDuration(workspaceVideoDetails.durationSeconds)
                                        : formatDuration(workspaceVideoDetails.duration)
                                }</b>
                            </div>
                        </div>
                        <div className="worklink-video-overlay">
                            {/* Placeholder for potential overlay content */}
                        </div>

                        <div className="worklink-video-thumbnail-container" style={{ position: 'relative' }}>
                            <img 
                                src={workspaceVideoDetails.thumbnailUrl || preview_thumbnail} 
                                alt="Video Thumbnail" 
                                className="worklink-video-thumbnail"
                            />
                        </div>
                        {workspaceVideoDetails.title && (
                            <div className="worklink-video-title-DONE">
                                <b>
                                    {truncateTitle(workspaceVideoDetails.title)}
                                </b>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default YTVideoDisplay;
