// src/components/NonAuthThumbnailLayout.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../myBumpUp/myCreator/ThumbnailLayout.css'; 

// FIREBASE
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, onSnapshot, collection, setDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';

// FUNCTIONS
import { registerListener } from '../../components/appComponents/ListenerManager';
import he from 'he';

// IMPORT UI
import InsufficientTimePopUp from '../../components/miscComponents/InsufficientTimePopUp';

// IMAGES
import upmove_icon from '../../assets/images/icons/upmove-icon.png';
import setting_icon from '../../assets/images/icons/setting-icon.png';
import bumpups_white_big_logo from '../../assets/images/logo/bumpups-white-big-logo.png';
import reload_white_icon from '../../assets/images/icons/reload-white-icon.png';
import processlogo from '../../assets/gifmedia/bumpups-2.gif';
import youtube_white_logo from '../../assets/images/logo/youtube-white-logo.png';
import youtube1_logo from '../../assets/images/logo/youtube1-logo.png';

// **Helper Function: Remove Duplicate Videos Based on videoId**
const removeDuplicates = (videos) => {
  const seen = new Set();
  return videos.filter(video => {
    const duplicate = seen.has(video.videoId);
    seen.add(video.videoId);
    return !duplicate;
  });
};

// **Helper Function: Format Duration (Moved Outside Component)**
const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += `${hours}h `;
    if (minutes > 0) {
      formattedDuration += `${minutes}m`;
    }
  } else {
    formattedDuration = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  return formattedDuration.trim();
};

// **Helper Function: Check if All Specified Data Points are Missing**
const isDataMissing = (video) => {
  return (
    !video.hashtags &&
    !video.takeaways &&
    !video.titles &&
    !video.summary &&
    !video.timestamps
  );
};


const NonAuthThumbnailLayout = () => {
  // **State Declarations**
  const [videos, setVideos] = useState([]);
  const [activeTab, setActiveTab] = useState('all'); // 'all' or 'archive'

  const [doneVideos, setDoneVideos] = useState([]); 
  const [videoStats, setVideoStats] = useState({}); 
  const [initialVideoStats, setInitialVideoStats] = useState({});
  const [insufficientTime, setInsufficientTime] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(reload_white_icon);
  const [hoveredVideoId, setHoveredVideoId] = useState(null);

  // **Pagination States**
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 24; // Adjust as needed

  const functions = getFunctions();
  const refreshVLCStats = httpsCallable(functions, 'refreshVLCStats');


  // **Effect: Fetch and Listen for Video Data from Firestore**
  useEffect(() => {
    const fetchAndListenVideoData = () => {
      const user = auth.currentUser;
      if (!user) return; // Ensure user is authenticated

      const userDocRef = doc(db, 'users', user.uid);
      const unAuthCollectionRef = collection(userDocRef, 'youtubeUrlContent');

      const unsubscribe = onSnapshot(unAuthCollectionRef, (querySnapshot) => {
        const doneVideoIds = [];
        const stats = {};
        const initialStats = {};
        const fetchedVideos = [];

        querySnapshot.forEach((docSnap) => {
          const docData = docSnap.data();
          const videoId = docSnap.id.startsWith('youtubeUrl-') ? docSnap.id.substring('youtubeUrl-'.length) : docSnap.id;

          doneVideoIds.push(videoId);

          stats[videoId] = {
            viewCount: docData.curr_view_count,
            likeCount: docData.curr_like_count,
            commentCount: docData.curr_comment_count,
          };

          initialStats[videoId] = {
            viewCount: docData.init_view_count,
            likeCount: docData.init_like_count,
            commentCount: docData.init_comment_count,
          };

          fetchedVideos.push({
            videoId,
            ...docData,  // Include all document data
            isArchived: docData.isArchived || false, // Ensure isArchived field is set
          });
        });

        // **Sort the fetchedVideos by youtube_url_creation_time descending**
        const sortedVideos = fetchedVideos.sort((a, b) => {
          const timeA = a.youtube_url_creation_time ? a.youtube_url_creation_time.toMillis() : 0;
          const timeB = b.youtube_url_creation_time ? b.youtube_url_creation_time.toMillis() : 0;
          return timeB - timeA; // Descending order
        });

        const uniqueVideos = removeDuplicates(sortedVideos); // Remove duplicates if necessary
        setVideos(uniqueVideos);
        setDoneVideos(doneVideoIds);
        setVideoStats(stats);
        setInitialVideoStats(initialStats);
        localStorage.setItem(`youtubeUrlCache_${user.uid}`, JSON.stringify(uniqueVideos)); // Cache videos in localStorage
        setCurrentPage(1); // Reset to first page on data update
      });

      registerListener(unsubscribe);

      return () => {
        unsubscribe();
      };
    };

    fetchAndListenVideoData();
  }, []);

  // **Effect: Listen for Insufficient Time Subscription Status**
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(db, 'users', user.uid);
    const insufficientTimeRef = doc(userDocRef, 'subscriptionData', 'noTimeAvailable');

    const unsubscribe = onSnapshot(insufficientTimeRef, (docSnap) => {
      const isTimeInsufficient = docSnap.exists() && docSnap.data().insufficientTime;
      setInsufficientTime(isTimeInsufficient);

      if (isTimeInsufficient) {
        // **Removed: Clearing processingVideos from cache**
        // setProcessingVideos([]);
      }
    });

    registerListener(unsubscribe);

    return () => unsubscribe();
  }, []);

  // **Calculate the Number of Processed Videos**
  const numberOfVideos = videos.filter(video => !video.isArchived && video.is_transcript_available).length;

  // **Handle Tab Change (All or Archive)**
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setCurrentPage(1); // Reset to first page on tab change
  };

  // **Handle Archiving/Unarchiving a Video**
  const handleArchiveVideo = async (videoId, shouldArchive) => {
    try {
      const updatedVideos = videos.map(video => 
        video.videoId === videoId ? { ...video, isArchived: shouldArchive } : video
      );
      setVideos(updatedVideos);
  
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const videoCacheRef = collection(userDocRef, 'youtubeUrlContent');
  
      await Promise.all(
        updatedVideos.map(video => {
          const videoRef = doc(videoCacheRef, `youtubeUrl-${video.videoId}`);
          return setDoc(videoRef, video, { merge: true });
        })
      );
  
      const localStorageKey = `youtubeUrlCache_${auth.currentUser.uid}`;
      localStorage.removeItem(localStorageKey);
      localStorage.setItem(localStorageKey, JSON.stringify(updatedVideos));
      setCurrentPage(1); // Reset to first page on archive
    } catch (error) {
      // console.error("Error updating video archive status:", error);
    }
  };
  
  // **Render Video Title with Decoding and Truncation**
  const renderTitle = (video_title) => {
    const decodedTitle = he.decode(video_title);
    return decodedTitle.length > 37 ? `${decodedTitle.substring(0, 37)}...` : decodedTitle;
  };

  // **Format Numbers for Display (e.g., 1.2K, 3.4M)**
  const formatStatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num;
    }
  };

  // **Handle Reload Button Click to Refresh Stats**
  const handleReloadClick = async () => {
    try {
      setIsProcessing(true);
      setCurrentLogo(processlogo);
      await refreshVLCStats({ videoType: "youtubeUrl" });
    } catch (error) {
      // console.error("Error refreshing stats:", error);
    } finally {
      setIsProcessing(false);
      setCurrentLogo(reload_white_icon);
      setIsCooldown(true);
      setTimeout(() => setIsCooldown(false), 5000);
    }
  };

  // **Pagination Logic**
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const filteredVideos = videos.filter(video => {
    // Ensure is_transcript_available exists and is true
    if (!video.is_transcript_available) return false;
    
    // Existing tab-based filtering
    if (activeTab === 'all') return !video.isArchived;
    if (activeTab === 'archive') return video.isArchived;
    return false;
  });
  const currentVideos = filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);
  const totalPages = Math.ceil(filteredVideos.length / videosPerPage);
  const startVideoNumber = filteredVideos.length === 0 ? 0 : indexOfFirstVideo + 1;
  const endVideoNumber = Math.min(indexOfLastVideo, filteredVideos.length);

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <div className="unauth-thumbnail-layout">
      {/* **Tabs and Reload Button** */}
      <div className="tabs-container">
        <button
          className="reload-button-unauth"
          data-hover-text="Get Latest Stats"
          onClick={handleReloadClick}
          disabled={isProcessing || isCooldown}
          style={{
            cursor: isCooldown || isProcessing ? 'not-allowed' : 'pointer',
            opacity: isCooldown || isProcessing ? 0.5 : 1,
            backgroundColor: isProcessing ? 'rgba(74, 221, 128, 0.5)' : 'initial',
            position: 'relative'
          }}
        >
          <img src={currentLogo} alt="Reload Analytics" className="reload-analytics-logo-unauth" />
        </button>
  
        <div 
          className={`tab ${activeTab === 'all' ? 'active' : ''}`} 
          onClick={() => handleTabChange('all')}
        >
          Uploaded (<b>{numberOfVideos}</b>)
        </div>
        <div 
          className={`tab ${activeTab === 'archive' ? 'active' : ''}`} 
          onClick={() => handleTabChange('archive')}
        >
          Archive
        </div>
      </div>
  
      {/* **Thumbnail Grid** */}
      <div className="thumbnail-grid">
        {
          currentVideos.map((video) => {
            const isDone = doneVideos.includes(video.videoId);
            const dataMissing = isDataMissing(video); // Check if all specified data points are missing

            return (
              <div key={video.videoId} className="videocontent-container">

                <div className="thumbnail-wrapper">
                  <div 
                    className="thumbnail-item"
                    onMouseEnter={() => setHoveredVideoId(video.videoId)}
                    onMouseLeave={() => setHoveredVideoId(null)}
                  >
                    {/* **Duration Badge / Archive Button** */}
                    <div 
                      className={`duration-badge 
                        ${hoveredVideoId === video.videoId && (activeTab === 'archive' || !video.isArchived) ? 'archive-active' : ''} 
                        ${dataMissing ? 'archive disabled' : ''}
                      `}
                      onClick={() => {
                        if (!dataMissing) {
                          handleArchiveVideo(video.videoId, !video.isArchived);
                        }
                      }}
                      aria-disabled={dataMissing}
                      role={dataMissing ? 'presentation' : 'button'}
                      tabIndex={dataMissing ? '-1' : '0'}
                      title={dataMissing ? 'Cannot archive video because data is missing' : 'Click to archive'}
                    >
                      {
                        !dataMissing && hoveredVideoId === video.videoId 
                          ? (activeTab === 'archive' ? 'Unarchive' : 'Archive') 
                          : formatDuration(video.video_duration_in_seconds)
                      }
                    </div>

                    {/* **UnauthProcessing Badge** */}
                    <div
                      className={
                        dataMissing
                          ? "unauthprocessing-badge orange" // Apply orange class if data is missing
                          : isDone
                          ? "unauthprocessing-badge done"
                          : "unauthprocessing-badge"
                      }
                    >
                      <img src={bumpups_white_big_logo} alt="Timestamp Badge" />
                    </div>

                    {/* **Video Thumbnail** */}
                    <img src={video.video_thumbnail} alt="Thumbnail" />

                    {/* **Done Video Overlay or Orange Overlay** */}
                    {isDone && (
                      !dataMissing ? (
                        <Link to={`/video/id-${video.videoId}`}>
                          <div className="done-video-overlay"></div>
                        </Link>
                      ) : (
                        <div className="done-video-overlay overlay-orange"></div>
                      )
                    )}

                    {/* **YouTube Link Button** */}
                    <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="youtube-link-button">
                      <img src={isDone ? youtube1_logo : youtube_white_logo} alt="YouTube Link" className="youtube-link-icon" />
                    </a>
                  </div>

                  {/* **Video Info and Action Buttons** */}
                  <div className="info-action-wrapper">
                    <div className="info-rectangle">
                      <div className="video-title">
                        {renderTitle(video.video_title)}
                      </div>
                      <div className="action-buttons videolayout">
                        {!isDone && (
                          /* **Modified "Process Video" Button** */
                          <button 
                            className="icon-button not-done" 
                            disabled={true} // Disabled since processing is removed
                            aria-disabled={true}
                            title="Video processing is no longer available"
                          >
                            <img src={upmove_icon} alt="Process" className="icon" />
                            <span className="process-tooltip">Process Video</span>
                          </button>
                        )}
                          {isDone && !dataMissing && (
                            <Link 
                              to={`/video/id-${video.videoId}`} 
                              state={{ from: '/links' }} 
                              className="icon-button done"
                            >
                              <img src={setting_icon} alt="More" className="icon" />
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* **Video Statistics** */}
                <div className="video-stats-container">
                  <div className={`video-stats ${isDone && videoStats[video.videoId]?.viewCount != null && videoStats[video.videoId]?.likeCount != null && videoStats[video.videoId]?.commentCount != null ? '' : 'dimmed'}`}>
                    {/* **Views Stat** */}
                    <div className="video-stat">
                      <div className="stat-icon">Views</div>
                      <div className={`stat-box ${isDone && videoStats[video.videoId]?.viewCount != null && videoStats[video.videoId]?.viewCount !== initialVideoStats[video.videoId]?.viewCount ? 'number' : 'default'}`}>
                        {isDone && videoStats[video.videoId]?.viewCount != null
                          ? <>
                              {formatStatNumber(videoStats[video.videoId].viewCount)}
                              {videoStats[video.videoId]?.viewCount > initialVideoStats[video.videoId]?.viewCount && (
                                <span className="increase">+{formatStatNumber(videoStats[video.videoId].viewCount - initialVideoStats[video.videoId]?.viewCount)}</span>
                              )}
                            </>
                          : '--'}
                      </div>
                    </div>

                    {/* **Likes Stat** */}
                    <div className="video-stat">
                      <div className="stat-icon">Likes</div>
                      <div className={`stat-box ${isDone && videoStats[video.videoId]?.likeCount != null && videoStats[video.videoId]?.likeCount !== initialVideoStats[video.videoId]?.likeCount ? 'number' : 'default'}`}>
                        {isDone && videoStats[video.videoId]?.likeCount != null
                          ? <>
                              {formatStatNumber(videoStats[video.videoId].likeCount)}
                              {videoStats[video.videoId]?.likeCount > initialVideoStats[video.videoId]?.likeCount && (
                                <span className="increase">+{formatStatNumber(videoStats[video.videoId].likeCount - initialVideoStats[video.videoId]?.likeCount)}</span>
                              )}
                            </>
                          : '--'}
                      </div>
                    </div>

                    {/* **Comments Stat** */}
                    <div className="video-stat">
                      <div className="stat-icon">Comments</div>
                      <div className={`stat-box ${isDone && videoStats[video.videoId]?.commentCount != null && videoStats[video.videoId]?.commentCount !== initialVideoStats[video.videoId]?.commentCount ? 'number' : 'default'}`}>
                        {isDone && videoStats[video.videoId]?.commentCount != null
                          ? <>
                              {formatStatNumber(videoStats[video.videoId].commentCount)}
                              {videoStats[video.videoId]?.commentCount > initialVideoStats[video.videoId]?.commentCount && (
                                <span className="increase">+{formatStatNumber(videoStats[video.videoId].commentCount - initialVideoStats[video.videoId]?.commentCount)}</span>
                              )}
                            </>
                          : '--'}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            );
          })
        }
      </div>

      {/* **Pagination Controls** */}
      {totalPages > 1 && (
        <div className="unauth-pagination-controls">
          <span className="unauth-pagination-info">
            {startVideoNumber}-{endVideoNumber} of about {filteredVideos.length}
          </span>
          <button
            onClick={goToFirstPage}
            disabled={currentPage === 1}
            className="unauth-pagination-button"
          >
            <i className="unauth-pagination-arrow unauth-double-left"></i>
          </button>
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className="unauth-pagination-button"
          >
            <i className="unauth-pagination-arrow unauth-left"></i>
          </button>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="unauth-pagination-button"
          >
            <i className="unauth-pagination-arrow unauth-right"></i>
          </button>
          <button
            onClick={goToLastPage}
            disabled={currentPage === totalPages}
            className="unauth-pagination-button"
          >
            <i className="unauth-pagination-arrow unauth-double-right"></i>
          </button>
        </div>
      )}

      {/* **Insufficient Time Popup** */}
      {insufficientTime && <InsufficientTimePopUp />}
    </div>
  );
};

export default NonAuthThumbnailLayout;
