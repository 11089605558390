// src/HomePage.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

// Firebase auth and context
import { signInWithRedirect } from 'firebase/auth';
import { AuthProvider } from '../AuthContext';
import { auth, googleProvider } from '../firebase';

// Components and images
import FAQComponent from './unauthComponent/FAQComponent';
import ExampleVideoCarousel from './unauthComponent/exampleVideoCarousel';
import PodcastUseCase from './unauthComponent/podcastUseCase';
import Footer from './Footer';
import CookieBar from '../components/miscComponents/CookieBar';
import SocialProfileCarousel from './unauthComponent/socialprofileCarousel';

// Images
import google_big_logo from '../assets/images/logo/google-big-logo.png'; // Updated import for Google logo
import workspace_topfold_branding from '../assets/images/branding/workspace-topfold-branding.png';
import bumpups_preview_video from '../assets/images/default/bumpups-preview-video.jpg';

const possibleVideoValue = [
  "Anything",
  "Timestamps",
  "Data Tables",
  "Quick Summaries",
  "Study Guide",
  "SWOT Analysis",
  "Case Brief",
  "Tutorial Steps",
  "Legal Analysis",
  "Coding Steps",
  "Interview Insights",
  "Footnotes",
  "Annotated Excerpts",
  "Code Blocks",
  "Bullet Points",
  "Instructions",
  "Blockquotes",
];

function HomePage() {
  const navigate = useNavigate();
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  // Capture and store GCLID
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gclid = params.get('gclid') || '';
    localStorage.setItem('gclid', gclid);
  }, []);

  // Manage animation classes for header text
  const [animationState, setAnimationState] = useState('fade-in');

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState('fade-out');
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % possibleVideoValue.length);
        setAnimationState('fade-in');
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleGoogleSignUp = async () => {
    try {
      await signInWithRedirect(auth, googleProvider);
    } catch (error) {
      // Handle error if needed
    }
  };

  return (
    <AuthProvider>
      <Helmet>
        <title>Bumpups | Transform Every Video into Actionable Insights</title>
        <meta name="description" content="Access an AI layer that turns raw video content into searchable, actionable insights. Unlock hidden value, drive innovation, and explore your favorite videos like never before." />
      </Helmet>
      <div className="hp-homepage-container">
        {/* Top fold section */}
        <div className="hp-topfold-section">
          <div className="hp-topfold-content">
            {/* Get Started Pill */}
            <button className="hp-get-started-pill" onClick={handleSignUp}>
              <span className="hp-pill-badge">Free</span>
              <span className="hp-pill-text">#1 AI Video Processer</span>
              <span className="hp-pill-arrow">→</span>
            </button>
            
            {/* Main Header */}
            <h1 className="hp-main-header">
              Process videos for <br /> 
              <span className={`highlight ${animationState}`}>
                {possibleVideoValue[currentTextIndex]}
              </span>
            </h1>
            <p className="hp-sub-header">
              Process your videos to deliver insights across all industries. Ask questions, request summaries, analyses, and more with <b>Bump AI</b>.
            </p>
            <div className="hp-cta-buttons">
              <button className="hp-cta-button email-button" onClick={handleSignUp}>
                Start free with email
              </button>
              <button className="hp-google-button" onClick={handleGoogleSignUp}>
                <div className="hp-google-logo-container">
                  <img
                    src={google_big_logo}
                    alt="Google logo"
                    className="hp-google-logo-left"
                  />
                </div>
                <span className="hp-google-signup-text">Sign up with Google</span>
              </button>
            </div>
          </div>
          <div className="hp-topfold-media-container">
            {/* Autoplaying Video */}
            <video
              className="hp-topfold-video"
              src="https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2Ftrim-demo-bumpups.mp4?alt=media&token=631fc865-d851-403d-8812-5137138b348f"
              autoPlay
              muted
              loop
              playsInline
              poster={bumpups_preview_video}
            >
              {/* Your browser does not support the video tag. */}
            </video>
          </div>
        </div>

        {/* Other sections */}
        <ExampleVideoCarousel />
        {/* Prompt Workspace Section */}
        <div className="hp-prompt-workspace-wrapper">
          <h2 className="podcast-use-case-title">Bumpups Workspaces</h2>
          <p className="hp-prompt-workspace-subtitle">
            Explore our unique workspace designed to help you interact with video content and extract insights effortlessly.
          </p>
          <img 
            src={workspace_topfold_branding} 
            alt="Prompt Workspace" 
            className="hp-prompt-workspace-image" 
          />
        </div>
        <PodcastUseCase />

        {/* Social Profile Carousel Section */}
        <SocialProfileCarousel /> 
        <FAQComponent />
        <CookieBar />

        <Footer />
      </div>
    </AuthProvider>
  );
}

export default HomePage;
