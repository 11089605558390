import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import './VideoValuePage.css';

// FIREBASE
import { doc, onSnapshot, collection, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';

// FUNCTIONS
import AppTransition from '../../components/appComponents/AppTransition';
import axios from 'axios';
import he from 'he';
import { getYoutubeURLUnAuthCaptions, getYoutubeCaptionsPython } from '../myBumpUp/myCreator/getVideoCaptions';

// IMPORT UI
import ProcessingPage from '../../unAuthPages/ProcessingPage';
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';

// IMAGES
import placeholder_color_thumbnail from '../../assets/images/thumbnail/placeholder-color-thumbnail.jpg';
import copy_icon from '../../assets/images/icons/copy-icon.png';
import checkmark_icon from '../../assets/images/icons/checkmark-icon.png';
import star_icon from '../../assets/images/icons/star-icon.png';
import clock_icon from '../../assets/images/icons/clock-icon.png';
import timestamp_icon from '../../assets/images/icons/timestamp-icon.png';
import video_tag_icon from '../../assets/images/icons/video-tag-icon.png';
import help_icon from '../../assets/images/icons/help-icon.png';
import setting_icon from '../../assets/images/icons/setting-icon.png';
import reload_white_icon from '../../assets/images/icons/reload-white-icon.png';
import reload_dark_icon from '../../assets/images/icons/reload-dark-icon.png';
import processlogo from '../../assets/gifmedia/bumpups-2.gif';
import lang_world_icon from '../../assets/images/icons/lang-world-icon.png';

const VideoValuePage = ({ isSidebarOpen, toggleSidebar }) => {
  const [videoId, setVideoId] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [pathType, setPathType] = useState('url'); // 'url' or 'root'
  const navigate = useNavigate();
  const location = useLocation();
  const origin = location.state?.from || '/creator'; // Default to '/creator' if no state
  const currentUrl = window.location.href; // Capture the current URL

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    let videoIdFromUrl;
    let determinedPathType;

    if (pathname.includes('id-')) {
      videoIdFromUrl = pathname.split('id-').pop();
      determinedPathType = 'url';
    } else {
      videoIdFromUrl = pathname.split('/').pop();
      determinedPathType = 'root';
    }

    // console.log("Extracted video ID:", videoIdFromUrl); // Log the video ID
    setVideoId(videoIdFromUrl);
    setPathType(determinedPathType);

    const checkVideoExistence = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/dashboard');
          return;
        }

        const userDocRef = doc(db, 'users', user.uid);
        const collectionName = determinedPathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
        const docPrefix = determinedPathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const videoDocRef = doc(userDocRef, collectionName, `${docPrefix}${videoIdFromUrl}`);
        const videoDoc = await getDoc(videoDocRef);

        if (!videoDoc.exists()) {
          // console.log('Video document does not exist, redirecting...');
          navigate('/dashboard');
        } else {
          setIsVideoLoading(false);
        }
      } catch (error) {
        // console.error('Error checking video existence:', error);
        navigate('/dashboard');
      }
    };

    checkVideoExistence();
  }, [navigate]);

  if (isVideoLoading) {
    return (
      <div>
        <ProcessingPage />
      </div>
    );
  }

  return (
    <div className="dashboard-videoid">
      {isSidebarOpen && <Sidebar />}
      {!isSidebarOpen && <CloseSidebar />}
      <AppTransition>
        <div className="dashboard-main-videoid">
          <div className="dashboard-content-wrapper-videoid">
            <div className="dashboard-left-videoid">
              <DoneVideoDetails videoId={videoId} origin={origin} pathType={pathType} />
              <VideoTimestamps videoId={videoId} pathType={pathType} />
            </div>
            <div className="dashboard-center-videoid">
              <VideoTitles videoId={videoId} pathType={pathType} />
              <VideoSummary videoId={videoId} pathType={pathType} />
              <KeyTakeaways videoId={videoId} pathType={pathType} />
            </div>
            <div className="dashboard-right-videoid">
              <ReloadData videoId={videoId} currentUrl={currentUrl} pathType={pathType} />
              <VideoStats videoId={videoId} pathType={pathType} />
              <VideoTags videoId={videoId} pathType={pathType} />
              <VideoSuggestions videoId={videoId} />
            </div>
          </div>
        </div>
      </AppTransition>
    </div>
  );
};

const ReloadData = ({ videoId, currentUrl, pathType }) => {
  const [progress, setProgress] = useState(0);
  const [showReload, setShowReload] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [isDebounced, setIsDebounced] = useState(false);
  const user = auth.currentUser;

  useEffect(() => {
    if (user && user.uid) {
      const debounceKey = `${user.uid}-${videoId}-debounce`;
      const debounceTime = sessionStorage.getItem(debounceKey);

      if (debounceTime && new Date().getTime() < parseInt(debounceTime, 10)) {
        setIsDebounced(true);
      }

      const userDocRef = doc(db, 'users', user.uid);
      const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
      const docPrefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
      const videoDocRef = doc(userDocRef, collectionName, `${docPrefix}${videoId}`);

      const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const videoData = docSnapshot.data();
          const { video_title, video_thumbnail, video_duration_in_seconds, summary, takeaways, hashtags, timestamps, titles } = videoData;

          if (video_title && video_thumbnail && video_duration_in_seconds && summary && takeaways && hashtags && timestamps && titles) {
            setShowReload(false);
          } else {
            setShowReload(true);
          }
        } else {
          setShowReload(true);
        }
      });

      return () => unsubscribe();
    }
  }, [videoId, user, pathType]);

  const extractYoutubeVideoID = (url) => {
    const match = url.match(/\/video\/id-([a-zA-Z0-9_-]+)/);
    if (match) return match[1];
    const defaultMatch = url.match(/\/video\/([a-zA-Z0-9_-]+)/);
    return defaultMatch ? defaultMatch[1] : null;
  };

  const convertDurationToSeconds = (duration) => {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    if (!match) return 0;
    const hours = parseInt(match[1], 10) || 0;
    const minutes = parseInt(match[2], 10) || 0;
    const seconds = parseInt(match[3], 10) || 0;
    return hours * 3600 + minutes * 60 + seconds;
  };

  const fetchYoutubeVideoDetails = async (url) => {
    const extractedVideoId = extractYoutubeVideoID(url);
    if (!extractedVideoId) return null;

    const apiYoutubeKey = process.env.REACT_APP_YOUTUBE_API_KEY;
    try {
      const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
        params: {
          part: 'snippet,contentDetails',
          id: extractedVideoId,
          key: apiYoutubeKey,
        },
      });

      if (response.data.items.length > 0) {
        const { snippet, contentDetails } = response.data.items[0];
        const durationSeconds = convertDurationToSeconds(contentDetails.duration);
        const thumbnailUrl = snippet.thumbnails.maxres?.url || snippet.thumbnails.standard?.url || snippet.thumbnails.high?.url;

        return {
          videoId: extractedVideoId,
          duration: contentDetails.duration,
          durationInSeconds: durationSeconds,
          thumbnailUrl,
          title: snippet.title,
        };
      } else {
        // console.error('No video details found in response');
      }
    } catch (error) {
      // console.error('Error fetching YouTube video details:', error);
    }
    return null;
  };

  const incrementProgress = (currentProgress, targetProgress) => {
    if (currentProgress < targetProgress) {
      const increment = Math.min(5, targetProgress - currentProgress);
      setProgress((prevProgress) => prevProgress + increment);

      const nextIncrement = Math.max(1000, (60 * 1000) / ((100 - currentProgress) / 5));
      setTimeout(() => {
        incrementProgress(currentProgress + increment, targetProgress);
      }, nextIncrement);
    }
  };

  const handleReload = async () => {
    if (!user) return; // Ensure user exists
    const debounceKey = `${user.uid}-${videoId}-debounce`;
    setIsDebounced(true);
    sessionStorage.setItem(debounceKey, new Date().getTime() + 60000);
  
    setProgress(10);
    setLoadingComplete(false);
  
    try {
      const videoDetails = await fetchYoutubeVideoDetails(currentUrl);
  
      if (!videoDetails) {
        setProgress(0);
        setIsDebounced(false);
        sessionStorage.removeItem(debounceKey);
        return;
      }
  
      const { videoId: extractedVideoId, durationInSeconds, thumbnailUrl, title } = videoDetails;
      const isURLFormat = currentUrl.includes('/id-');
      const isReprocess = true;
  
      const contentId = `youtubeUrl-${extractedVideoId}`; // Added contentId
  
      setProgress(20);
      setLoadingComplete(true);
  
      let backendResponse;
  
      if (isURLFormat) {
        const fullUrl = `https://youtu.be/${extractedVideoId}`;
        const isPublic = true;
  
        backendResponse = await getYoutubeURLUnAuthCaptions({
          videoId: extractedVideoId,
          durationInSeconds,
          thumbnailUrl,
          title,
          isReprocess,
          fullUrl,
          isPublic,
          contentId, // Pass contentId here
        });
      } else {
        backendResponse = await getYoutubeCaptionsPython({
          videoId: extractedVideoId,
          durationInSeconds,
          thumbnailUrl,
          title,
          isReprocess,
          contentId, // Pass contentId here as well
        });
      }
  
      // Check for specific error flags
      if (backendResponse.is503Error || backendResponse.isInternalError) {
        setProgress(0);
        setLoadingComplete(false);
        setIsDebounced(false);
        sessionStorage.removeItem(debounceKey);
      } else {
        setProgress(50);
        incrementProgress(50, 100);
  
        const checkDataPointsFilled = () => {
          const userDocRef = doc(db, 'users', user.uid);
          const collectionName = isURLFormat ? 'youtubeUrlContent' : 'youtubeContent';
          const videoDocRef = doc(userDocRef, collectionName, contentId); // Use contentId here
  
          const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const videoData = docSnapshot.data();
              const {
                video_title,
                video_thumbnail,
                video_duration_in_seconds,
                summary,
                takeaways,
                hashtags,
                timestamps,
                titles,
              } = videoData;
  
              if (
                video_title &&
                video_thumbnail &&
                video_duration_in_seconds &&
                summary &&
                takeaways &&
                hashtags &&
                timestamps &&
                titles
              ) {
                setProgress(100);
                setLoadingComplete(true);
                unsubscribe();
              }
            }
          });
        };
  
        checkDataPointsFilled();
      }
    } catch (error) {
      setProgress(0);
      setLoadingComplete(false);
      setIsDebounced(false);
      sessionStorage.removeItem(debounceKey);
    }
  };
  

  return (
    <div className={`reload-data ${showReload ? '' : 'hidden'}`}>
      <LoadingBar color="#4add80" progress={progress} />
      <div className="reload-data-container">
        <button
          className="reload-data-button"
          onClick={handleReload}
          disabled={isDebounced}
          style={{
            opacity: isDebounced ? 0.8 : 1,
            cursor: isDebounced ? 'not-allowed' : 'pointer'
          }}
        >
          <img src={loadingComplete ? processlogo : reload_dark_icon} alt="Reload Logo" className="reload-logo" />
          {loadingComplete ? 'Processing...' : 'Reprocess'}
        </button>
        <div className="help-icon-container-reload-videoid">
          <img src={help_icon} alt="Help Icon" className="help-icon-rload-videoid" />
          <div className="help-tooltip-reprocess-videoid">
            Use the reprocess button to reprocess your video for <b>FREE</b> in case of failed outputs or if you're viewing a legacy processed video.
            <a href="https://intercom.help/bumpups/en/articles/9416266-does-reprocessing-my-video-deduct-minutes" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
          </div>
        </div>
      </div>
    </div>
  );
};

const DoneVideoDetails = ({ videoId, origin, pathType }) => {
  const [videoTitle, setVideoTitle] = useState('');
  const [videoThumbnail, setVideoThumbnail] = useState(placeholder_color_thumbnail);
  const [videoDuration, setVideoDuration] = useState('');
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const storageKeyTitle = `${user.uid} ${prefix}${videoId}-title`;
        const storageKeyThumbnail = `${user.uid} ${prefix}${videoId}-thumbnail`;
        const storageKeyDuration = `${user.uid} ${prefix}${videoId}-duration`;

        const storedTitle = localStorage.getItem(storageKeyTitle);
        const storedThumbnail = localStorage.getItem(storageKeyThumbnail);
        const storedDuration = localStorage.getItem(storageKeyDuration);

        const userDocRef = doc(db, 'users', user.uid);
        const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
        const videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);

        if (storedTitle && storedThumbnail && storedDuration) {
          setVideoTitle(storedTitle);
          setVideoThumbnail(storedThumbnail);
          setVideoDuration(formatDuration(storedDuration));
        } else {
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const fetchedTitle = videoData.video_title || '';
            const fetchedThumbnail = videoData.video_thumbnail || placeholder_color_thumbnail;
            const fetchedDuration = videoData.video_duration_in_seconds || 0;

            setVideoTitle(fetchedTitle);
            setVideoThumbnail(fetchedThumbnail);
            setVideoDuration(formatDuration(fetchedDuration));

            localStorage.setItem(storageKeyTitle, fetchedTitle);
            localStorage.setItem(storageKeyThumbnail, fetchedThumbnail);
            localStorage.setItem(storageKeyDuration, fetchedDuration);
          }
        }

        // Add snapshot listener
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTitle = videoData.video_title || '';
            const newThumbnail = videoData.video_thumbnail || placeholder_color_thumbnail;
            const newDuration = videoData.video_duration_in_seconds || 0;

            setVideoTitle(newTitle);
            setVideoThumbnail(newThumbnail);
            setVideoDuration(formatDuration(newDuration));

            localStorage.setItem(storageKeyTitle, newTitle);
            localStorage.setItem(storageKeyThumbnail, newThumbnail);
            localStorage.setItem(storageKeyDuration, newDuration);
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching video details:', error);
      }
    };

    fetchVideoDetails();
  }, [videoId, pathType]);

  const handleLearnMoreClick = () => {
    window.open(`https://youtu.be/${videoId}`, '_blank');
  };

  const formatDuration = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return [
      h > 0 ? `${h}:${m < 10 ? '0' : ''}` : '',
      `${m}:${s < 10 ? '0' : ''}${s}`,
    ].filter(Boolean).join('');
  };

  const originLink = pathname.includes('id-') ? '/links' : origin;

  const renderTitle = (title) => {
    // Decode the title to handle special characters
    const decodedTitle = he.decode(title);

    // Shorten the title if it's longer than 92 characters, appending '...' if shortened
    return decodedTitle.length > 92 ? `${decodedTitle.substring(0, 92)}...` : decodedTitle;
  };

  return (
    <div className="done-videodetails-container">
      <div className="done-videodetails-header">
        <Link to={originLink} className="done-videodetails-catalog-button">
          &larr; Your Catalog
        </Link>
        <span className="done-videodetails-duration-badge">{videoDuration}</span>
      </div>
      <div className="done-videodetails-thumbnail-container">
        <img src={videoThumbnail} alt="Video Thumbnail" className="done-videodetails-thumbnail" />
      </div>
      <div className="done-videodetails-content">
        <h2 className="done-videodetails-title">{renderTitle(videoTitle)}</h2>
        <div className="done-videodetails-script">Video link</div>
        <div className="done-videodetails-action">
          <button className="done-videodetails-action-btn" onClick={handleLearnMoreClick}>
            {`https://youtu.be/${videoId}`}
          </button>
        </div>
      </div>
    </div>
  );
};

const VideoStats = ({ videoId, pathType }) => {
  const [videoStats, setVideoStats] = useState(null);
  const [initialVideoStats, setInitialVideoStats] = useState(null);

  useEffect(() => {
    const fetchVideoStats = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const userDocRef = doc(db, 'users', user.uid);
        const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
        const valueDataCollectionRef = collection(userDocRef, collectionName);

        const unsubscribe = onSnapshot(valueDataCollectionRef, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
            const docVideoId = doc.id.startsWith(prefix) ? doc.id.substring(prefix.length) : doc.id;
            if (docVideoId === videoId) {
              setVideoStats({
                viewCount: doc.data().curr_view_count,
                likeCount: doc.data().curr_like_count,
                commentCount: doc.data().curr_comment_count,
              });

              setInitialVideoStats({
                viewCount: doc.data().init_view_count,
                likeCount: doc.data().init_like_count,
                commentCount: doc.data().init_comment_count,
              });
            }
          });
        });

        return () => unsubscribe();
      } catch (error) {
        // console.error('Error fetching video stats:', error);
      }
    };

    fetchVideoStats();
  }, [videoId, pathType]);

  const formatStatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num;
    }
  };

  if (!videoStats || !initialVideoStats) {
    return <div>Loading...</div>;
  }

  return (
    <div className="video-stats-container-videoid">
      <h3 className="video-stat-title-videoid">Video Stats</h3>
      <div className={`video-stats-videoid ${videoStats.viewCount != null && videoStats.likeCount != null && videoStats.commentCount != null ? '' : 'dimmed'}`}>
        <div className="video-stat-videoid">
          <div className="stat-icon-videoid">Views</div>
          <div className={`stat-box-videoid ${videoStats.viewCount != null && videoStats.viewCount !== initialVideoStats.viewCount ? 'number' : 'default'}`}>
            {videoStats.viewCount != null
              ? <>
                  {formatStatNumber(videoStats.viewCount)}
                  {videoStats.viewCount > initialVideoStats.viewCount && (
                    <span className="increase-videoid">+{formatStatNumber(videoStats.viewCount - initialVideoStats.viewCount)}</span>
                  )}
                </>
              : '--'}
          </div>
        </div>
        <div className="video-stat-videoid">
          <div className="stat-icon-videoid">Likes</div>
          <div className={`stat-box-videoid ${videoStats.likeCount != null && videoStats.likeCount !== initialVideoStats.likeCount ? 'number' : 'default'}`}>
            {videoStats.likeCount != null
              ? <>
                  {formatStatNumber(videoStats.likeCount)}
                  {videoStats.likeCount > initialVideoStats.likeCount && (
                    <span className="increase-videoid">+{formatStatNumber(videoStats.likeCount - initialVideoStats.likeCount)}</span>
                  )}
                </>
              : '--'}
          </div>
        </div>
        <div className="video-stat-videoid">
          <div className="stat-icon-videoid">Comments</div>
          <div className={`stat-box-videoid ${videoStats.commentCount != null && videoStats.commentCount !== initialVideoStats.commentCount ? 'number' : 'default'}`}>
            {videoStats.commentCount != null
              ? <>
                  {formatStatNumber(videoStats.commentCount)}
                  {videoStats.commentCount > initialVideoStats.commentCount && (
                    <span className="increase-videoid">+{formatStatNumber(videoStats.commentCount - initialVideoStats.commentCount)}</span>
                  )}
                </>
              : '--'}
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoTitles = ({ videoId, pathType }) => {
  const [titles, setTitles] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const storageTitleKey = `${user.uid} ${prefix}${videoId}-titles`;
        const storedTitles = localStorage.getItem(storageTitleKey);

        let videoDocRef;
        if (storedTitles) {
          setTitles(JSON.parse(storedTitles));
        } else {
          const userDocRef = doc(db, 'users', user.uid);
          const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
          videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const titlesArray = videoData.titles || [];
            setTitles(titlesArray);
            localStorage.setItem(storageTitleKey, JSON.stringify(titlesArray));
          }
        }

        // Add snapshot listener
        if (!videoDocRef) {
          const userDocRef = doc(db, 'users', user.uid);
          const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
          videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);
        }
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTitles = videoData.titles || [];
            setTitles(newTitles);
            localStorage.setItem(storageTitleKey, JSON.stringify(newTitles));
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching titles:', error);
      }
    };

    fetchTitles();
  }, [videoId, pathType]);

  const copyTitlesToClipboard = () => {
    const titlesString = titles.join('\n');
    navigator.clipboard.writeText(titlesString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="key-takeaways-box-videoid">
      <div className="key-takeaways-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="key-takeaways-title-videoid">Video Titles</h3>
          <div className="help-icon-container-videoid">
            <img src={help_icon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              These video titles are optimized to enhance your video's visibility and engagement.
              <a href="https://intercom.help/bumpups/en/articles/9542805-how-do-optimized-video-titles-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <button className="copy-summary-btn-videoid" onClick={copyTitlesToClipboard}>
          <div className="tooltip-videoid">
            <img src={copied ? checkmark_icon : copy_icon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
            <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
          </div>
        </button>
      </div>
      <div className="key-takeaways-content-videoid">
        {titles.length > 0 ? (
          titles.map((title, index) => (
            <div key={index} className="key-takeaway-videoid">
              <span className="key-takeaway-text-videoid">{title}</span>
            </div>
          ))
        ) : (
          <div className="video-summary-content-videoid">
            <img src={reload_white_icon} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};

const VideoSummary = ({ videoId, pathType }) => {
  const [summary, setSummary] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const storageSummaryKey = `${user.uid} ${prefix}${videoId}-summary`;
        const storedSummary = localStorage.getItem(storageSummaryKey);

        if (storedSummary) {
          setSummary(storedSummary);
        } else {
          const userDocRef = doc(db, 'users', user.uid);
          const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
          const videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const fetchedSummary = videoData.summary || "";
            setSummary(fetchedSummary);
            localStorage.setItem(storageSummaryKey, fetchedSummary);
          }
        }

        // Add snapshot listener
        const userDocRef = doc(db, 'users', user.uid);
        const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
        const videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newSummary = videoData.summary || "";
            setSummary(newSummary);
            localStorage.setItem(storageSummaryKey, newSummary);
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching summary:', error);
      }
    };

    fetchSummary();
  }, [videoId, pathType]);

  const copySummaryToClipboard = () => {
    navigator.clipboard.writeText(summary);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="video-summary-box-videoid">
      <div className="video-summary-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="video-summary-title-videoid">Video Description</h3>
          <div className="help-icon-container-videoid">
            <img src={help_icon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              We have written an optimized description to help viewers find your videos more easily through search, by including keywords at the beginning of the overview.
              <a href="https://intercom.help/bumpups/en/articles/9381182-how-does-a-video-description-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <div className="action-buttons-videoid">
          <button className="copy-summary-btn-videoid" onClick={copySummaryToClipboard}>
            <div className="tooltip-videoid">
              <img src={copied ? checkmark_icon : copy_icon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
              <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
            </div>
          </button>
        </div>
      </div>
      <div className="video-summary-content-videoid">
        {summary ? (
          <p className="video-summary-videoid">{summary}</p>
        ) : (
          <img src={reload_white_icon} alt="Reload Logo" className="reload-logo-videoid" />
        )}
      </div>
    </div>
  );
};

const KeyTakeaways = ({ videoId, pathType }) => {
  const [takeaways, setTakeaways] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchTakeaways = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const storageTakeawayKey = `${user.uid} ${prefix}${videoId}-takeaways`;
        const storedTakeaways = localStorage.getItem(storageTakeawayKey);

        let videoDocRef;
        if (storedTakeaways) {
          setTakeaways(JSON.parse(storedTakeaways));
        } else {
          const userDocRef = doc(db, 'users', user.uid);
          const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
          videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const takeawaysArray = videoData.takeaways || [];
            setTakeaways(takeawaysArray);
            localStorage.setItem(storageTakeawayKey, JSON.stringify(takeawaysArray));
          }
        }

        // Add snapshot listener
        if (!videoDocRef) {
          const userDocRef = doc(db, 'users', user.uid);
          const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
          videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);
        }
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTakeaways = videoData.takeaways || [];
            setTakeaways(newTakeaways);
            localStorage.setItem(storageTakeawayKey, JSON.stringify(newTakeaways));
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching takeaways:', error);
      }
    };

    fetchTakeaways();
  }, [videoId, pathType]);

  const copyTakeawaysToClipboard = () => {
    const takeawaysString = takeaways.join('\n');
    navigator.clipboard.writeText(takeawaysString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const parseTakeaway = (takeaway) => {
    const emojiMatch = takeaway.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic}|\p{Emoji_Modifier_Base}|\p{Emoji_Component})+/gu);
    if (emojiMatch) {
      const emoji = emojiMatch[0];
      const text = takeaway.replace(emoji, '').trim();
      return { emoji, text };
    }
    return { emoji: '', text: takeaway };
  };

  return (
    <div className="key-takeaways-box-videoid">
      <div className="key-takeaways-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="key-takeaways-title-videoid">Key Takeaways</h3>
          <div className="help-icon-container-videoid">
            <img src={help_icon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              These key takeaways enhance your video description with relevant keywords, increasing visibility in Google, Bing, and other search engines.
              <a href="https://intercom.help/bumpups/en/articles/9381198-how-do-key-takeaways-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <button className="copy-summary-btn-videoid" onClick={copyTakeawaysToClipboard}>
          <div className="tooltip-videoid">
            <img src={copied ? checkmark_icon : copy_icon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
            <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
          </div>
        </button>
      </div>
      <div className="key-takeaways-content-videoid">
        {takeaways.length > 0 ? (
          takeaways.map((takeaway, index) => {
            const { emoji, text } = parseTakeaway(takeaway);
            return (
              <div key={index} className="key-takeaway-videoid">
                <span className="key-takeaway-emoji-videoid">{emoji}</span>
                <span className="key-takeaway-text-videoid">{text}</span>
              </div>
            );
          })
        ) : (
          <div className="video-summary-content-videoid">
            <img src={reload_white_icon} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};

const VideoTags = ({ videoId, pathType }) => {
  const [tags, setTags] = useState([]);
  const [copied, setCopied] = useState(false);
  const [highlightedTags, setHighlightedTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const storageTagKey = `${user.uid} ${prefix}${videoId}-tags`;
        const storedTags = localStorage.getItem(storageTagKey);

        const userDocRef = doc(db, 'users', user.uid);
        const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
        const videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);

        if (storedTags) {
          setTags(storedTags.split(', '));
        } else {
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const tagsArray = videoData.hashtags || [];
            setTags(tagsArray);
            localStorage.setItem(storageTagKey, tagsArray.join(', '));
          }
        }

        // Add snapshot listener
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTags = videoData.hashtags || [];
            setTags(newTags);
            localStorage.setItem(storageTagKey, newTags.join(', '));
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [videoId, pathType]);

  useEffect(() => {
    // Highlight the first 5 unique tags
    const highlightIndices = Array.from({ length: Math.min(5, tags.length) }, (_, i) => i);
    setHighlightedTags(highlightIndices);
  }, [tags]);

  const copyTagsToClipboard = () => {
    const tagsString = tags.join(', ');
    navigator.clipboard.writeText(tagsString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="video-tags-box">
      <div className="video-tags-header">
        <div className="video-summary-title-container-videoid">
          <h3 className="video-tags-title">Video Tags</h3>
          <div className="help-icon-container-videoid">
            <img src={help_icon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              We created hashtags to make your videos more discoverable by associating them with specific topics, trends, or communities, helping attract viewers in your brand's target audience.
              <a href="https://intercom.help/bumpups/en/articles/9381190-how-do-video-tags-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <div className="copy-section">
          <div className="tooltip-videoid">
            <img src={star_icon} alt="Best Tags" className="star-icon-left" />
            <span className="tooltiptext-left">Best Tags</span>
          </div>
          <button className="copy-summary-btn-videoid" onClick={copyTagsToClipboard}>
            <div className="tooltip-videoid">
              <img src={copied ? checkmark_icon : copy_icon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
              <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
            </div>
          </button>
        </div>
      </div>
      <div className="video-tags-content">
        {tags.length > 0 ? (
          tags.map((tag, index) => (
            <div key={index} className={`video-tag ${highlightedTags.includes(index) ? 'highlighted' : ''}`}>
              {highlightedTags.includes(index) && <img src={star_icon} alt="Star" className="star-icon" />}
              {tag}
            </div>
          ))
        ) : (
          <div className="video-tags-content-videoid">
            <img src={reload_white_icon} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};

const VideoTimestamps = ({ videoId, pathType }) => {
  const [timestamps, setTimestamps] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchTimestamps = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const prefix = pathType === 'url' ? 'youtubeUrl-' : 'youtube-';
        const storageTimestampKey = `${user.uid} ${prefix}${videoId}-timestamps`;
        const storedTimestamps = localStorage.getItem(storageTimestampKey);

        const userDocRef = doc(db, 'users', user.uid);
        const collectionName = pathType === 'url' ? 'youtubeUrlContent' : 'youtubeContent';
        const videoDocRef = doc(userDocRef, collectionName, `${prefix}${videoId}`);

        if (storedTimestamps) {
          setTimestamps(storedTimestamps.split('\n'));
        } else {
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const timestampsString = videoData.timestamps || '';
            const timestampsArray = timestampsString ? timestampsString.split('\n') : [];
            setTimestamps(timestampsArray);
            localStorage.setItem(storageTimestampKey, timestampsString);
          }
        }

        // Add snapshot listener
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTimestampsString = videoData.timestamps || '';
            const newTimestamps = newTimestampsString ? newTimestampsString.split('\n') : [];
            setTimestamps(newTimestamps);
            localStorage.setItem(storageTimestampKey, newTimestampsString);
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching timestamps:', error);
      }
    };

    fetchTimestamps();
  }, [videoId, pathType]);

  const copyTimestampsToClipboard = () => {
    const timestampsString = timestamps.join('\n');
    navigator.clipboard.writeText(timestampsString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const formatTimeToSeconds = (time) => {
    const parts = time.split(':');
    let seconds = 0;
    if (parts.length === 3) {
      seconds = (+parts[0]) * 3600 + (+parts[1]) * 60 + (+parts[2]);
    } else if (parts.length === 2) {
      seconds = (+parts[0]) * 60 + (+parts[1]);
    }
    return seconds;
  };

  return (
    <div className="video-timestamps-box-videoid">
      <div className="video-timestamps-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="video-timestamps-title-videoid">Video Timestamps</h3>
          <div className="help-icon-container-videoid">
            <img src={help_icon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              Break your video into sections with individual previews, adding context to each part and letting you rewatch easily. Optimizing timestamps can enhance SEO by making your content more discoverable.
              <a href="https://intercom.help/bumpups/en/articles/9377422-how-do-video-timestamps-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <div className="icon-buttons-videoid">
          <Link to="/settings/creator" className="branding-btn-videoid">
            <div className="tooltip-videoid">
              <img src={setting_icon} alt="Branding" className="branding-icon-videoid" />
              <span className="tooltiptext-videoid">Branding</span>
            </div>
          </Link>
          <button className="copy-summary-btn-videoid" onClick={copyTimestampsToClipboard}>
            <div className="tooltip-videoid">
              <img src={copied ? checkmark_icon : copy_icon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
              <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
            </div>
          </button>
        </div>
      </div>
      <div className="video-timestamps-content-videoid">
        {timestamps.length > 0 ? (
          timestamps.map((timestamp, index) => {
            const [time, description] = timestamp.split(' - ');
            const seconds = formatTimeToSeconds(time);
            const youtubeLink = `https://youtu.be/${videoId}?t=${seconds}`;
            return (
              <div key={index} className="video-timestamp-videoid">
                <a href={youtubeLink} target="_blank" rel="noopener noreferrer" className="video-timestamp-number-videoid">
                  {time} -
                </a>
                <span className="video-timestamp-description-videoid">{description}</span>
              </div>
            );
          })
        ) : (
          <div className="video-timestamps-content-videoid">
            <img src={reload_white_icon} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};

const VideoSuggestions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 4; // Total pages

  // Updated content for different pages with associated links
  const pageContents = [
    {
      title: "Choose Your Language",
      description: "Set your preferred language for video processing outputs like timestamps and descriptions.",
      link: "/settings", // Link to the settings page
      image: lang_world_icon // Replace with the actual icon for language if needed
    },
    {
      title: "What timestamps do",
      description: "Break your videos into sections with timestamps, improving viewer navigation and boosting SEO.",
      link: "https://intercom.help/bumpups/en/articles/9377422-how-do-video-timestamps-help-you",
      image: timestamp_icon
    },
    {
      title: "Using targeted tags",
      description: "Make your videos more discoverable with targeted hashtags, attracting the right audience.",
      link: "https://intercom.help/bumpups/en/articles/9381190-how-do-video-tags-help-you",
      image: video_tag_icon
    },
    {
      title: "Your processing time",
      description: "Processing time on Bumpups is deducted per video, with beneficial rounding up.",
      link: "https://intercom.help/bumpups/en/articles/8858577-how-are-credits-deducted",
      image: clock_icon
    },
  ];

  const nextPage = () => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const openLink = () => {
    const { link } = pageContents[currentPage - 1];
    if (link.startsWith('http')) {
      window.open(link, '_blank'); // Opens external links in a new tab
    } else {
      window.location.href = link; // Navigates to internal links
    }
  };

  // Get the content for the current page
  const { title, description, image } = pageContents[currentPage - 1];

  return (
    <div className="video-suggestions-container">
      <div className="video-suggestions-card">
        <div className="video-suggestions-content">
          <h3>Suggestions for you</h3>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="video-suggestions-icon">
          <img src={image} alt={title} /> {/* Use dynamic image and alt text */}
        </div>
      </div>
      <div className="video-suggestions-actions">
        <button
          className="video-suggestions-start-btn"
          onClick={openLink}
        >
          {currentPage === 1 ? 'CHANGE LANGUAGE' : 'READ NOW'} {/* Conditionally render button text */}
        </button>
        <div className="video-suggestions-pagination">
          <span className="pagination-arrowbox" onClick={prevPage}>&lt;</span>
          {`${currentPage}/${totalPages}`}
          <span className="pagination-arrowbox" onClick={nextPage}>&gt;</span>
        </div>
      </div>
    </div>
  );
};

export default VideoValuePage;
