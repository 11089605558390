import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./Footer.css";

// IMAGES
import twitLogo from "../assets/images/logo/x-white-logo.png";
import linkedinLogo from "../assets/images/logo/linkedin-white-logo.png";
import bumpups_logo from '../assets/images/default/bumpups-logo-final.png';


const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollToTopAndNavigate = () => {
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  return (
    <footer className="footer-container">
      <div className="footer-branding">
        <Link to="/" className="footer-brand" onClick={scrollToTopAndNavigate}>
        <img src={bumpups_logo} alt="Bumpups Logo" className="footer-logo" />
        </Link>
        <div className="footer-brand-description">
          <span className="footer-timestamp-highlight">
            #1 AI VIDEO PROCESSOR
          </span>{" "}
          Bump AI will watch any video and deliver insights across all
          industries in just one click.
          <div className="footer-social-media-icons">
            <a
              href="https://www.linkedin.com/company/bumpups"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinLogo} alt="LinkedIn" />
            </a>
            <a
              href="https://twitter.com/bumpups"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitLogo} alt="Twitter" />
            </a>
          </div>
        </div>
      </div>

      {/* Desktop Footer Sections */}
      {!isMobile && (
        <>
          <div className="footer-section">
            <h4>Product</h4>
            <ul className="footer-links">
              <li>
                <Link
                  to="/pricing"
                  className="footer-contact-link"
                  onClick={scrollToTopAndNavigate}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <a
                  href="https://bumpups.canny.io/changelog"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-contact-link footer-external-link"
                >
                  Product Changelog
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Features</h4>
            <ul className="footer-links">
              <li>
                <Link
                  to="/workspace-feature"
                  className="footer-contact-link"
                  onClick={scrollToTopAndNavigate}
                >
                  Workspaces
                </Link>
              </li>
              <li>
                <Link
                  to="/creator-feature"
                  className="footer-contact-link"
                  onClick={scrollToTopAndNavigate}
                >
                  Creator Studio
                </Link>
              </li>
              <li>
                <Link
                  to="/youtube-feature"
                  className="footer-contact-link"
                  onClick={scrollToTopAndNavigate}
                >
                  YouTube Videos
                </Link>
              </li>
              <li>
                <Link
                  to="/local-feature"
                  className="footer-contact-link"
                  onClick={scrollToTopAndNavigate}
                >
                  Local Videos
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Resources</h4>
            <ul className="footer-links">
              <li>
                <a
                  href="https://bumpups.canny.io/feature-requests"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-contact-link footer-external-link"
                >
                  Feature Request
                </a>
              </li>
              <li>
                <a
                  href="https://intercom.help/bumpups/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-contact-link footer-external-link"
                >
                  Help Center
                </a>
              </li>
            </ul>
          </div>
        </>
      )}

      {/* Mobile Footer Section */}
      {isMobile && (
        <div className="footer-section footer-mobile">
          <h4>Resources</h4>
          <ul className="footer-links">
            <li>
              <Link
                to="/pricing"
                className="footer-contact-link"
                onClick={scrollToTopAndNavigate}
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/workspace-feature"
                className="footer-contact-link"
                onClick={scrollToTopAndNavigate}
              >
                Workspaces
              </Link>
            </li>
            <li>
              <Link
                to="/creator-feature"
                className="footer-contact-link"
                onClick={scrollToTopAndNavigate}
              >
                Creator Studio
              </Link>
            </li>
            <li>
              <Link
                to="/youtube-feature"
                className="footer-contact-link"
                onClick={scrollToTopAndNavigate}
              >
                YouTube Videos
              </Link>
            </li>
            <li>
              <Link
                to="/local-feature"
                className="footer-contact-link"
                onClick={scrollToTopAndNavigate}
              >
                Local Videos
              </Link>
            </li>
            <li>
              <a
                href="https://bumpups.canny.io/feature-requests"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-contact-link footer-external-link"
              >
                Feature Request
              </a>
            </li>
            <li>
              <a
                href="https://intercom.help/bumpups/en/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-contact-link footer-external-link"
              >
                Help Center
              </a>
            </li>
            <li>
              <a
                href="https://bumpups.canny.io/changelog"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-contact-link footer-external-link"
              >
                Product Changelog
              </a>
            </li>
          </ul>
        </div>
      )}

      <div className="footer-bottom">
        ©2025 Bumpups - All rights reserved.
        <Link
          to="/terms-of-service"
          className="footer-contact-link"
          onClick={scrollToTopAndNavigate}
        >
          Terms of Service
        </Link>
        <Link
          to="/privacy-policy"
          className="footer-contact-link"
          onClick={scrollToTopAndNavigate}
        >
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
