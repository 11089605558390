import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './descriptionTemplate.css';

const DescriptionTemplate = () => {
  // State for YouTube description template
  const [description, setDescription] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [descriptionSaved, setDescriptionSaved] = useState(false);
  const textareaRef = useRef(null);

  // Load description from localStorage or Firestore
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const localStorageDescription = localStorage.getItem(`userTemplateDescription_${user.uid}`);
      if (localStorageDescription) {
        setDescription(localStorageDescription);
      } else {
        const fetchDescription = async () => {
          const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.userTemplateDescription) {
              setDescription(userData.userTemplateDescription);
              localStorage.setItem(`userTemplateDescription_${user.uid}`, userData.userTemplateDescription);
            }
          }
        };
        fetchDescription();
      }
    }
  }, []);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const saveDescriptionTemplate = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const debounceKey = `${user.uid}_description_debounce`;
    const lastSaveTimestamp = localStorage.getItem(debounceKey);
    const currentTime = Date.now();

    if (lastSaveTimestamp && currentTime - lastSaveTimestamp < 5000) {
      return;
    }

    setIsSaving(true);
    localStorage.setItem(debounceKey, currentTime.toString());

    try {
      const userDocRef = doc(db, 'users', user.uid);
      const profileRef = doc(userDocRef, 'userSettings', 'profile');
      await setDoc(profileRef, { userTemplateDescription: description }, { merge: true });
      setDescriptionSaved(true);
      localStorage.setItem(`userTemplateDescription_${user.uid}`, description);
      setTimeout(() => {
        setDescriptionSaved(false);
      }, 3000);
    } catch (error) {
      console.error('Error saving template:', error);
    }

    setTimeout(() => {
      setIsSaving(false);
      localStorage.removeItem(debounceKey);
    }, 5000);
  };

  const handleDescriptionPaste = (text) => {
    const textarea = textareaRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const beforeValue = description.substring(0, startPos);
    const afterValue = description.substring(endPos);
    setDescription(`${beforeValue}${text}${afterValue}`);
    setTimeout(() => {
      textarea.setSelectionRange(startPos + text.length, startPos + text.length);
      textarea.focus();
    }, 0);
  };

  return (
    <>
      {/* Global header */}
      <div className="setting-header">Upload</div>

      {/* Main container for settings */}
      <div className="account-settings-container">
        {/* YouTube Description Section */}
        <div className="settings-row">
          <div className="settings-label">YouTube Description
          <div className="settings-subtitle">
            This is a template for new video uploads.
            </div>
          </div>

          <div className="settings-field">
            <button
              className="ui-setting-button"
              onClick={saveDescriptionTemplate}
              disabled={!description.trim() || isSaving}
            >
              {descriptionSaved ? 'Template Saved' : 'Save'}
            </button>
          </div>
        </div>
        
        <div className="description-wrapper">
          <textarea
            ref={textareaRef}
            className="description-textarea"
            placeholder="Use placeholders like {{VIDEO_DESCRIPTION}} to automatically insert the video description generated by Bump AI."
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>
        
        <div className="description-button-group">
          <button className="ui-setting-button" onClick={() => handleDescriptionPaste('{{VIDEO_DESCRIPTION}}')}>
            Description
          </button>
          <button className="ui-setting-button" onClick={() => handleDescriptionPaste('{{VIDEO_TAKEAWAYS}}')}>
            Key Takeaways
          </button>
          <button className="ui-setting-button" onClick={() => handleDescriptionPaste('{{VIDEO_TIMESTAMPS}}')}>
            Timestamps
          </button>
          <button className="ui-setting-button" onClick={() => handleDescriptionPaste('{{VIDEO_HASHTAGS}}')}>
            Hashtags
          </button>
        </div>
      </div>
    </>
  );
};

export default DescriptionTemplate;
