import React, { useState, useEffect, useRef } from 'react';
import './exampleVideoCarousel.css';

import education_thumbnail from '../../assets/images/thumbnail/education-thumbnail.jpg';
import school_thumbnail from '../../assets/images/thumbnail/school-thumbnail.jpg';
import speech_thumbnail from '../../assets/images/thumbnail/speech-thumbnail.jpg';
import podcast_thumbnail from '../../assets/images/thumbnail/podcast-thumbnail.jpg';
import youtuber_thumbnail from '../../assets/images/thumbnail/youtuber-thumbnail.jpg';
import legal_thumbnail from '../../assets/images/thumbnail/legal-thumbnail.jpg';
import llama_surfing_thumbnail from '../../assets/images/thumbnail/llama-bump-thumbnail.jpg';


import study_guide_branding from '../../assets/images/branding/study-guide-branding.jpg';
import podcast_summary_branding from '../../assets/images/branding/podcast-summary-branding.jpg';
import capbabilities_branding from '../../assets/images/branding/capbabilities-branding.jpg';
import legal_lecture_branding from '../../assets/images/branding/legal-lecture-branding.jpg';
import top_insights_entrepreneur from '../../assets/images/branding/top-insights-entrepreneur.jpg';
import mrBeast_challenges_summary_branding from '../../assets/images/branding/mrBeast-challenges-summary-branding.jpg';
import tedtalk_summary_thumbnail from '../../assets/images/branding/tedtalk-summary-thumbnail.jpg';


const carouselItems = [
  { title: 'Podcast', image: podcast_thumbnail, overlay: podcast_summary_branding },
  { title: 'Any Topic', image: llama_surfing_thumbnail, overlay: capbabilities_branding },
  { title: 'Education', image: school_thumbnail, overlay: study_guide_branding },
  { title: 'YouTuber', image: youtuber_thumbnail, overlay: mrBeast_challenges_summary_branding },
  { title: 'Presentation', image: education_thumbnail, overlay: tedtalk_summary_thumbnail },
  { title: 'Legal', image: legal_thumbnail, overlay: legal_lecture_branding },
  { title: 'Business', image: speech_thumbnail, overlay: top_insights_entrepreneur },
];

const ExampleVideoCarousel = () => {
  const [itemsToShow, setItemsToShow] = useState(3);
  const totalItems = carouselItems.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const handleNext = () => {
    if (currentIndex < totalItems - itemsToShow) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transform = `translateX(-${currentIndex * (100 / itemsToShow)}%)`;
    }
    setIsPrevDisabled(currentIndex === 0);
    setIsNextDisabled(currentIndex >= totalItems - itemsToShow);
  }, [currentIndex, itemsToShow, totalItems]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) setItemsToShow(1);
      else if (window.innerWidth < 992) setItemsToShow(2);
      else setItemsToShow(3);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="ex-carousel-container">
      <div className="ex-carousel-title">
        WHAT VIDEO WILL YOU <span className="ex-highlight">PROCESS</span> TODAY?
      </div>
      <div className="ex-carousel">
        <button
          className="ex-arrow ex-left-arrow"
          onClick={handlePrev}
          disabled={isPrevDisabled}
          aria-label="Previous"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill="#ffffff" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </svg>
        </button>
        <div className="ex-carousel-inner">
        <div className="ex-carousel-items" ref={itemsRef}>
            {carouselItems.map((item, index) => (
              <div
                className="ex-carousel-item"
                key={index}
                style={{ flex: `0 0 calc(100% / ${itemsToShow})` }}
              >
                {/* Title at the Top */}
                <p className="ex-item-title">{item.title}</p>

                {/* Main Image */}
                <img src={item.image} alt={item.title} className="ex-item-image" />

                {/* Overlay Image Below the Main Image */}
                {item.overlay && (
                  <div className="ex-overlay-image-container">
                    <img src={item.overlay} alt={`${item.title} overlay`} className="ex-overlay-image" />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="ex-carousel-gradient ex-carousel-gradient-left"></div>
          <div className="ex-carousel-gradient ex-carousel-gradient-right"></div>
        </div>
        <button
          className="ex-arrow ex-right-arrow"
          onClick={handleNext}
          disabled={isNextDisabled}
          aria-label="Next"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path fill="#ffffff" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ExampleVideoCarousel;
