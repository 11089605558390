import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Navbar.css';

// FIREBASE
import { auth, db } from '../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

// FUNCTIONS
import { AuthContext } from '../AuthContext';
import { unregisterAllListeners } from '../components/appComponents/ListenerManager';

// IMPORT UI
import UserPlanPopup from '../myPlans/UserPlanPopup';
import UserPaidPopup from '../myPlans/UserPaidPopup';

// IMAGES
import bumpups_logo from '../assets/images/default/bumpups-logo-final.png';
import crown_plan_icon from '../assets/images/icons/crown-plan-icon.png';
import logout_icon from '../assets/images/icons/logout-icon.png';
import placeholder_profile from '../assets/images/profile/placeholder-profile.jpg';
import setting_icon from '../assets/images/icons/setting-icon.png';
import baseplan_white_up_arrow_icon from '../assets/images/icons/baseplan-white-up-arrow-icon.png';
import bumpups_white_small_logo from '../assets/images/logo/bumpups-white-small-logo.png';

function Navbar({ isSidebarOpen, toggleSidebar }) {
  // UI states
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  // Navigation and Auth
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const user = auth.currentUser;

  // Subscription and profile states
  const [timeBank, setTimeBank] = useState(0);
  const [timeBankBump, setTimeBankBump] = useState(0);
  const [currentPlan, setCurrentPlan] = useState(
    sessionStorage.getItem(`currentPlan_${user?.uid}`) || "Free Plan"
  );
  const [isActive, setIsActive] = useState(
    sessionStorage.getItem(`isActive_${user?.uid}`) ? JSON.parse(sessionStorage.getItem(`isActive_${user?.uid}`)) : false
  );
  const [profileImage, setProfileImage] = useState();
  const [showPlanPopUp, setShowPlanPopUp] = useState(false);

  const handleLogout = async () => {
    try {
      unregisterAllListeners();
      await auth.signOut();
      navigate('/');
    } catch (error) {
      // Handle error if needed
    }
  };

  // Click-outside logic for profile menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showProfileMenu && !event.target.closest('.profile-dropdown') && !event.target.closest('.profile-icon')) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showProfileMenu]);

  // Window resize listener
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Firestore subscriptions and data fetching (for logged in users)
  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const subscriptionDocRef = doc(userDocRef, 'subscriptionData', 'timeWarden');
      const planDetailsRef = doc(userDocRef, "subscriptionData", "planDetails");
      const profileDocRef = doc(userDocRef, 'userSettings', 'profile');

      const unsubscribeTime = onSnapshot(subscriptionDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const newTimeBank = docSnap.data().timeBank || 0;
          const newTimeBankBump = docSnap.data().timeBankBump || 0;

          const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
          const sessionTimeBankBump = sessionStorage.getItem(`timeBankBump_${user.uid}`);

          if (!sessionTimeBank || parseInt(sessionTimeBank, 10) !== newTimeBank) {
            setTimeBank(newTimeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, newTimeBank.toString());
          }

          if (!sessionTimeBankBump || parseInt(sessionTimeBankBump, 10) !== newTimeBankBump) {
            setTimeBankBump(newTimeBankBump);
            sessionStorage.setItem(`timeBankBump_${user.uid}`, newTimeBankBump.toString());
          }
        }
      });

      const unsubscribePlan = onSnapshot(planDetailsRef, (docSnap) => {
        if (docSnap.exists()) {
          const newPlanData = docSnap.data();
          const newPlan = newPlanData.currentPlan || 'Free Plan';
          const newActiveStatus = newPlanData.IsActive !== undefined ? newPlanData.IsActive : false;

          setCurrentPlan(newPlan);
          setIsActive(newActiveStatus);
          sessionStorage.setItem(`currentPlan_${user.uid}`, newPlan);
          sessionStorage.setItem(`isActive_${user.uid}`, JSON.stringify(newActiveStatus));
        }
      });

      const unsubscribeProfileImage = onSnapshot(profileDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const profileImageUrl = docSnap.data().profileImage || '';
          setProfileImage(profileImageUrl);
          sessionStorage.setItem(`profileImage_${user.uid}`, profileImageUrl);
        }
      });

      const fetchData = async () => {
        // Fetch time bank and plan details from sessionStorage or Firestore
        const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
        const sessionTimeBankBump = sessionStorage.getItem(`timeBankBump_${user.uid}`);
        const sessionCurrentPlan = sessionStorage.getItem(`currentPlan_${user.uid}`);
        const sessionIsActive = sessionStorage.getItem(`isActive_${user.uid}`);

        if (sessionTimeBank !== null) {
          setTimeBank(parseInt(sessionTimeBank, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBank = docSnap.data().timeBank || 0;
            setTimeBank(timeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, timeBank.toString());
          }
        }

        if (sessionTimeBankBump !== null) {
          setTimeBankBump(parseInt(sessionTimeBankBump, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBankBump = docSnap.data().timeBankBump || 0;
            setTimeBankBump(timeBankBump);
            sessionStorage.setItem(`timeBankBump_${user.uid}`, timeBankBump.toString());
          }
        }

        if (sessionCurrentPlan !== null && sessionIsActive !== null) {
          setCurrentPlan(sessionCurrentPlan);
          setIsActive(JSON.parse(sessionIsActive));
        } else {
          const planSnap = await getDoc(planDetailsRef);
          if (planSnap.exists()) {
            const planData = planSnap.data();
            const plan = planData.currentPlan || 'Free Plan';
            const activeStatus = planData.IsActive !== undefined ? planData.IsActive : false;

            setCurrentPlan(plan);
            setIsActive(activeStatus);
            sessionStorage.setItem(`currentPlan_${user.uid}`, plan);
            sessionStorage.setItem(`isActive_${user.uid}`, JSON.stringify(activeStatus));
          }
        }

        // Profile image fetch
        const profileSnap = await getDoc(profileDocRef);
        if (profileSnap.exists()) {
          const profileImageUrl = profileSnap.data().profileImage || '';
          setProfileImage(profileImageUrl);
          sessionStorage.setItem(`profileImage_${user.uid}`, profileImageUrl);
        }
      };

      fetchData();

      return () => {
        unsubscribeTime();
        unsubscribePlan();
        unsubscribeProfileImage();
      };
    }
  }, [currentUser, user]);

  // Utility: convert minutes into "Xh Xm" format
  const formatTime = (minutes) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}h ${m}m`;
  };

  // Since this Navbar is only for logged in users, we always use the "navbar" style.
  const navbarClass = 'navbar';

  return (
    <nav className={navbarClass}>
      <div className="brand-and-toggle-container">
        <button onClick={toggleSidebar} className="sidebar-toggle" title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}>
          {isSidebarOpen ? '✖' : '☰'}
        </button>
        <Link to="/dashboard" className="navbar-brand">
        <Link to="/dashboard" className="navbar-brand">
            <img src={bumpups_logo} alt="Bumpups Logo" className="navbar-logo" />
          </Link>
        </Link>
      </div>
      <div className="navbar-links">
        {windowWidth > 1280 && (
          <>
            <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="time-container">
                <img src={baseplan_white_up_arrow_icon} alt=">" className="planNavIcon info-icon" />
                <span className="base-time-text">{formatTime(timeBank)}</span>
                <div className="navbar-tooltip-text">Base credits</div>
              </div>
            </Link>
            <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="time-container">
                <img src={bumpups_white_small_logo} alt=">" className="planNavIcon info-icon" />
                <span className="bump-time-text">{formatTime(timeBankBump)}</span>
                <div className="navbar-tooltip-text">Bump credits</div>
              </div>
            </Link>
            <button className="add-credits-button" onClick={() => setShowPlanPopUp(true)}>
              Add more credits
            </button>
          </>
        )}
        {showPlanPopUp && (
          (currentPlan === "base" || currentPlan === "Essential Plan" || currentPlan === "bump") && isActive ?
            <UserPaidPopup 
              isOpen={showPlanPopUp} 
              onClose={() => setShowPlanPopUp(false)} 
              currentPlan={currentPlan}
            /> :
            <UserPlanPopup 
              isOpen={showPlanPopUp} 
              onClose={() => setShowPlanPopUp(false)} 
              currentPlan={currentPlan}
            />
        )}
        <img 
          src={profileImage || placeholder_profile} 
          alt="Profile" 
          className="profile-icon" 
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        />
        {showProfileMenu && (
          <div className="profile-dropdown active">
            <div className="nav-button" style={{ textAlign: 'left' }} onClick={() => setShowProfileMenu(false)}>
              <Link to="/plans" style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={crown_plan_icon} alt="Subscription Icon" className="iconmenu" />
                My plan
              </Link>
            </div>
            <div className="nav-button" style={{ textAlign: 'left' }} onClick={() => setShowProfileMenu(false)}>
              <Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={setting_icon} alt="Settings Icon" className="iconmenu" />
                Settings & Beta
              </Link>
            </div>
            <div className="mainnav-divider"></div>
            <div className="nav-button" style={{ textAlign: 'left' }} onClick={() => { handleLogout(); setShowProfileMenu(false); }}>
              <span style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={logout_icon} alt="Logout Icon" className="iconmenu" />
                Log out
              </span>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
