import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { debounce } from 'lodash';

import './peferenceSetting.css';

const PreferenceSetting = () => {
  const [isShortTimestamps, setIsShortTimestamps] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);

      const localStorageUserData = localStorage.getItem(`userData_${user.uid}`);
      if (localStorageUserData) {
        const userData = JSON.parse(localStorageUserData);
        setIsShortTimestamps(
          userData.isShortTimestamps !== undefined ? userData.isShortTimestamps : false
        );
      }

      const timestampProfileRef = doc(userDocRef, 'valueSettings', 'timestamps');
      const unsubscribeTimestamps = onSnapshot(timestampProfileRef, (docSnap) => {
        if (docSnap.exists()) {
          const { isShortTimestamps } = docSnap.data();
          setIsShortTimestamps(isShortTimestamps || false);
        }
      });

      return () => {
        unsubscribeTimestamps();
      };
    }
  }, []);

  const debouncedTimestampHandleToggle = debounce(async (isShort) => {
    setIsShortTimestamps(isShort);
    const user = auth.currentUser;
    if (user) {
      const timestampSettingsRef = doc(db, 'users', user.uid, 'valueSettings', 'timestamps');
      try {
        await updateDoc(timestampSettingsRef, { isShortTimestamps: isShort });
        const userData = JSON.parse(sessionStorage.getItem(`userData_${user.uid}`)) || {};
        userData.isShortTimestamps = isShort;
        sessionStorage.setItem(`userData_${user.uid}`, JSON.stringify(userData));
      } catch (error) {
        console.error(error);
      }
    }
  }, 500);

  const handleLengthOption = (value) => {
    const isShort = value === 'short';
    debouncedTimestampHandleToggle(isShort);
  };

  return (
    <>
      {/* Header outside the container */}
      <div className="setting-header">AI Preferences</div>
      
      {/* Main container for AI Preferences */}
      <div className="account-settings-container">
        <div className="settings-row">
          <div className="settings-label">
            <span>Timestamp Length</span>
            <div className="settings-subtitle">
            Short timestamps offer a concise overview for lengthy content.
            </div>
          </div>
          <div className="settings-field">
            <button
              value="short"
              className={`timestamp-button short ${isShortTimestamps ? 'selected' : ''}`}
              onClick={() => handleLengthOption('short')}
            >
              Short
            </button>
            <button
              value="standard"
              className={`timestamp-button standard ${!isShortTimestamps ? 'selected' : ''}`}
              onClick={() => handleLengthOption('standard')}
            >
              Standard
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferenceSetting;
