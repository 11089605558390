// shortCutTab.js

import React from 'react';
import './promptsWorkspace.css';
import copy_icon from '../../assets/images/icons/copy-icon.png';
import checkmark_icon from '../../assets/images/icons/checkmark-icon.png';
import reload_white_icon from '../../assets/images/icons/reload-white-icon.png'; // Ensure the path is correct
import star_icon from '../../assets/images/icons/star-icon.png';

const ShortCutsTab = ({
  isScTimestampsEnabled,
  isScDescriptionEnabled,
  isScTakeawaysEnabled,
  isScTitlesEnabled,
  isScTagsEnabled,
  timestamps,
  summary,
  takeaways,
  titles,
  isScRun,
  hashtags,
  needsReload,
  isReloadDisabled,
  reloadCooldown,
  handleReload,
  handleCopyDetails,
  copiedDetail,
  highlightedTags,
}) => {
  
  // Helper function to parse takeaways for emojis
  const parseTakeaway = (takeaway) => {
    const emojiMatch = takeaway.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic}|\p{Emoji_Modifier_Base}|\p{Emoji_Component})+/gu);
    if (emojiMatch) {
      const emoji = emojiMatch[0];
      const text = takeaway.replace(emoji, '').trim();
      return { emoji, text };
    }
    return { emoji: '', text: takeaway };
  };

  // Helper functions to check data existence
  const hasTimestamps = typeof timestamps === 'string' && timestamps.trim() !== '';
  const hasDescription = summary && summary.trim() !== '';
  const hasTakeaways = Array.isArray(takeaways) && takeaways.length > 0;
  const hasTitles = Array.isArray(titles) && titles.length > 0;
  const hasHashtags = Array.isArray(hashtags) && hashtags.length > 0;

  return (
    <div className="shortcutoutput-details-container">
      {/* Reload Button */}
      {isScRun && needsReload && (
        <div className="ws-reload-button-container">
          <button
            className="ws-shortcut-reload-button"
            onClick={handleReload}
            aria-label="Reload Data"
            disabled={isReloadDisabled}
          >
            <img src={reload_white_icon} alt="Reload" className="ws-shortcut-reload-icon" />
            {isReloadDisabled ? `Reloading...` : 'Reload'}
          </button>
        </div>
      )}

      {/* Timestamps Section */}
      {isScRun && isScTimestampsEnabled && (
        <div className="ws-shortcut-timestamps-box">
          <div className="ws-shortcut-timestamps-header">
            <div className="ws-shortcut-summary-title-container">
              <h3 className="ws-shortcut-timestamps-title">Timestamps</h3>
            </div>
            <div className="ws-shortcut-icon-buttons">
              <button
                className="ws-shortcut-copy-summary-btn"
                onClick={() => handleCopyDetails(timestamps, 'Timestamps')}
                aria-label="Copy Timestamps"
              >
                <img
                  src={copiedDetail === 'Timestamps' ? checkmark_icon : copy_icon}
                  alt={copiedDetail === 'Timestamps' ? 'Copied' : 'Copy'}
                  className="ws-shortcut-copy-icon"
                />
              </button>
            </div>
          </div>

          <div className="ws-shortcut-timestamps-content">
            {hasTimestamps ? (
              timestamps.split('\n').map((timestamp, index) => {
                const [time, description] = timestamp.split(' - ');
                return (
                  <div key={index} className="ws-shortcut-timestamp">
                    <span className="ws-shortcut-timestamp-number">{time} -</span>
                    <span className="ws-shortcut-timestamp-description">{description}</span>
                  </div>
                );
              })
            ) : (
              <div className="ws-shortcut-summary-content">
                <img src={reload_white_icon} alt="Reload Logo" className="ws-shortcut-reload-logo" />
              </div>
            )}
          </div>
        </div>
      )}
  
      {/* Description Section */}
      {isScRun && isScDescriptionEnabled && (
        <div className="ws-shortcut-summary-box">
          <div className="ws-shortcut-summary-header">
            <div className="ws-shortcut-summary-title-container">
              <h3 className="ws-shortcut-summary-title">Description</h3>
            </div>
            <div className="ws-shortcut-action-buttons">
              <button
                className="ws-shortcut-copy-summary-btn"
                onClick={() => handleCopyDetails(summary, 'Description')}
                aria-label="Copy Description"
              >
                <img
                  src={copiedDetail === 'Description' ? checkmark_icon : copy_icon}
                  alt={copiedDetail === 'Description' ? 'Copied' : 'Copy'}
                  className="ws-shortcut-copy-icon"
                />
              </button>
            </div>
          </div>

          <div className="ws-shortcut-summary-content">
            {hasDescription ? (
              <span className="ws-shortcut-summary">{summary}</span>
            ) : (
              <img src={reload_white_icon} alt="Reload Logo" className="ws-shortcut-reload-logo" />
            )}
          </div>
        </div>
      )}
  
      {/* Takeaways Section */}
      {isScRun && isScTakeawaysEnabled && (
        <div className="ws-shortcut-takeaways-box">
          <div className="ws-shortcut-takeaways-header">
            <div className="ws-shortcut-summary-title-container">
              <h3 className="ws-shortcut-takeaways-title">Takeaways</h3>
            </div>
            <button
              className="ws-shortcut-copy-summary-btn"
              onClick={() => handleCopyDetails(takeaways.join('\n'), 'Takeaways')}
              aria-label="Copy Takeaways"
            >
              <img
                src={copiedDetail === 'Takeaways' ? checkmark_icon : copy_icon}
                alt={copiedDetail === 'Takeaways' ? 'Copied' : 'Copy'}
                className="ws-shortcut-copy-icon"
              />
            </button>
          </div>

          <div className="ws-shortcut-takeaways-content">
            {hasTakeaways ? (
              takeaways.map((takeaway, index) => {
                const { emoji, text } = parseTakeaway(takeaway);
                return (
                  <div key={index} className="ws-shortcut-takeaway">
                    {emoji && <span className="ws-shortcut-takeaway-emoji">{emoji}</span>}
                    <span className="ws-shortcut-takeaway-text">{text}</span>
                  </div>
                );
              })
            ) : (
              <div className="ws-shortcut-summary-content">
                <img src={reload_white_icon} alt="Reload Logo" className="ws-shortcut-reload-logo" />
              </div>
            )}
          </div>
        </div>
      )}
  
      {/* Titles Section */}
      {isScRun && isScTitlesEnabled && (
        <div className="ws-shortcut-titles-box">
          <div className="ws-shortcut-titles-header">
            <div className="ws-shortcut-summary-title-container">
              <h3 className="ws-shortcut-titles-title">Titles</h3>
            </div>
            <button
              className="ws-shortcut-copy-summary-btn"
              onClick={() => handleCopyDetails(titles.join('\n'), 'Titles')}
              aria-label="Copy Titles"
            >
              <img
                src={copiedDetail === 'Titles' ? checkmark_icon : copy_icon}
                alt={copiedDetail === 'Titles' ? 'Copied' : 'Copy'}
                className="ws-shortcut-copy-icon"
              />
            </button>
          </div>

          <div className="ws-shortcut-titles-content">
            {hasTitles ? (
              titles.map((title, index) => (
                <div key={index} className="ws-shortcut-takeaway">
                  <span className="ws-shortcut-takeaway-text">{title}</span>
                </div>
              ))
            ) : (
              <div className="ws-shortcut-summary-content">
                <img src={reload_white_icon} alt="Reload Logo" className="ws-shortcut-reload-logo" />
              </div>
            )}
          </div>
        </div>
      )}
  
      {/* Hashtags Section */}
      {isScRun && isScTagsEnabled && (
        <div className="ws-shortcut-hashtags-box">
          <div className="ws-shortcut-hashtags-header">
            <div className="ws-shortcut-summary-title-container">
              <h3 className="ws-shortcut-hashtags-title">Hashtags</h3>
            </div>
            <div className="ws-shortcut-copy-section">
              <button
                className="ws-shortcut-copy-summary-btn"
                onClick={() => handleCopyDetails(hashtags.join('\n'), 'Hashtags')}
                aria-label="Copy Hashtags"
              >
                <img
                  src={copiedDetail === 'Hashtags' ? checkmark_icon : copy_icon}
                  alt={copiedDetail === 'Hashtags' ? 'Copied' : 'Copy'}
                  className="ws-shortcut-copy-icon"
                />
              </button>
            </div>
          </div>

          <div className="ws-shortcut-hashtags-content">
            {hasHashtags ? (
              hashtags.map((hashtag, index) => (
                <div
                  key={index}
                  className={`ws-shortcut-hashtag ${
                    highlightedTags.includes(index) ? 'highlighted' : ''
                  }`}
                >
                  {highlightedTags.includes(index) && (
                    <img src={star_icon} alt="Star" className="ws-shortcut-star-icon" />
                  )}
                  {hashtag}
                </div>
              ))
            ) : (
              <div className="ws-shortcut-summary-content">
                <img src={reload_white_icon} alt="Reload Logo" className="ws-shortcut-reload-logo" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShortCutsTab;
