import React from 'react';
import { Link } from 'react-router-dom';
import './InsufficientTimePopUp.css';

// FIREBASE
import { doc, updateDoc } from "firebase/firestore"; 
import { db, auth } from '../../firebase';

// IMAGES
import clock_icon from '../../assets/images/icons/clock-icon.png';

export const InsufficientTimePopUp = () => {

  const handleClose = async () => {
    // Update the insufficientTime boolean in Firestore
    const user = auth.currentUser;
    const userDocRef = doc(db, 'users', user.uid);
    const insufficientTimeDataRef = doc(userDocRef, 'subscriptionData', 'noTimeAvailable');
    await updateDoc(insufficientTimeDataRef, {
      insufficientTime: false
    });

    // Reload the page to close the popup
    window.location.reload();
  };

  const handleAddMoreTime = async () => {
    // Update the insufficientTime boolean in Firestore to false
    const user = auth.currentUser;
    const userDocRef = doc(db, 'users', user.uid);
    const insufficientTimeDataRef = doc(userDocRef, 'subscriptionData', 'noTimeAvailable');
    await updateDoc(insufficientTimeDataRef, {
      insufficientTime: false
    });

    // You may want to navigate to another page or update some other state here
  };

  return (
    <div className="overlay" onClick={handleClose}>
      <div className="plan-popup-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="outoftime-popup-container">
          <button className="close-btn" onClick={handleClose}>×</button>
          <h1 className="popup-title">
            <img src={clock_icon} alt="Clock" className="clock-logo" />
            Oops, Time's Up!
          </h1> 
          <p className="popup-subtitle">
            You've hit your video processing limit.<br /> 
            Want to add more credits?
          </p>
          <Link to="/plans" onClick={handleAddMoreTime}>
            <button className="add-more-time-btn">Add more credits</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InsufficientTimePopUp;
