// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';

// Context
import { AuthProvider } from './AuthContext';

// React Router
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Helmet for GA
import { Helmet } from 'react-helmet';

// Layouts
import UnauthLayout from './components/appComponents/UnauthLayout';
import AuthLayout from './components/appComponents/AuthLayout';

// Pages (Unauth)
import HomePage from './unAuthPages/HomePage';
import PricingPage from './unAuthPages/PricingPage';
import SignUpPage from './unAuthPages/SignUpPage';
import SignInPage from './unAuthPages/SignInPage';
import PrivacyPolicy from './unAuthPages/PrivacyPolicy';
import TermsOfService from './unAuthPages/TermsOfService';

import WorkspaceFeature from './unAuthPages/productPages/WorkspaceFeature';
import StudioFeature from './unAuthPages/productPages/StudioFeature';
import YouTubeFeature from './unAuthPages/productPages/YouTubeFeature';
import LocalFeature from './unAuthPages/productPages/LocalFeature';

// import StartupAPI from './unAuthPages/apiPages/StartupAPI';
// import EnterpriseAPI from './unAuthPages/apiPages/EnterpriseAPI';


// Pages (Auth)
import GeneralDashboard from './myDashboard/generalDashboard';
import WorkspaceDashboard from './myWorkspace/workspaceDashboard';
import NewWorkspace from './myWorkspace/workspaceComponents/NewWorkspace';
import ExistingWorkspace from './myWorkspace/workspaceComponents/ExistingWorkspace';
import BumpupDashboard from './myStudio/myBumpUp/bumperComponents/bumpupDashboard';
import ViewDoneBump from './myStudio/myBumpUp/doneBumpComponents/viewDoneBump';
import NonCreatorDashboard from './myStudio/myNonCreator/nonCreatorDashboard';
import VideoValuePage from './myStudio/myVideoPage/VideoValuePage';
import SubscriptionPage from './myPlans/SubscriptionPage';
import UserSettings from './mySettings/UserSettings';

// Components
import PrivateRoute from './components/appComponents/PrivateRoute';
import ScrollToTop from './components/appComponents/ScrollToTop';

import "@stripe/stripe-js";

function App() {
  // Sidebar state
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem('isSidebarOpen') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('isSidebarOpen', isSidebarOpen);
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => {
      const newState = !prev;
      localStorage.setItem('isSidebarOpen', newState);
      return newState;
    });
  };

  // Google Analytics ID (optional)
  const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Helmet>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            {GA4_MEASUREMENT_ID && [
              <script
                key="gtag-js"
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`}
              ></script>,
              <script key="gtag-init">
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA4_MEASUREMENT_ID}', {
                    send_page_view: false,
                  });
                `}
              </script>
            ]}
          </Helmet>

          <Routes>
            {/* Unauthenticated Layout & Routes */}
            <Route element={<UnauthLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />

              <Route path="/workspace-feature" element={<WorkspaceFeature />} />
              <Route path="/creator-feature" element={<StudioFeature />} />
              <Route path="/youtube-feature" element={<YouTubeFeature />} />
              <Route path="/local-feature" element={<LocalFeature />} />

              {/* <Route path="/startup" element={<StartupAPI />} />
              <Route path="/enterprise" element={<EnterpriseAPI />} /> */}

            </Route>

            {/* Authenticated Layout & Routes */}
            <Route element={<PrivateRoute><AuthLayout isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>}>
              <Route path="/dashboard" element={<GeneralDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/workspace" element={<WorkspaceDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/workspace/new" element={<NewWorkspace isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/workspace/:content_id" element={<ExistingWorkspace isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/bumper" element={<BumpupDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/bumper/:videoId" element={<ViewDoneBump isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/links" element={<NonCreatorDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/video/:videoId" element={<VideoValuePage isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/plans" element={<SubscriptionPage isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
              <Route path="/settings/*" element={<UserSettings isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />
            </Route>

            {/* Catch-all Redirect */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;