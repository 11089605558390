import React from 'react';
import { Link } from 'react-router-dom';
import './heroComponent.css';

function HeroComponent({
  title,
  subtitle,
  buttonText,
  arrowIcon,
  hoverImage,
}) {
  return (
    <section className="hero-section">
      <div className="hero-content-wrapper">
        {/* LEFT COLUMN: Title, Subtitle, Button + Wire */}
        <div className="hero-left-col">
          <h1 className="hero-title">{title}</h1>
          <p className="hero-subtitle">{subtitle}</p>
          <div className="button-wire-container">
            <Link to="/signup" className="hero-cta">
              {buttonText} <span className="arrow-icon">{arrowIcon}</span>
            </Link>
          </div>
        </div>
        {/* RIGHT COLUMN: Llama Surfing Image */}
        <div className="hero-right-col">
          <img
            src={hoverImage}
            alt="Llama Surfing"
            className="hero-hover-image"
          />
        </div>
      </div>
    </section>
  );
}

export default HeroComponent;
