// src/layouts/UnauthLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import UnAuthNavbar from '../../myNavbar/unAuthBar/UnAuthNavbar';

const UnauthLayout = () => {
  return (
    <>
      <UnAuthNavbar />
      <Outlet />
    </>
  );
};

export default UnauthLayout;
