import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../AuthContext'; // adjust the import path as needed

import bumpups_logo from '../../assets/images/default/bumpups-logo-final.png';
import workspace_icon from '../../assets/images/icons/comment-icon.png'; 
import creator_icon from '../../assets/images/icons/dashboard-icon.png';
import yt_icon from '../../assets/images/logo/youtube-white-logo.png';
import local_icon from '../../assets/images/icons/upload-small-icon.png';

import enterprise_icon from '../../assets/images/icons/lighting-icon.png';
import startup_icon from '../../assets/images/icons/hammer-icon.png';

import './unMobileNav.css';

const MobileNav = () => {
  const { currentUser } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const [apiDropdownOpen, setApiDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
    if (menuOpen) {
      setProductDropdownOpen(false);
      setApiDropdownOpen(false);
    }
  };

  // Closes the entire menu and any open dropdowns
  const closeMenu = () => {
    setMenuOpen(false);
    setProductDropdownOpen(false);
    setApiDropdownOpen(false);
  };

  const toggleProductDropdown = () => {
    setProductDropdownOpen((prev) => !prev);
  };

  const toggleAPIDropdown = () => {
    setApiDropdownOpen((prev) => !prev);
  };

  // Product dropdown items with their respective routes
  const productItems = [
    { title: 'Workspaces', icon: workspace_icon, route: '/workspace-feature' },
    { title: 'Creator Studio', icon: creator_icon, route: '/creator-feature' },
    { title: 'YouTube Videos', icon: yt_icon, route: '/youtube-feature' },
    { title: 'Local Videos', icon: local_icon, route: '/local-feature' },
  ];

  // API Solutions dropdown items (each now with an icon)
  const apiItems = [
    { title: 'Startups', icon: startup_icon },
    { title: 'Enterprise', icon: enterprise_icon },
  ];

  return (
    <div className="mobile-nav-container">
      <div className="mobile-nav-header">
        {/* Left: Logo */}
        <Link to="/" className="mobile-logo" onClick={closeMenu}>
          <img
            src={bumpups_logo}
            alt="Placeholder Logo"
            className="mobile-logo-img"
          />
        </Link>

        {/* Right side: Conditional button + Hamburger */}
        <div className="mobile-header-right">
          {currentUser ? (
            <Link to="/dashboard" className="mobile-signup-btn" onClick={closeMenu}>
              Dashboard
              <span className="mobile-arrow-container">
                <span className="mobile-arrow mobile-arrow-primary">&#8594;</span>
                <span className="mobile-arrow mobile-arrow-secondary">&#8594;</span>
              </span>
            </Link>
          ) : (
            <Link to="/signup" className="mobile-signup-btn" onClick={closeMenu}>
              Sign Up
              <span className="mobile-arrow-container">
                <span className="mobile-arrow mobile-arrow-primary">&#8594;</span>
                <span className="mobile-arrow mobile-arrow-secondary">&#8594;</span>
              </span>
            </Link>
          )}

          <button
            className="mobile-hamburger"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            {menuOpen ? (
              // "X" icon when open
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            ) : (
              // Hamburger icon when closed
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="3" y1="12" x2="21" y2="12" />
                <line x1="3" y1="6" x2="21" y2="6" />
                <line x1="3" y1="18" x2="21" y2="18" />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Slide-down menu */}
      <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
        <ul className="mobile-menu-list">
          <li className="mobile-menu-item" onClick={toggleProductDropdown}>
            Product ▾
            {productDropdownOpen && (
              <ul className="mobile-product-dropdown">
                {productItems.map((item, index) => (
                  <li
                    key={index}
                    className="mobile-product-dropdown-item"
                  >
                    <Link to={item.route} className="menu-link-mobile" onClick={closeMenu}>
                      <img src={item.icon} alt={`${item.title} icon`} />
                      <span>{item.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>

          {/* Uncomment and update below if API dropdown is needed */}
          {/*
          <li className="mobile-menu-item" onClick={toggleAPIDropdown}>
            API Solutions ▾
            {apiDropdownOpen && (
              <ul className="mobile-product-dropdown">
                {apiItems.map((item, index) => (
                  <li
                    key={index}
                    className="mobile-product-dropdown-item"
                    onClick={closeMenu}
                  >
                    <img src={item.icon} alt={`${item.title} icon`} />
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li className="mobile-menu-item" onClick={closeMenu}>
            Enterprise
          </li>
          */}

          <li className="mobile-menu-item">
            <Link to="/pricing" className="menu-link-mobile" onClick={closeMenu}>
              Pricing
            </Link>
          </li>
          {!currentUser && (
            <li className="mobile-menu-item">
              <Link to="/signin" className="menu-link-mobile" onClick={closeMenu}>
                Login
              </Link>
            </li>
          )}
        </ul>

        {/* Render the button section only for unauthenticated users */}
        {!currentUser && (
          <div className="mobile-menu-buttons">
            <Link to="/signup" className="mobile-signup-btn" onClick={closeMenu}>
              Start chatting — for free
              <span className="mobile-arrow-container">
                <span className="mobile-arrow mobile-arrow-primary">&#8594;</span>
                <span className="mobile-arrow mobile-arrow-secondary">&#8594;</span>
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNav;
