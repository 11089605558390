import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'; // adjust the import path as needed

// Dropdown components
import ProductDropdown from './dropdownComponent/ProductDropdown';
import ApiDropdown from './dropdownComponent/ApiDropdown';

// Logo (used for both desktop and mobile)
import bumpups_logo from '../../assets/images/default/bumpups-logo-final.png';

// Mobile navbar component
import UnMobileNav from './UnMobileNav';

// Desktop navbar CSS
import './unAuthNavbar.css';

const UnAuthNavbar = () => {
  const { currentUser } = useContext(AuthContext);

  // Check if the window width is less than 768px to switch to mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // If mobile, show mobile nav
  if (isMobile) {
    return <UnMobileNav />;
  }

  // Desktop Navbar
  return (
    <nav className="unauthn-navbar">
      <div className="unauthn-navbar-left">
        <Link to="/">
          <img
            src={bumpups_logo}
            alt="Placeholder Logo"
            className="unauthn-navbar-logo"
          />
        </Link>
      </div>

      <ul className="unauthn-navbar-links">
        <ProductDropdown />
        {/* Uncomment if needed */}
        {/* <ApiDropdown /> */}

        <li className="unauthn-navbar-link">
          <Link
            to="/pricing"
            className="unauthn-internal-link unauthn-underline-hover"
          >
            Pricing
          </Link>
        </li>
      </ul>

      {/* Right section changes based on whether currentUser exists */}
      {currentUser ? (
        <div className="unauthn-navbar-right">
          <div className="unauthn-navbar-divider"></div>
          <Link to="/dashboard" className="unauthn-signup-btn">
            Dashboard
            <span className="unauth-arrow-container">
              <span className="unauth-arrow unauth-arrow-primary">&#8594;</span>
              <span className="unauth-arrow unauth-arrow-secondary">&#8594;</span>
            </span>
          </Link>
        </div>
      ) : (
        <div className="unauthn-navbar-right">
          <div className="unauthn-navbar-divider"></div>
          <Link to="/signin" className="unauthn-login-link unauthn-underline-hover">
            Login
          </Link>
          <Link to="/signup" className="unauthn-signup-btn">
            Sign Up
            <span className="unauth-arrow-container">
              <span className="unauth-arrow unauth-arrow-primary">&#8594;</span>
              <span className="unauth-arrow unauth-arrow-secondary">&#8594;</span>
            </span>
          </Link>
        </div>
      )}
    </nav>
  );
};

export default UnAuthNavbar;
