// src/components/podcastUseCase.js
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './podcastUseCase.css';

// IMAGES
import saquon_barkley_thumbnail from '../../assets/images/thumbnail/barkley-thumbnail.jpeg';
import star_icon from '../../assets/images/icons/star-icon.png';

const videoTags = [
    "SaquonBarkley", "PhiladelphiaEagles", "NFL", "Football", "RunningBacks",
    "CollegeFootball", "PennState", "NFLDraft", "ProfessionalFootball",
    "Teamwork", "AthleticAchievements", "PlayerSafety"
];

const keyTakeaways = [
    {
        icon: '🏈',
        text: "Saquon Barkley's Transition: Reflects on joining the Philadelphia Eagles, embracing a new role, and contributing to team success"
    },
    {
        icon: '🏆',
        text: "NFL and College Reflections: Discusses college football challenges, professional transitions, and the evolving landscape of the sport"
    },
    {
        icon: '🏃‍♂️',
        text: "Running Back Insights: Explores the running back market, player versatility, and the physical demands of professional football"
    }
];

const timestamps = [
    { time: "14:32", description: " Meeting the team" },
    { time: "19:32", description: " College football advice" },
    { time: "26:30", description: " College camaraderie" },
    { time: "29:06", description: " Records and legacies" },
    { time: "45:10", description: " Rookie pranks" },
    { time: "47:19", description: " First NFL touchdown" },
    { time: "49:38", description: " Favorite rivalry moment" },
    { time: "53:59", description: " New York success" },
    { time: "56:30", description: " Major League Baseball season" },
    { time: "1:03:55", description: " Running back market" },
    { time: "1:06:28", description: " Changing the narrative" },
    { time: "1:22:59", description: " Drinking Contest Plans" },
    { time: "1:25:24", description: " Banning Hip Drop Tackles" }
];

const videoDescriptions = [
    "Join hosts Travis and Jason Kelce on New Heights as they welcome NFL star Saquon Barkley to discuss his transition to the Eagles, playing with Brandon Graham, and his excitement for the team's potential.",
    "Saquon Barkley shares his journey to the Eagles with the Kelce brothers. Hear about his new role, the warm reception in Philly, and his optimism for the team's future in this engaging episode of New Heights.",
    "On New Heights, Saquon Barkley talks with Travis and Jason Kelce about his move to the Eagles, his new teammates, and the support he's received. Enjoy a lively chat filled with insights and personal stories."
];

function PodcastUseCase() {
    const [tagIndices, setTagIndices] = useState([0, 1]);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [currentTakeawayIndex, setCurrentTakeawayIndex] = useState(0);
    const [currentTimestampIndex, setCurrentTimestampIndex] = useState(0);
    const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);

    // Update tags, takeaways, timestamps, and descriptions every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            const newIndices = new Set();

            // Ensure we have 2 unique indices for tags
            while (newIndices.size < 2) {
                const newIndex = Math.floor(Math.random() * videoTags.length);
                newIndices.add(newIndex);
            }

            const indicesArray = Array.from(newIndices);

            // Update states
            setTagIndices(indicesArray);
            setHighlightedIndex(Math.floor(Math.random() * 2));
            setCurrentTakeawayIndex((prevIndex) => (prevIndex + 1) % keyTakeaways.length);
            setCurrentTimestampIndex((prevIndex) => (prevIndex + 1) % timestamps.length);
            setCurrentDescriptionIndex((prevIndex) => (prevIndex + 1) % videoDescriptions.length);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const displayedTags = tagIndices.map(index => videoTags[index]);

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
    };



    return (
        <div className="podcast-use-case-container">
            {/* Title and Subtitle Section */}
            <div className="podcast-use-case-header">
                <h2 className="podcast-use-case-title">Case Study: Podcasters</h2>
                <div className="podcast-use-case-subtitle">
                Podcasters are just one of the many users who love our platform, using it to generate accurate timestamps, detailed descriptions, and more—all with <Link to="/signup" className="ex-highlight-click">a single click.</Link>
                </div>
            </div>

            {/* Existing hp-wrapper Content */}
            <div className="hp-wrapper">
                <div className="hp-image-section">
                    <img src={saquon_barkley_thumbnail} alt="Thumbnail" className="hp-homepage-image" />
                </div>

                <div className="hp-info-box hp-video-tags">
                    <p className="hp-video-tags-title">Video Hashtags</p>
                    <div className="video-tags-content hp-fade-in-out">
                        {displayedTags.map((tag, index) => (
                            <div key={index} className={`hp-video-tag ${index === highlightedIndex ? 'highlighted' : ''}`}>
                                {index === highlightedIndex && <img src={star_icon} alt="Star" className="star-icon" />}
                                {tag}
                            </div>
                        ))}
                        <div className="hp-video-tag">
                            + more
                        </div>
                    </div>
                </div>
                <div className="hp-info-box hp-key-takeaways">
                    <p className="hp-key-takeaway-title">Key Takeaways</p>
                    <div className="key-takeaway-content hp-fade-in-out">
                        <span className="key-takeaway-icon">{keyTakeaways[currentTakeawayIndex].icon}</span>
                        <span className="key-takeaway-text">{truncateText(keyTakeaways[currentTakeawayIndex].text, 80)}</span>
                    </div>
                </div>
                <div className="hp-info-box hp-description">
                    <p className="hp-description-title">Video Description</p>
                    <div className="hp-description-wrapper hp-fade-in-out">
                        <p className="hp-description-content">{truncateText(videoDescriptions[currentDescriptionIndex], 110)}</p>
                    </div>
                </div>
                <div className="hp-info-box hp-timestamps">
                    <p className="hp-timestamps-title">Timestamps</p>
                    <div className="hp-timestamps-content">
                        <div key={currentTimestampIndex} className="hp-timestamp-item hp-fade-in-out">
                            <span className="hp-timestamp-time">{timestamps[currentTimestampIndex].time}</span> -{" "}
                            <span className="hp-timestamp-description">{timestamps[currentTimestampIndex].description}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default PodcastUseCase;