import React, { useEffect, useState } from 'react';
import './MaintenancePage.css';

//FIREBASE
import { doc, onSnapshot } from "firebase/firestore"; 
import { db } from '../../firebase';

//FUNCTIONS
import { registerListener } from '../appComponents/ListenerManager';


const MaintenancePage = () => {
    const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);
    const [maintenanceMessage, setMaintenanceMessage] = useState('');
  
    useEffect(() => {
      const maintenanceStatusRef = doc(db, 'maintenanceStatus', 'status');
    
      const unsubscribe = onSnapshot(maintenanceStatusRef, (statusSnapshot) => {
        if (statusSnapshot.exists()) {
          setIsMaintenanceActive(statusSnapshot.data().isActive);
          setMaintenanceMessage(statusSnapshot.data().message);
        } else {
          setIsMaintenanceActive(false);
          setMaintenanceMessage('');
        }
      });
    
      registerListener(unsubscribe);  // Register the unsubscribe function
    
      return () => {
        unsubscribe();
        console.log("Successfully unsubscribed from maintenanceStatus listener");  // Log message confirming unsubscription
      };
    }, []);
  
    return (
        <div className="maintenance-container">
          {isMaintenanceActive && (
            <>
              <h1 className="maintenance-header">Server Maintenance ☕</h1>
              <p className="maintenance-text">{maintenanceMessage}</p>
              <p className="maintenance-subtitle">grab a coffee, we will be right back!</p>
              <div className="button-container">
                <a href="https://bumpups.zapier.app/get-in-touch" target="_blank" rel="noopener noreferrer" className="maintenance-button support-button">
                  Contact Support
                </a>
              </div>
            </>
          )}
        </div>
    );
  };

export default MaintenancePage;
