import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './CloseSidebar.css';

// IMAGES
import price_tag_icon from '../assets/images/icons/price-tag-icon.png';
import help_icon from '../assets/images/icons/help-icon.png';
import dashboard_icon from '../assets/images/icons/dashboard-icon.png';
import crown_plan_icon from '../assets/images/icons/crown-plan-icon.png';
import comment_icon from '../assets/images/icons/comment-icon.png';
import youtube_white_logo from '../assets/images/logo/youtube-white-logo.png';
import setting_icon from '../assets/images/icons/setting-icon.png';

function CloseSidebar() {
    const [currentView, setCurrentView] = useState('');

    useEffect(() => {
        const updateView = () => {
            setCurrentView(window.location.pathname);
        };

        window.addEventListener('popstate', updateView);
        updateView();
        return () => window.removeEventListener('popstate', updateView);
    }, []);

    // Generic helper function to check if the current path starts with the given route.
    const isCurrentView = (view) => currentView.startsWith(view);

    // Special check for the '/bumper' link: active if currentView starts with '/bumper', '/links' or '/video'
    const isBumperOrLinks = () =>
      currentView.startsWith('/bumper') ||
      currentView.startsWith('/links') ||
      currentView.startsWith('/video');

    const getLinkClass = (view) => {
        if (view === '/bumper') {
            return `close-sidebar-link ${isBumperOrLinks() ? 'close-link-selected' : ''}`;
        }
        return `close-sidebar-link ${isCurrentView(view) ? 'close-link-selected' : ''}`;
    };

    const getIconClass = (view) => {
        if (view === '/bumper') {
            return `close-link-icon ${isBumperOrLinks() ? 'close-link-icon-selected' : ''}`;
        }
        return `close-link-icon ${isCurrentView(view) ? 'close-link-icon-selected' : ''}`;
    };

    return (
        <div className="close-sidebar">
            <div className="close-create-wrapper">
                <Link 
                  to="/dashboard" 
                  className="close-create-button" 
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <img src={dashboard_icon} alt="Bump Up Logo" className="link-icon-bump-close" />
                </Link>
            </div>
            <div className="close-dividersides"></div>

            <Link to="/workspace" className={getLinkClass('/workspace')}>
                <img src={comment_icon} alt="Workspace" className={getIconClass('/workspace')} />
            </Link>

            <Link to="/bumper" className={getLinkClass('/bumper')}>
                <img src={youtube_white_logo} alt="Upload" className={getIconClass('/bumper')} />
            </Link>

            <a 
              href="https://intercom.help/bumpups/en/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className={getLinkClass('/help')}
            >
                <img 
                  src={isCurrentView('/help') ? price_tag_icon : help_icon} 
                  alt="Help" 
                  className={getIconClass('/help')} 
                />
            </a>

            <Link to="/plans" className={getLinkClass('/plans')}>
                <img src={crown_plan_icon} alt="Plans" className={getIconClass('/plans')} />
            </Link>

            <Link to="/settings" className={getLinkClass('/settings')}>
                <img src={setting_icon} alt="Settings" className={getIconClass('/settings')} />
            </Link>
        </div>
    );
}

export default CloseSidebar;
