import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './workflowComponent.css';

function WorkflowComponent({ headerData, row1Data, row2Data, row3Data }) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="main-div">
      <section className="two-col-alternating">
        {/* HEADER ROW */}
        <div className="header-row">
          <div className="header-col-left">
            <h1 className="header-title">{headerData.title}</h1>
          </div>
          <div className="header-col-right">
            <div className="header-right-inner">
              <p className="header-description">{headerData.description}</p>
            </div>
          </div>
        </div>

        {/* ROW 1: Video on left, Accordion on right */}
        <div className="row">
        <div className="left-col image-placeholder">
            <video
              autoPlay
              loop
              muted
              playsInline
              poster={row1Data.placeholder}  // Added fallback image as poster
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            >
              <source src={row1Data.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="right-col accordion-col">
            {row1Data.accordionItems.map((item, index) => {
              const isOpen = openIndex === index;
              return (
                <div
                  key={item.title}
                  className={`accordion-item ${isOpen ? 'open' : ''}`}
                  onClick={() => handleToggle(index)}
                >
                  <div className="accordion-header">
                    <h4 className="accordion-title">{item.title}</h4>
                    <div className="accordion-icon">{isOpen ? '×' : '+'}</div>
                  </div>
                  {isOpen && (
                    <div className="accordion-content">
                      <p>{item.content}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {/* ROW 2: Text on left, Image on right */}
        <div className="row">
          <div className="left-col text-col">
            <h2>{row2Data.text.title}</h2>
            <p>{row2Data.text.description}</p>
            <Link to={row2Data.text.ctaLink} className="inline-cta">
              {row2Data.text.ctaText}{' '}
              <span className="inline-cta-arrow">{row2Data.text.ctaArrow}</span>
            </Link>
          </div>
          <div className="right-col image-placeholder">
            <img
              src={row2Data.image}
              alt="Row 2"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        </div>

        {/* ROW 3: Image on left, Text on right */}
        <div className="row">
          <div className="left-col image-placeholder">
            <img
              src={row3Data.image}
              alt="Row 3"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          <div className="right-col text-col">
            <h2>{row3Data.text.title}</h2>
            <p>{row3Data.text.description}</p>
            <Link to={row3Data.text.ctaLink} className="inline-cta">
              {row3Data.text.ctaText}{' '}
              <span className="inline-cta-arrow">{row3Data.text.ctaArrow}</span>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WorkflowComponent;
