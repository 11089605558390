import React from 'react';
import { Link } from 'react-router-dom';
import './caseStudyComponent.css';

function CaseStudyComponent({ caseStudyData }) {
  const { leftImage, title, subtitle, buttonText, stats } = caseStudyData;
  
  return (
    <div className="case-study-container">
      <div className="case-study-card">
        {/* Left side: Image */}
        <div className="case-study-left">
          <img 
            src={leftImage} 
            alt="Case Study Visual" 
            className="case-study-img"
          />
        </div>

        {/* Right side: Text content */}
        <div className="case-study-right">
          <h2 className="cs-title">
            {title}
            <br />
            <span className="cs-subtitle">{subtitle}</span>
          </h2>

          <Link to="/signup" className="cs-button">
            {buttonText} <span className="cs-arrow">→</span>
          </Link>

          <div className="cs-stats">
            {stats.map((stat, index) => (
              <div className="stat" key={index}>
                <h1 className="metrics">{stat.metrics}</h1>
                <p>{stat.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyComponent;
