// ./homeComponent/socialprofileCarousel.js
import React from 'react';
import './socialprofileCarousel.css';

// Images
import youtube_white_logo from '../../assets/images/logo/youtube-white-logo.png';
import aquinas_yt from '../../assets/images/YT/quin-yt.jpg';
import corbin_brown_yt from '../../assets/images/YT/cbrown-yt.jpg';
import rotten_mango_yt from '../../assets/images/YT/rotten-mango-yt.jpg';
import come_and_talk_yt from '../../assets/images/YT/come-and-talk-yt.jpg';
import valuetainment_yt from '../../assets/images/YT/valuetainment-yt.jpg';
import espn_yt from '../../assets/images/YT/espn-yt.jpg';
import chris_will_yt from '../../assets/images/YT/chris-will-yt.jpg';
import nba_yt from '../../assets/images/YT/nba-yt.jpg';
import first_feast_yt from '../../assets/images/YT/first-we-feast-yt.jpg';
import uc_berkeley_yt from '../../assets/images/YT/uc-berk-yt.jpg';
import wired_yt from '../../assets/images/YT/wired-yt.jpg';
import jubilee_yt from '../../assets/images/YT/jubilee-yt.jpg';



const profiles = [
  { img: espn_yt, name: "ESPN", subscribers: "13.4M" },
  { img: rotten_mango_yt, name: "Rotten Mango", subscribers: "2.8M" },
  { img: corbin_brown_yt, name: "Corbin Brown", subscribers: "88K" },
  { img: come_and_talk_yt, name: "Come And Talk 2 Me", subscribers: "592K" },
  { img: nba_yt, name: "NBA", subscribers: "22.8M" },
  { img: uc_berkeley_yt, name: "UC Berkeley", subscribers: "66K" },
  { img: wired_yt, name: "WIRED", subscribers: "12M" },
  { img: jubilee_yt, name: "Jubilee", subscribers: "9.8M" },
  { img: first_feast_yt, name: "First We Feast", subscribers: "14.5M" },
  { img: aquinas_yt, name: "Pints With Aquinas", subscribers: "546K" },
  { img: chris_will_yt, name: "Chris Williamson", subscribers: "3.4M" },
  { img: valuetainment_yt, name: "Valuetainment", subscribers: "5.6M" }
];

function SocialProfileCarousel() {
  return (
    <div className="spc-scrolling-bar-container-wrapper">
      <div className="spc-scrolling-bar-container">
        <div className="spc-scrolling-bar">
          {Array.from({ length: 12 }).flatMap((_, index) =>
            profiles.map((profile, profileIndex) => (
              <div key={`${index}-${profileIndex}`} className="spc-profile">
                <div className="spc-profile-img-wrapper">
                  <img src={profile.img} alt={`${profile.name} Profile`} className="spc-profile-img"/>
                  <img src={youtube_white_logo} alt="YouTube Logo" className="spc-youtube-logo"/>
                </div>
                <p className="spc-profile-name">{profile.name}</p>
                <p className="spc-profile-subscribers">{profile.subscribers}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default SocialProfileCarousel;
